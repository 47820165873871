import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../../models/user';
import { IUserResponse } from '../../infrastructure/interfaces/user-response.interface';
import { tap } from 'rxjs/operators';
import { CompanyService } from '../company.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  public redirectUrl: any | string;

  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) {}

  authenticate(userName: string, password: string): Observable<User> {
    return this.http.post<User>(`/login`, {
      username: userName,
      password: password,
    });
  }

  authenticateWithToken(token: string): Observable<User> {
    return this.http.post<User>(`/login/token`, {
      token,
    });
  }

  resendEmailConfirm(email:string, companyName: string, companyType: string, phone: string): Observable<User> {
    return this.http.post<User>(`/api/resend/confirm-email`, {
      email,
      companyName,
      companyType,
      phone
    });
  }

  getSendConfirmEmailExpired(email:string): Observable<Pick<User, 'sendConfirmEmailExpires'>> {
    return this.http.get<User>(`/api/send-confirm-email-expires/${email}`);
  }

  logout(): Observable<any> {
    this.clearUserInfo();
    this.companyService.clearCompany();
    return this.http.get<any>('/logout');
  }

  getLoggedInUserFromSession(isReused = false): Observable<IUserResponse> {
    if (isReused && this.isAuthenticated) {
      const userData = sessionStorage.getItem('userInfo');
      return of({
        model: [JSON.parse(userData)],
        err: null,
      });
    }

    return this.http.get<IUserResponse>(`/api/user`).pipe(
      tap((userInfo: IUserResponse) => {
        if (userInfo) {
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo.model[0]));
        }
      })
    );
  }

  setUserInfo(userInfo: Partial<User>): void {
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  clearUserInfo(): void {
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('metadata');
  }

  get isAuthenticated(): boolean {
    let userData = sessionStorage.getItem('userInfo');
    if (userData && JSON.parse(userData)) {
      return true;
    }
    return false;
  }

  get currentUser(): User {
    return JSON.parse(sessionStorage.getItem('userInfo')) as User;
  }
}
