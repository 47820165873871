<div #gridView>
  <kendo-grid
    #grid
    class="forecast-table"
    [columnMenu]="columnMenu"
    [selectable]="
      isSelectable
        ? { checkboxOnly: true, mode: 'multiple', drag: false }
        : false
    "
    [reorderable]="reorderable"
    [data]="gridDataItems"
    [pageSize]="displayedGridState.take"
    [skip]="displayedGridState.skip"
    [pageable]="pageable"
    [filterable]="isPrintList ? false : filterable"
    [filter]="displayedGridState.filter"
    [sortable]="sortable"
    [sort]="displayedGridState.sort"
    [group]="displayedGridState.group"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (dataStateChange)="onStateChange($event)"
    (selectionChange)="onSelectionChange($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    (columnReorder)="onColumnReorder($event)"
    (columnLockedChange)="onColumnLocked($event)"
    [height]="gridHeight"
    [navigable]="true"
    [resizable]="true"
    [rowClass]="determineGridClass"
    [kendoGridSelectBy]="gridSelectByKey"
    [(selectedKeys)]="gridItemSelectedKey"
    (excelExport)="onExcelExport($event)"
    (columnResize)="onResizeColumn($event)"
  >
    <!-- Toolbar template  -->
    <ng-template
      kendoGridToolbarTemplate
      position="top"
      *ngIf="!isPrintList && itemName !== GridName.ItemStickers && !isSticker"
    >
      <!-- chip -->
      <div style="display: contents" *ngIf="gridState.filter.filters.length">
        <strong>Filter(s) : </strong>
        <kendo-chip
          *ngFor="let filter of gridState.filter.filters; index as i"
          class="ml-1 mr-1"
          themeColor="info"
          rounded="large"
          [label]="getExtraFilterChipLabel(filter)"
          [removable]="true"
          removeIcon="k-icon k-i-close"
          (remove)="deleteExtraFilter(i)"
        >
        </kendo-chip>
        <small class="text-primary cursor-pointer" (click)="clearAllFilter()"
          >Remove all filters
        </small>
      </div>
      <div
        class="forecast-table__header"
        [ngClass]="
          gridDescription
            ? 'forecast-table__header--two-children'
            : 'forecast-table__header--one-child'
        "
      >
        <!-- Description of grid table -->
        <div
          *ngIf="gridDescription"
          class="forecast-table__header__description"
        >
          {{ gridDescription }}
        </div>
        <div class="forecast-table__header__toolbar">
          <ul class="nav justify-content-end">
            <li class="nav-item active" *ngIf="itemName === GridName.ItemsInPo">
              <span
                [ngbTooltip]="'Add PO Wizard'"
                container="body"
                placement="top"
                tooltipClass="custom-tooltip"
                class="po-wizard-icon"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  (click)="
                    itemName === GridName.ItemsInPo
                      ? customAddClick.emit()
                      : open(content)
                  "
                >
                  <i class="fa fa-magic"></i>
                </button>
              </span>
              <span
                container="body"
                placement="top"
                tooltipClass="custom-tooltip"
                class="po-wizard-text"
              >
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  (click)="
                    itemName === GridName.ItemsInPo
                      ? customAddClick.emit()
                      : open(content)
                  "
                >
                  <span>Add PO Wizard</span>
                </button>
              </span>
            </li>
            <li class="nav-item active">
              <kendo-dropdownlist
                #dropDownListFilter
                [data]="filterList"
                [filterable]="true"
                [defaultItem]="defaultFilter"
                size="small"
                textField="text"
                valueField="value"
                (filterChange)="filterChange($event)"
                (selectionChange)="selectionChange($event)"
              >
              </kendo-dropdownlist>
            </li>
            <li
              class="nav-item active"
              *ngIf="
                isShipmentDetail && !isModifyingUnits && isModifyUnitsVisible
              "
            >
              <button
                class="btn btn-sm btn-primary"
                (click)="onModifyUnits()"
                [disabled]="grid?.loading"
              >
                <i class="fas fa-edit"></i>
                Modify Units
              </button>
            </li>

            <li
              class="nav-item active"
              *ngIf="isShipmentDetail && isModifyingUnits"
            >
              <button
                class="btn btn-sm btn-success"
                (click)="onModifyUnitsSave()"
                [disabled]="grid?.loading"
              >
                <i class="fas fa-save"></i>
                Save
              </button>
            </li>

            <li
              class="nav-item active"
              *ngIf="isShipmentDetail && isModifyingUnits"
            >
              <button
                class="btn btn-sm btn-danger"
                (click)="onModifyUnitsCancel()"
                [disabled]="grid?.loading"
              >
                <i class="fas fa-times"></i>
                Cancel
              </button>
            </li>

            <li
              class="nav-item active"
              *ngIf="isShipmentDetail && !isModifyingUnits"
            >
              <button
                class="btn btn-sm btn-primary"
                *ngIf="itemName === 'Forecast Shipment Item' && selectingItem.status === ShipmentStatus.PENDING"
                (click)="onCreateShipment(selectingItem)"
              >
                Next Step: &nbsp;
                <i class="fas fa-archive"></i>
                Create FBA Shipment
              </button>

              <button
                class="btn btn-sm btn-primary"
                *ngIf="itemName === 'Forecast Shipment Item' && selectingItem.status === ShipmentStatus.WORKING"
                (click)="onWorkOnShipment(selectingItem)"
              >
                Next Step: &nbsp;
                <i class="fas fa-truck"></i>
                Work on Shipment
              </button>

              <button
                class="btn btn-sm btn-primary"
                *ngIf="itemName === 'Amazon Shipment Item'"
                (click)="refreshSingleShipment()"
                [disabled]="grid?.loading || shouldShowMaintenanceMessage"
              >
                Next Step: &nbsp;
                <i class="fas fa-redo"></i>
                Refresh Amazon Status
              </button>
            </li>

            <li class="nav-item active" *ngIf="canChangeCreatedDate">
              <button
                class="btn btn-sm btn-primary"
                [disabled]="grid?.loading"
                (click)="changeCreatedDate()"
              >
                <i class="fa fa-calendar"></i>
                Change Created Date
              </button>
            </li>

            <li
              *ngIf="printIsVisible && isCompanyTypeASC && !isModifyingUnits"
              class="nav-item active"
            >
              <button
                kendoButton
                icon="printer"
                [disabled]="isDisabledPrintSticker || grid?.loading"
                class="k-button btn btn-sm btn-success forecast-table__header__toolbar print-sticker"
                (click)="handlePrintStickers()"
              ></button>
            </li>

            <li
              class="nav-item active"
              *ngIf="refreshIsVisible && isCompanyTypeASC"
            >
              <span
                placement="bottom"
                container="body"
                [ngbTooltip]="
                  shouldShowMaintenanceMessage
                    ? maintenanceTooltipContent
                    : timeOffTooltipContent
                "
                tooltipClass="timeInfo"
              >
                <button
                  kendoButton
                  [disabled]="
                    isProcessing ||
                    grid?.loading ||
                    shouldShowMaintenanceMessage
                  "
                  class="k-button d-flex btn btn-sm btn-success forecast-table__header__toolbar--left__btn"
                  (click)="handleSyncShipment()"
                >
                  <span
                    class="spinner-border spinner-border-sm text-light mr-2 mt-1"
                    *ngIf="isProcessing; else noProcess"
                  ></span>
                  <ng-template #noProcess>
                    <i class="fas fa-sync mr-2"></i>
                  </ng-template>
                  <span>Refresh All</span>
                </button>
              </span>
              <ng-template #timeOffTooltipContent let-greeting="greeting">
                Last Refresh
                <br />
                {{ shipmentLastRefresh | date : "fullDate" }}
                {{ shipmentLastRefresh | date : "shortTime" }}
              </ng-template>
              <ng-template #maintenanceTooltipContent>
                The Amazon API is currently undergoing unscheduled maintenance
              </ng-template>
            </li>

            <li *ngIf="saveGridStateIsVisible" class="nav-item active">
              <!-- Grid layout views -->
              <app-grid-view-combobox
                [isCustom]="isCustom"
                [itemName]="itemName"
                [modelType]="modelType"
                [metadataFields]="userMetaDataFields"
                [state]="displayedGridState"
                [columnReorderArgs]="columnReorderArgs"
                [columnResizeArgs]="columnResizeArgs"
                [columnVisibilityArgs]="columnVisibilityArgs"
                [clearClicked$]="clearClicked$"
                (gridViewChange)="handleGridViewChange($event)"
                [userSelectedGridView]="userSelectedGridView"
                [removalOrdersFilter]="removalOrdersFilter"
              ></app-grid-view-combobox>
            </li>

            <li
              *ngIf="
                isViewOnly &&
                isCustom &&
                itemName === 'Items in PO' &&
                !isGridEmpty
              "
              class="nav-item active"
            >
              <!-- Export for Items in PO -->
              <button
                type="button"
                class="btn btn-sm btn-primary forecast-table__header__export"
                kendoGridExcelCommand
                (click)="downloadData(true)"
              >
                <i class="fas fa-file mr-2"></i> Export
              </button>
            </li>

            <li
              *ngIf="isModifyVisible"
              class="nav-item active"
            >
              <button
                class="k-button btn btn-sm btn-primary forecast-table__header__toolbar"
                (click)="customModifyClick.emit()"
              >
                <i class="fas fa-edit"></i>
                {{ modifyText }}
              </button>
            </li>

            <li
              *ngIf="addIsVisible && !isModifyingUnits"
              class="nav-item active"
            >
              <div
                [title]="
                  hasActiveItemsReachLimit && itemName === GridName.Item
                    ? 'Your active item number has exceeded the limit of the current subscription'
                    : hasOrdersReachLimit && itemName === GridName.Demand
                    ? 'Your order number has exceeded the limit of the current subscription'
                    : ''
                "
              >
                <button
                  class="k-button btn btn-sm btn-success forecast-table__header__toolbar"
                  (click)="
                    itemName === GridName.ManageSupplier || itemName === GridName.PurchaseOrderItem
                      ? customAddClick.emit()
                      : open(content)
                  "
                  [disabled]="itemName !== GridName.PurchaseOrderItem && (hasActiveItemsReachLimit || hasOrdersReachLimit)"
                >
                  <i *ngIf="checkShipmentDetail()" class="fas fa-plus"></i>
                  {{ checkShipmentDetail() ? "Add SKUs" : (addText || "Add New") }}
                </button>
              </div>
            </li>

            <li
              *ngIf="
                itemName === 'Amazon Shipment Item' &&
                selectingItem?.status === ShipmentStatus.WORKING
              "
              class="nav-item active"
            >
              <button
                class="btn btn-sm btn-danger"
                [disabled]="isDeleting"
                (click)="onDeleteShipment()"
              >
                <span
                  class="spinner-border spinner-border-sm text-light align-items-center"
                  *ngIf="isDeleting; else notDelete"
                ></span>
                <ng-template #notDelete
                  ><i class="fas fa-times"></i
                ></ng-template>
                Delete Shipment
              </button>
            </li>

            <li
              *ngIf="checkShipmentDetail() && checkCancelShipmentIsVisible()"
              class="nav-item active"
            >
              <button
                type="button"
                class="btn btn-sm btn-danger forecast-table__header__cancel"
                (click)="onCancelShipment()"
              >
                <i class="fas fa-times-circle"></i> Cancel Shipment
              </button>
            </li>

            <li
              *ngIf="itemName === GridName.Demand && isCompanyTypeASC"
              class="nav-item active"
            >
              <ng-template #minOrderQtyPopContent>
                <div class="form-group">
                  <label for="filterRemovalOrdersQty">
                    Min Order Qty to Hide &nbsp;
                    <span
                      ngbTooltip="Filter demands with the minimum number of order qty"
                      tooltipClass="custom-tooltip"
                    >
                      <i class="fas fa-info-circle cursor-pointer"></i>
                    </span>
                  </label>
                  <input
                    class="form-control"
                    id="filterRemovalOrdersQty"
                    type="number"
                    [(ngModel)]="filterRemovalOrdersQty"
                  />
                </div>

                <div class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="k-button w-100 mr-1"
                    (click)="clearFilterRemovalOrders(minOrderQtyPop)"
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    class="k-button k-primary w-100"
                    (click)="filterRemovalOrders(minOrderQtyPop)"
                    [disabled]="
                      !filterRemovalOrdersQty && filterRemovalOrdersQty !== 0
                    "
                  >
                    Filter
                  </button>
                </div>
              </ng-template>

              <button
                #minOrderQtyPop="ngbPopover"
                type="button"
                class="k-button btn btn-sm forecast-table__header__toolbar"
                [ngbPopover]="minOrderQtyPopContent"
                container="body"
                autoClose="outside"
                [ngClass]="
                  removalOrdersFilter ? 'btn-success' : 'btn-outline-success'
                "
              >
                Filter Removal Orders
              </button>
            </li>

            <li
              *ngIf="itemName !== GridName.Item && deleteIsVisible"
              class="nav-item active"
            >
              <button
                [disabled]="isDisabledDelete"
                (click)="deleteItems()"
                class="k-button btn btn-sm btn-danger forecast-table__header__toolbar"
              >
                Delete
                {{
                  finalItemName === "Supply" ? "Purchase Order" : finalItemName
                }}(s) - {{ selectedRows.size }}
              </button>
            </li>

            <li *ngIf="isDeleteItemsVisible" class="nav-item active">
              <button
                [disabled]="isDisabledDelete"
                (click)="hideItems()"
                class="k-button btn btn-sm btn-danger forecast-table__header__toolbar"
              >
                Hide {{ itemName }}(s) - {{ selectedRows.size }}
              </button>
            </li>

            <li
              *ngIf="itemName === GridName.Supply && isCompanyTypeQBFS"
              class="nav-item active"
            >
              <ng-template #maxOrderQtyPopContent>
                <div
                  class="form-group"
                  [ngClass]="
                    suppliesMaxOrderQty !== null && suppliesMaxOrderQty < 1
                      ? 'mb-1'
                      : 'mb-4'
                  "
                >
                  <label for="">
                    Min Order Qty to Hide &nbsp;
                    <span
                      ngbTooltip="Hide POs from Purchasing recommendations if the Order Qty is greater than or Equal to ____ Unit(s)"
                      tooltipClass="custom-tooltip"
                    >
                      <i class="fas fa-info-circle cursor-pointer"></i>
                    </span>
                  </label>
                  <input
                    class="form-control"
                    id=""
                    type="number"
                    [(ngModel)]="suppliesMaxOrderQty"
                  />

                  <small
                    *ngIf="
                      suppliesMaxOrderQty !== null && suppliesMaxOrderQty < 1
                    "
                    class="ml-1 mt-1 text-danger"
                  >
                    Min is 1.
                  </small>
                </div>

                <div class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="k-button w-100 mr-1"
                    (click)="hideSupply(null)"
                    [disabled]="loading"
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    class="k-button k-primary w-100"
                    (click)="hideSupply(suppliesMaxOrderQty)"
                    [disabled]="
                      !suppliesMaxOrderQty || suppliesMaxOrderQty < 1 || loading
                    "
                  >
                    Hide
                  </button>
                </div>
              </ng-template>

              <button
                #maxOrderQtyPop="ngbPopover"
                type="button"
                class="k-button btn btn-sm btn-danger forecast-table__header__toolbar"
                [ngbPopover]="maxOrderQtyPopContent"
                container="body"
                autoClose="outside"
              >
                Automatically Hide POs
              </button>
            </li>

            <li
              *ngIf="itemName === GridName.Item && isCompanyTypeQBFS"
              class="nav-item active"
            >
              <button
                (click)="onHandSync()"
                class="k-button btn btn-sm forecast-table__header__toolbar"
                [class]="
                  currentCompany?.isManualInventory
                    ? 'btn-outline-primary'
                    : 'btn-primary'
                "
              >
                On-Hand Sync:
                {{ currentCompany?.isManualInventory ? "Off" : "On" }}
              </button>
            </li>

            <li *ngIf="itemName === GridName.Item" class="nav-item active">
              <button
                (click)="eraseWarehouseInventory()"
                class="k-button btn btn-sm forecast-table__header__toolbar btn-primary"
              >
                Erase Warehouse Inventory
              </button>
            </li>

            <li *ngIf="isFilter" class="nav-item active">
              <button
                class="k-button btn btn-sm btn-warning forecast-table__header__toolbar"
                (click)="clearAll()"
                title="Clear all sorts and filters"
              >
                {{
                  specificItemId && items?.length
                    ? "Clear " + items[0]?.name + " filter"
                    : "Clear Filters"
                }}
              </button>
            </li>

            <li *ngIf="isDefaultLockVisible" class="nav-item active">
              <button
                kendoButton
                class="btn btn-sm btn-primary tack-reset"
                title="Reset to default pinned column"
                (click)="resetLockedColumns()"
              >
                <img
                  src="assets/images/tack-reset-icon.svg"
                  alt="tack-reset-icon"
                  width="20px"
                  height="20px"
                />
              </button>
            </li>

            <li *ngIf="bulkMngtIsVisible" class="nav-item active">
              <div
                ngbDropdown
                dropdownClass="custom-dropdown"
                class="d-inline-block"
                container="body"
              >
                <button
                  class="btn btn-sm btn-outline-primary"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                >
                  <span
                    *ngIf="isExporting"
                    title="Please wait while the application is exporting"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                  Bulk Management
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button
                    class="custom-dropdown-item"
                    ngbDropdownItem
                    (click)="downloadInitialTemplate()"
                  >
                    Download Default Required Template
                  </button>
                  <button
                    class="custom-dropdown-item"
                    ngbDropdownItem
                    (click)="downloadFile()"
                  >
                    Download Custom Template
                  </button>
                  <button
                    class="forecast-table__header__toolbar--left__download custom-dropdown-item"
                    ngbDropdownItem
                    (click)="downloadFile(false)"
                    [disabled]="isExporting"
                  >
                    Download Data
                  </button>
                  <button
                    class="custom-dropdown-item"
                    ngbDropdownItem
                    (click)="uploadFile()"
                  >
                    Upload Data
                  </button>
                  <button
                    class="custom-dropdown-item"
                    ngbDropdownItem
                    (click)="undoLastUpload()"
                    *ngIf="remainingUndoTime > 0 && isUploadUndoable"
                  >
                    Undo Last Upload ({{ remainingUndoTime }} hrs left)
                  </button>
                </div>
              </div>
            </li>

            <li
              *ngIf="isExtraExportVisible && !isModifyingUnits"
              class="nav-item active"
            >
              <!-- Export data without bulk management -->
              <!-- Export for All Available Items -->
              <button
                type="button"
                class="btn btn-sm btn-primary forecast-table__header__export"
                (click)="downloadData(); downloadFile(false)"
              >
                <i class="fas fa-file mr-2"></i> Export
              </button>
            </li>

            <li class="nav-item active" *ngIf="fieldsToLookUp?.length">
              <!-- Grid item lookups -->
              <app-grid-item-lookup
                [fieldsToLookUp]="fieldsToLookUp"
                [itemName]="itemName"
                [defaultSearchTerm]="searchTerm"
                (onSearch)="onSearch($event)"
              >
              </app-grid-item-lookup>
            </li>

            <li class="nav-item active">
              <div *ngIf="fullScreenIconIsVisible">
                <img
                  alt=""
                  *ngIf="toogleFullScreenIsVisible"
                  class="forecast-table__header__toolbar__full-screen"
                  [src]="iconFullScreen"
                  (click)="fullScreen()"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ng-template>

    <!-- Checkbox column  -->
    <kendo-grid-checkbox-column
      *ngIf="!isViewOnly && isSelectable"
      showSelectAll="true"
      [style]="{ width: '50px', 'text-align': 'center' }"
      [headerStyle]="{ width: '50px', 'text-align': 'center' }"
      [columnMenu]="false"
      [minResizableWidth]="50"
      [width]="50"
      [reorderable]="false"
      [locked]="
        itemName !== GridName.Bom && itemName !== GridName.ManageSupplier
      "
    >
      <ng-template kendoGridHeaderTemplate>
        <input
          #checkboxSelectAll
          class="k-checkbox"
          kendoGridSelectAllCheckbox
          (change)="ensureGroupCheckboxIsClicked(checkboxSelectAll.checked)"
        />
      </ng-template>
      <ng-template kendoGridCellTemplate let-idx="rowIndex" let-dataItem="dataItem">
        <input class="k-checkbox" [kendoGridSelectionCheckbox]="idx" [class.hidden]="getIsCheckBoxHidden(dataItem)" />
      </ng-template>
    </kendo-grid-checkbox-column>

    <!-- Remove button columns  -->
    <kendo-grid-column
      *ngIf="itemName === 'Items in PO'"
      field="removeAll"
      [columnMenu]="false"
      [sortable]="false"
      [includeInChooser]="false"
      [reorderable]="false"
      class="forecast-table__button-col"
      [style]="{ width: '120px', 'text-align': 'center' }"
      [headerStyle]="{ width: '120px', 'text-align': 'center' }"
      [minResizableWidth]="120"
      [width]="120"
      [locked]="true"
    >
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <button
          class="btn btn-sm forecast-table__button-col__header"
          (click)="removeAll()"
        >
          Remove All
        </button>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button
          class="btn btn-sm forecast-table__button-col__cell"
          (click)="removeItem(dataItem)"
        >
          Remove
        </button>
      </ng-template>
    </kendo-grid-column>

    <!-- Add button columns  -->
    <kendo-grid-column
      *ngIf="itemName === 'All Available Items'"
      field="addAll"
      [columnMenu]="false"
      [sortable]="false"
      [includeInChooser]="false"
      [reorderable]="false"
      class="forecast-table__button-col"
      [style]="{ width: '120px', 'text-align': 'center' }"
      [headerStyle]="{ width: '120px', 'text-align': 'center' }"
      [minResizableWidth]="120"
      [width]="120"
      [locked]="true"
    >
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <button
          *ngIf="isAddAllVisible | async"
          class="btn btn-sm forecast-table__button-col__header"
          (click)="addAll()"
        >
          Add All
        </button>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button
          class="btn btn-sm forecast-table__button-col__cell"
          [class.forecast-table__button-col__header--active]="!dataItem.inPO"
          (click)="toggleItem(dataItem)"
        >
          {{ dataItem.inPO ? "Remove" : "Add to PO" }}
        </button>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      *ngFor="let metaDataField of selectedColumns"
      field="{{ metaDataField.field }}"
      title="{{ metaDataField.displayName }}"
      filter="{{ metaDataField.dataType }}"
      [hidden]="
        !metaDataField?.initialUpload ||
        hiddenColumns.includes(metaDataField?.field)
      "
      [columnMenu]="metaDataField.sortable"
      [reorderable]="metaDataField.reOrderable"
      [sortable]="metaDataField.sortable"
      [filterable]="metaDataField.filterable"
      [width]="metaDataField.width || 100"
      [headerClass]="determineGridClass()"
      [headerStyle]="{ verticalAlign: 'middle' }"
      [locked]="lockedColumns.includes(metaDataField.field)"
    >
      <ng-template
        kendoGridFilterMenuTemplate
        *ngIf="metaDataField.displayControl.input === inputTypeEnum.Date"
        let-filter
        let-column="column"
        let-filterService="filterService"
      >
        <kendo-grid-date-filter-menu
          [column]="column"
          [filter]="filter"
          [filterService]="filterService"
          [extra]="!disabledExtraFields.includes(metaDataField.field)"
          operator="gte"
        >
        </kendo-grid-date-filter-menu>
      </ng-template>

      <ng-template kendoGridHeaderTemplate>
        <span
          class="d-flex justify-content-start"
          [title]="
            metaDataField.field
              | dataTooltip : currentCompany?.companyType : itemName
          "
          [style.width.%]="100"
          [class.justify-content-between]="
            metaDataField.field === ItemField.tags
          "
        >
          <div
            *ngIf="
              isIconHeader &&
              metaDataField.field === SummaryField.recommendedQty &&
              (isCompanyTypeASC || isCompanyTypeQBFS)
            "
            class="mr-2 k-cursor-pointer"
          >
            <div (click)="sortField(metaDataField.field)">
              <img alt="" [src]="iconForecast" />
            </div>
            <div
              (click)="sortField(SummaryField.trueRecommendedQty)"
              *ngIf="isCompanyTypeASC || isCompanyTypeQBFS"
            >
              <i class="fa fa-rocket"></i>
            </div>
          </div>

          <div class="shorten-text">
            <i
              *ngIf="metaDataField.field | dataFlagClass"
              [class]="metaDataField.field | dataFlagClass"
            ></i>

            <span [ngSwitch]="metaDataField.field">
              <span
                *ngSwitchCase="SummaryField.recommendedQty"
                (click)="sortField(metaDataField.field)"
              >
                <span class="k-cursor-pointer">
                  {{ metaDataField.displayName }}
                  &nbsp;
                  <i
                    [class]="getSortIconClass(SummaryField.recommendedQty)"
                  ></i>
                </span>
              </span>

              <span *ngSwitchDefault
                >{{ metaDataField.displayName }}
                <span
                  *ngIf="
                    metaDataField?.displayControl?.output ===
                    outputTypeEnum.Currency
                  "
                  >({{ displayCurrency.symbol }})
                </span>

                <span
                  *ngIf="isNewLockAvailable"
                  class="pin-btn ml-1"
                  [class.pin-btn--unlocked]="
                    !lockedColumns.includes(metaDataField.field)
                  "
                  (click)="lockColumnWithButton($event, metaDataField.field)"
                >
                  <img
                    src="assets/images/tack-icon.svg"
                    alt="tack-icon"
                    width="20px"
                    height="20px"
                  />
                </span>
              </span>
            </span>

            <br />
            <span
              *ngIf="
                metaDataField.field === SummaryField.recommendedQty &&
                (isCompanyTypeASC || isCompanyTypeQBFS)
              "
              (click)="sortField(SummaryField.trueRecommendedQty)"
              class="k-cursor-pointer"
              title="Recommended order qty based on recent sales and forecast."
            >
              Daily Sales Rate Recommendation &nbsp;
              <i
                [class]="getSortIconClass(SummaryField.trueRecommendedQty)"
              ></i>
            </span>
          </div>

          <input
            type="checkbox"
            *ngIf="
              metaDataField.field === ItemField.tags &&
              ![
                GridName.AllAvailableItems,
                GridName.ItemsInPo,
                GridName.PurchaseOrderItem
              ].includes(itemName)
            "
            class="mr-1"
            #checkboxSelectAllTag
            (change)="selectAllTagRows($event.target?.checked)"
            kendoCheckBox
          />
        </span>
      </ng-template>

      <!-- Select Filter -->
      <ng-template
        kendoGridFilterMenuTemplate
        let-filter
        let-column="column"
        let-filterService="filterService"
        *ngIf="metaDataField.displayControl.input === inputTypeEnum.Select"
      >
        <kendo-select-filter
          [filter]="filter"
          [data]="metaDataField.displayControl.selectSource"
          [filterService]="filterService"
          [isMenu]="true"
          [field]="metaDataField.field"
          textField="display"
          valueField="val"
        ></kendo-select-filter>
      </ng-template>

      <ng-template
        *ngIf="
          metaDataField.field === 'parentName' ||
          metaDataField.field === 'fullName' ||
          metaDataField.field === 'itemName'
        "
        kendoGridGroupHeaderTemplate
        let-value="value"
        let-group="group"
      >
        <input
          #groupcheck
          type="checkbox"
          class="k-checkbox"
          (change)="selectGroup(group, groupcheck)"
        />
        &nbsp;{{ value }}{{ getItemSiteTotal(group) }}
        <div *ngIf="itemName === GridName.ManageSupplier" class="ml-2">
          <button
            type="button"
            class="forecast-icon btn p-0 icon-btn"
            (click)="
              openSalesVelocityModal(
                group?.items[0],
                SalesVelocitySettingsType.restockAMZ
              )
            "
            title="RestockAMZ Daily Sales Rate settings"
          >
            <i class="fas fa-cog"></i>
          </button>
        </div>
      </ng-template>

      <!-- Inputs of grid cell -->
      <ng-template
        *ngIf="
          !bulkEditAllowedFields?.length ||
          bulkEditAllowedFields.includes(metaDataField.field)
        "
        [ngSwitch]="metaDataField.displayControl.input"
        kendoGridEditTemplate
        let-formGroup="formGroup"
        let-column="column"
        let-isNew="isNew"
      >
        <input
          *ngSwitchCase="inputTypeEnum.Text"
          type="text"
          class="k-textbox"
          [formControl]="formGroup.get(metaDataField.field)"
        />
        <input
          *ngSwitchCase="inputTypeEnum.Email"
          type="email"
          class="k-textbox"
          [formControl]="formGroup.get(metaDataField.field)"
        />
        <input
          *ngSwitchCase="inputTypeEnum.Integer"
          decimalNumber
          type="number"
          class="k-textbox"
          [formControl]="formGroup.get(metaDataField.field)"
        />
        <input
          *ngSwitchCase="inputTypeEnum.Decimal"
          decimalNumber
          type="number"
          class="k-textbox"
          [formControl]="formGroup.get(metaDataField.field)"
        />
        <kendo-datepicker
          *ngSwitchCase="inputTypeEnum.Date"
          calendarType="classic"
          [formControl]="formGroup.get(metaDataField.field)"
          placeholder="mm/dd/yyyy"
          format="{{ FORMAT_DATE }}"
          [formatPlaceholder]="FORMAT_PATTERN"
        ></kendo-datepicker>
        <app-list-input
          *ngSwitchCase="inputTypeEnum.List"
          [group]="formGroup"
          [control]="formGroup.get(metaDataField.field)"
          [controlName]="metaDataField.field"
          [source]="metaDataField.displayControl.source"
          [textField]="metaDataField.displayControl.textField"
          [valueField]="metaDataField.displayControl.valueField"
        >
        </app-list-input>
        <input
          *ngSwitchDefault
          type="text"
          class="k-textbox"
          [formControl]="formGroup.get(metaDataField.field)"
        />
        <app-lookup-input
          *ngSwitchCase="inputTypeEnum.LookUp"
          [group]="formGroup"
          [lookUpConfig]="metaDataField.displayControl.lookUpConfig"
          [control]="formGroup.get(metaDataField.field)"
          [controlName]="metaDataField.field"
          (chooseItem)="currentAsin = $event"
        ></app-lookup-input>

        <div *ngSwitchCase="inputTypeEnum.Select">
          <kendo-dropdownlist
            *ngIf="
              metaDataField.field !== ItemField.serviceLevel;
              else serviceLevelDropDown
            "
            [data]="metaDataField.displayControl.selectSource"
            [formControl]="formGroup.get(metaDataField.field)"
            textField="display"
            valueField="val"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>

          <ng-template #serviceLevelDropDown>
            <kendo-dropdownlist
              [popupSettings]="{ height: 200, width: 320 }"
              [data]="metaDataField.displayControl.selectSource"
              [formControl]="formGroup.get(metaDataField.field)"
              textField="display"
              valueField="val"
              [valuePrimitive]="true"
            >
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div
                  class="dropdown-list-item"
                  [style]="{
                    'background-color': dataItem.bgColor,
                    color: getContrastYIQ(dataItem.bgColor)
                  }"
                >
                  <span class="pl-2">{{ dataItem.display }}</span>
                </div>
              </ng-template>
            </kendo-dropdownlist>
          </ng-template>
        </div>

        <div
          *ngIf="
            formGroup.get(metaDataField.field)?.invalid &&
            !(isNew && formGroup.get(metaDataField.field)?.untouched)
          "
          class="text-danger text-small"
        >
          <span
            *ngIf="formGroup.get(metaDataField.field)?.hasError('required')"
          >
            <small>{{ metaDataField.displayName }} is required.</small>
          </span>
          <span *ngIf="formGroup.get(metaDataField.field)?.hasError('max')">
            <small>Max is {{ metaDataField | dataGetMax }}.</small>
          </span>
          <span *ngIf="formGroup.get(metaDataField.field)?.hasError('min')">
            <small>Min is {{ metaDataField | dataGetMin }}.</small>
          </span>
          <span *ngIf="formGroup.get(metaDataField.field)?.hasError('email')">
            <small>Invalid email address.</small>
          </span>
          <span *ngIf="formGroup.get(metaDataField.field)?.hasError('pattern')">
            <small>Invalid value.</small>
          </span>
          <span
            *ngIf="formGroup.get(metaDataField.field)?.hasError('divisibleBy')"
          >
            <small>{{
              formGroup.get(metaDataField.field)?.getError("divisibleBy")
            }}</small>
          </span>
          <span
            *ngIf="
              formGroup.get(metaDataField.field)?.hasError('lessThanOrEqual')
            "
          >
            <small>{{
              formGroup.get(metaDataField.field)?.getError("lessThanOrEqual")
            }}</small>
          </span>
          <span
            *ngIf="
              formGroup.get(metaDataField.field)?.hasError('greaterThanOrEqual')
            "
          >
            <small>{{
              formGroup.get(metaDataField.field)?.getError("greaterThanOrEqual")
            }}</small>
          </span>
          <span
            *ngIf="
              formGroup
                .get(metaDataField?.field)
                ?.hasError('lessThanOrEqualStatic')
            "
          >
            <small>{{
              formGroup
                .get(metaDataField?.field)
                ?.getError("lessThanOrEqualStatic")
            }}</small>
          </span>

          <span
            *ngIf="
              formGroup
                .get(metaDataField.field)
                ?.hasError('casePackStateUnchanged')
            "
          >
            <small>{{
              formGroup
                .get(metaDataField.field)
                ?.getError("casePackStateUnchanged")
            }}</small>
          </span>
        </div>
      </ng-template>

      <!-- Output of grid cell  -->
      <ng-template
        kendoGridCellTemplate
        [ngSwitch]="metaDataField.displayControl.output"
        let-dataItem
        let-rowIndex="rowIndex"
        let-column="column"
      >
        <div
          *ngSwitchCase="outputTypeEnum.Checkbox"
          style="width: 100%"
          class="text-center"
          [class.is-grey]="getIsRowGrey(dataItem)"
        >
          <input
            type="checkbox"
            kendoCheckBox
            (click)="handleCheckboxClick($event, dataItem, column)"
            [checked]="dataItem[metaDataField.field]"
            [disabled]="
              isReadOnly(metaDataField.field) ||
              (isViewOnly && metaDataField.field !== SummaryField.doNotOrder) ||
              grid?.loading
            "
          />
        </div>

        <span
          *ngSwitchCase="outputTypeEnum.Text"
          [class.position-relative]="metaDataField.field === SummaryField.daysLeft"
          [class.text-danger]="
            metaDataField.field === 'earliestDueDate' &&
            (dataItem | dataIsSummaryByVendor : metaDataField : itemName)
          "
          [class.is-grey]="getIsRowGrey(dataItem)"
          [ngbTooltip]="
            metaDataField.displayControl?.willShowTooltip
              ? dataItem[metaDataField.field]
              : ''
          "
          container="body"
        >
          <span
            *ngIf="
              metaDataField.displayControl?.selectSource?.length;
              else default
            "
          >
            {{
              metaDataField
                | dataOutput
                  : dataItem
                  : itemName
                  : vendorsGroupedByKey
                  : currentCompany
            }}
          </span>

          <ng-template #default>
            <span
              *ngIf="
                !(dataItem | dataIsSummaryByVendor : metaDataField : itemName)
              "
              class="d-flex justify-content-start"
            >
              <div
                *ngIf="
                  isIconHeader &&
                  metaDataField.field === SummaryField.recommendedQty
                "
                class="mr-2"
              >
                <button
                  *ngIf="isCompanyTypeASC || isCompanyTypeQBFS"
                  class="forecast-icon btn p-0 icon-btn"
                  (click)="
                    onDetailPO({
                      dataItem: dataItem,
                      modalType: ModalType.ItemCard,
                      isTotalComponentDataOn:
                        dataItem.isChild &&
                        (isCompanyTypeASC || isCompanyTypeQBFS)
                    })
                  "
                  title="Item Card"
                >
                  <img alt="" [src]="iconForecast" />
                </button>
                <button
                  *ngIf="isCompanyTypeASC || isCompanyTypeQBFS"
                  class="forecast-icon btn p-0 icon-btn"
                  (click)="
                    onSaleVelocityCalculation(
                      dataItem,
                      dataItem.isChild &&
                        (isCompanyTypeASC || isCompanyTypeQBFS)
                    )
                  "
                  title="Average Daily Sales Rate number panel"
                >
                  <i class="fa fa-rocket"></i>
                </button>

                <ng-container
                  *ngIf="
                    (isCompanyTypeASC || isCompanyTypeQBFS) && dataItem.isChild
                  "
                >
                  <button
                    *ngIf="isCompanyTypeASC || isCompanyTypeQBFS"
                    class="forecast-icon btn p-0 icon-btn"
                    (click)="
                      onDetailPO({
                        dataItem: dataItem,
                        modalType: ModalType.ItemCard
                      })
                    "
                    title="Item Card"
                  >
                    <img alt="" [src]="iconForecast" />
                  </button>

                  <button
                    *ngIf="isCompanyTypeASC || isCompanyTypeQBFS"
                    class="forecast-icon btn p-0 icon-btn"
                    (click)="onSaleVelocityCalculation(dataItem)"
                    title="Average Daily Sales Rate number panel"
                  >
                    <i class="fa fa-rocket"></i>
                  </button>
                </ng-container>
              </div>

              <div class="shorten-text">
                <span [ngSwitch]="metaDataField.field">
                  <span *ngSwitchCase="ItemField.oversized">
                    {{ dataItem[metaDataField.field] | dataTransformOversize1 }}
                  </span>
                  <span *ngSwitchCase="ItemField.hazmat">
                    {{ dataItem[metaDataField.field] | dataTransformBoolean1 }}
                  </span>
                  <span *ngSwitchCase="ItemField.itemHistoryLength">
                    {{
                      dataItem[metaDataField.field]
                        | dataAddTextUnitToNumber : "month" : true
                    }}
                  </span>
                  <span *ngSwitchCase="VendorField.averageHistoryLength">
                    {{
                      dataItem[metaDataField.field]
                        | dataAddTextUnitToNumber : "month"
                    }}
                  </span>

                  <span *ngSwitchCase="ShipmentField.shipmentId">
                    <span [ngSwitch]="itemName">
                      <ng-container *ngSwitchCase="
                        itemName === GridName.Shipment || itemName === GridName.ShipmentAWD
                          ? itemName
                          : ''
                      ">
                        <a
                          *ngIf="dataItem[metaDataField.field]"
                          class="forecast-table__link-to-ASC"
                          [href]="
                            amazonShipmentDetailLink(
                              dataItem[metaDataField.field],
                              itemName === GridName.ShipmentAWD
                            )
                          "
                          target="_blank"
                          rel="noopener"
                        >
                          {{ dataItem.shipmentConfirmationId && dataItem[metaDataField.field] ? dataItem.shipmentConfirmationId : dataItem[metaDataField.field] }}
                        </a>
                      </ng-container>

                      <ng-container *ngSwitchCase="GridName.PurchaseOrder">
                        <span *ngIf="dataItem.shipments?.length; index as i">
                          <a
                            *ngFor="let shipment of dataItem.shipments"
                            class="forecast-table__link-to-ASC"
                            [routerLink]="linkToShipment(shipment)"
                          >
                            {{ shipment.shipmentId || shipment.shipmentName }}<span *ngIf="i < dataItem.shipments.length - 1">, </span>
                          </a>
                        </span>
                      </ng-container>
                    </span>
                  </span>

                  <span *ngSwitchCase="ShipmentField.refNum">
                    <ng-container
                      *ngIf="
                        itemName === GridName.Shipment &&
                          dataItem[metaDataField.field];
                        else normalRefNum
                      "
                    >
                      <a
                        class="forecast-table__link-to-ASC"
                        [routerLink]="linkToPurchaseOrder(dataItem)"
                      >
                        {{ dataItem[metaDataField.field] }}
                      </a>
                    </ng-container>
                    <ng-template #normalRefNum>
                      {{ dataItem[metaDataField.field] }}
                    </ng-template>
                  </span>

                  <span *ngSwitchDefault>
                    <ng-container
                      *ngIf="
                        metaDataField.field.includes('Date') &&
                          grid?.loading &&
                          rowIndex === editingRowIndex;
                        else normalText
                      "
                    >
                      <span class="forecast-table__loading-dots"></span>
                    </ng-container>
                    <ng-template #normalText>
                      <span
                        [ngbTooltip]="
                          dataItem.isCustom &&
                          metaDataField.field === 'recommendedQty'
                            ? 'Modified Forecast in use, safety stock not included'
                            : (isCompanyTypeASC || isCompanyTypeQBFS) &&
                              dataItem.isChild &&
                              dataItem.isolatedValues &&
                              (dataItem.isolatedValues[metaDataField.field] ||
                                dataItem.isolatedValues[metaDataField.field] ===
                                  0)
                            ? 'Total component data'
                            : ''
                        "
                        container="body"
                        placement="bottom"
                        [ngClass]="{
                          'text-c-red':
                            dataItem.isCustom &&
                            metaDataField.field === 'recommendedQty'
                        }"
                        >{{
                          !dataItem?.isChild &&
                          (itemName === GridName.ItemsInPo ||
                            itemName === GridName.AllAvailableItems) &&
                          (getIsolatedSummaryValue(
                            dataItem,
                            metaDataField.field
                          ) ||
                            getIsolatedSummaryValue(
                              dataItem,
                              metaDataField.field
                            ) === 0)
                            ? getIsolatedSummaryValue(
                                dataItem,
                                metaDataField.field
                              )
                            : dataItem[metaDataField.field]
                        }}
                      </span>

                      <ng-container
                        *ngIf="
                          (isCompanyTypeASC || isCompanyTypeQBFS) &&
                          dataItem.isChild &&
                          metaDataField.field !== SummaryField.recommendedQty &&
                          dataItem.isolatedValues &&
                          (dataItem.isolatedValues[metaDataField.field] ||
                            dataItem.isolatedValues[metaDataField.field] === 0)
                        "
                      >
                        <div
                          class="shorten-text"
                          ngbTooltip="Individual SKU data"
                          container="body"
                          placement="bottom"
                        >
                          {{ dataItem.isolatedValues[metaDataField.field] }}
                        </div>
                      </ng-container>
                    </ng-template>
                  </span>
                </span>
                <br />
                <span
                  *ngIf="
                    metaDataField.field === SummaryField.recommendedQty &&
                    (isCompanyTypeASC || isCompanyTypeQBFS)
                  "
                >
                  <a
                    *ngIf="
                      dataItem[SummaryField.alertAutoAdjust] &&
                      !dataItem.isChild
                    "
                    class="forecast-table__alert-link"
                    title="Click to adjust daily sales rate for out of stock"
                    (click)="onSaleVelocityCalculation(dataItem)"
                  >
                    {{ dataItem[SummaryField.trueRecommendedQty] }}
                  </a>
                  <span
                    *ngIf="!dataItem[SummaryField.alertAutoAdjust]"
                    [ngbTooltip]="
                      dataItem.isChild ? 'Total component data' : ''
                    "
                    container="body"
                    placement="bottom"
                  >
                    {{ dataItem[SummaryField.trueRecommendedQty] }}
                  </span>

                  <ng-container *ngIf="dataItem.isChild">
                    <div class="shorten-text">
                      <span
                        [ngbTooltip]="
                          dataItem.isCustom &&
                          metaDataField.field === 'recommendedQty'
                            ? 'Modified Forecast in use, safety stock not included'
                            : 'Individual SKU data'
                        "
                        [ngClass]="{
                          'text-c-red':
                            dataItem.isCustom &&
                            metaDataField.field === 'recommendedQty'
                        }"
                        container="body"
                        placement="bottom"
                      >
                        {{ dataItem.isolatedValues?.recommendedQty }}
                      </span>
                    </div>
                    <div class="shorten-text">
                      <span
                        title="Click to adjust daily sales rate for out of stock"
                        ngbTooltip="Individual SKU data"
                        container="body"
                        placement="bottom"
                        [class.forecast-table__alert-link]="
                          dataItem[SummaryField.alertAutoAdjust]
                        "
                        (click)="onSaleVelocityCalculation(dataItem)"
                      >
                        {{ dataItem.isolatedValues?.trueRecommendedQty }}
                      </span>
                    </div>
                  </ng-container>
                </span>
              </div>
            </span>
          </ng-template>

          <div *ngIf="metaDataField.field === 'vendorName'">
            <span
              *ngIf="
                dataItem | dataIsSummaryByVendor : metaDataField : itemName
              "
            >
              <i class="fa fa-exclamation-triangle mr-1"></i>Items Without
              Supplier
            </span>

            <span
              *ngIf="
                itemName === 'Supplier List' &&
                itemName === GridName.SupplierList &&
                dataItem.vendorName === null
              "
            >
              Unassigned
            </span>
          </div>

          <span class="position-absolute top-0" *ngIf="metaDataField.field === SummaryField.daysLeft && !dataItem[metaDataField.field]">
            > 1 year
          </span>
        </span>
        <span
          *ngSwitchCase="outputTypeEnum.Currency"
          [class.is-grey]="getIsRowGrey(dataItem)"
        >
          {{
            dataItem[metaDataField.field]
              | currency : displayCurrency.code : displayCurrency.symbol : "1.2"
          }}
        </span>
        <div
          *ngSwitchCase="outputTypeEnum.Icon"
          class="text-center d-flex"
          [class.is-grey]="getIsRowGrey(dataItem)"
          [class.justify-content-around]="
            metaDataField.field !== ItemField.tags
          "
          [class.justify-content-between]="
            metaDataField.field === ItemField.tags &&
            dataItem[metaDataField.field]?.length > 0
          "
          [class.justify-content-end]="
            metaDataField.field === ItemField.tags &&
            dataItem[metaDataField.field]?.length === 0
          "
          style="gap: 0 16px"
        >
          <div
            class="prep-field"
            *ngIf="metaDataField.field === ItemField.prepGuide"
          >
            <app-popover
              [content]="dataItem[metaDataField.field]"
            ></app-popover>
          </div>

          <div *ngIf="itemName === GridName.SupplierList">
            <i
              *ngIf="
                checkShowWarningIcon(
                  dataItem[RestockSuggestionSupplierField.orangeAlerts],
                  dataItem[RestockSuggestionSupplierField.vendorName]
                )
              "
              class="fas fa-exclamation-triangle"
            ></i>
          </div>
          <div
            *ngIf="itemName === GridName.Supplier"
            class="text-center d-flex justify-content-around"
            style="width: 100%"
          >
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="
                openSalesVelocityModal(
                  dataItem,
                  SalesVelocitySettingsType.purchasing
                )
              "
              title="Purchasing Daily Sales Rate settings"
            >
              <i class="fas fa-shopping-cart"></i>
            </button>
          </div>

          <!-- History in Items -->
          <button
            *ngIf="
              itemName === 'Item' && metaDataField.field === ItemField.history
            "
            type="button"
            class="forecast-icon btn p-0 icon-btn"
            (click)="onHistory(dataItem)"
          >
            <i class="fas fa-chart-pie"></i>
          </button>

          <!-- Links of Items  -->
          <div
            *ngIf="
              itemName === 'Item' && metaDataField.field === ItemField.links
            "
            class="text-center d-flex justify-content-around"
            style="width: 100%"
          >
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="
                openSalesVelocityModal(
                  dataItem,
                  SalesVelocitySettingsType.purchasing
                )
              "
              title="Purchasing Daily Sales Rate settings"
            >
              <i class="fas fa-shopping-cart"></i>
            </button>
            <button
              *ngIf="isShowRestockAMZ"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="
                openSalesVelocityModal(
                  dataItem,
                  SalesVelocitySettingsType.restockAMZ
                )
              "
              title="RestockAMZ Daily Sales Rate settings"
            >
              <i class="fas fa-cog"></i>
            </button>
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="onInventoryItem(dataItem.name)"
              title="FBA Inventory Report"
            >
              <i class="fa fa-heartbeat"></i>
            </button>
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Amazon Listing"
              (click)="
                isMarketPlaceIdEU
                  ? toggleAmazonLinkDropdown('amazonLinkMenu' + rowIndex)
                  : onAmazonItem(dataItem.asin)
              "
            >
              <i class="fab fa-amazon"></i>
              <span ngbDropdown>
                <i
                  class="fab fa-amazon d-none"
                  [id]="'amazonLinkMenu' + rowIndex"
                  ngbDropdownToggle
                ></i>
                <div
                  ngbDropdownMenu
                  [aria-labelledby]="'amazonLinkMenu' + rowIndex"
                >
                  <button
                    *ngFor="let marketplace of EU_MARKET_PLACE_IDS"
                    ngbDropdownItem
                    (click)="onAmazonItem(dataItem.asin, marketplace.val)"
                  >
                    {{ marketplace.display }}
                  </button>
                </div>
              </span>
            </button>
          </div>

          <ng-container
            *ngIf="
              (itemName === GridName.Item ||
                [GridName.AllAvailableItems, GridName.ItemsInPo].includes(
                  itemName
                )) &&
              metaDataField.field === ItemField.tags
            "
          >
            <div
              *ngIf="dataItem[metaDataField.field]?.length > 0"
              class="d-flex flex-wrap shorten-text"
              style="gap: 6px 10px"
            >
              <kendo-chip
                *ngFor="let tag of dataItem[metaDataField.field]"
                [label]="tag"
                [title]="tag"
              ></kendo-chip>
            </div>

            <div
              *ngIf="
                ![GridName.AllAvailableItems, GridName.ItemsInPo].includes(
                  itemName
                )
              "
              class="d-flex flex-column justify-content-between"
              style="gap: 20px 0"
            >
              <button
                class="btn forecast-table__tags__btn p-0"
                [title]="'Edit Tags'"
                (click)="openEditTagsModal(dataItem)"
              >
                <i class="fas fa-tags"></i>
              </button>
              <input
                type="checkbox"
                (change)="selectTagRows(dataItem, $event.target?.checked)"
                [checked]="!!selectedKeyTag[dataItem?.key]"
                kendoCheckBox
              />
            </div>
          </ng-container>

          <!-- Links of Items in PO, All available items  -->
          <div
            *ngIf="hasLinks && metaDataField.field === SummaryField.details"
            class="text-center d-flex justify-content-around"
            style="width: 100%"
          >
            <!-- Disable for ASC company has isShowRestockAMZ = true  -->
            <!-- Enable for companyType is CSV, QB -->
            <button
              *ngIf="!isCompanyTypeASC"
              class="forecast-icon btn p-0 icon-btn"
              (click)="
                onDetailPO({
                  dataItem: dataItem,
                  modalType: ModalType.ItemCard
                })
              "
              title="Item Card"
            >
              <img alt="" [src]="iconForecast" />
            </button>
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="onInventoryItem(dataItem.itemName)"
              title="FBA Inventory Report"
            >
              <i class="fa fa-heartbeat"></i>
            </button>
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              (click)="onAmazonItem(dataItem.asin)"
              title="Amazon Listing"
            >
              <i class="fab fa-amazon"></i>
            </button>
          </div>

          <!-- Manage Shipments: -->
          <!-- - Next step -->
          <div
            *ngIf="
              (itemName === GridName.Shipment || itemName === GridName.ShipmentAWD) &&
              metaDataField.field === ShipmentField.nextStep
            "
            class="text-center d-flex"
            style="width: 100%"
          >
            <ng-container [ngSwitch]="true">
              <button
                *ngSwitchCase="dataItem.status !== ShipmentStatus.PENDING && !dataItem.inboundPlanId"
                type="button"
                class="forecast-icon btn p-0 btn-wrapper"
                title="Refresh Amazon Status"
                (click)="refreshSingleShipment(dataItem)"
                [disabled]="shouldShowMaintenanceMessage"
              >
                <i class="feather icon-rotate-cw icon-btn__drawable-left"></i>
                <span class="next-step">Refresh Amazon Status</span>
              </button>

              <button
                *ngSwitchCase="dataItem.status !== ShipmentStatus.PENDING && !!dataItem.inboundPlanId"
                type="button"
                class="forecast-icon btn p-0 btn-wrapper"
                title="Work on Shipment"
                (click)="onWorkOnShipment(dataItem)"
              >
                <i class="fas fa-truck" [style.transform]="'translateY(5px)'"></i>
                <span class="next-step">Work on<br />Shipment</span>
              </button>

              <button
                *ngSwitchCase="dataItem.status === ShipmentStatus.PENDING"
                type="button"
                class="forecast-icon btn p-0 btn-wrapper"
                title="Create FBA Shipment"
                (click)="onCreateShipment(dataItem)"
              >
                <i class="fa fa-archive icon-btn__drawable-left"></i>
                <span class="next-step">Create FBA Shipment</span>
              </button>
            </ng-container>
          </div>

          <!-- - Options -->
          <div
            *ngIf="
              (itemName === GridName.Shipment || itemName === GridName.ShipmentAWD) &&
              metaDataField.field === ShipmentField.options
            "
            class="text-center d-flex justify-content-around"
            style="width: 100%"
          >
            <ng-container
              *ngIf="
                dataItem.status !== ShipmentStatus.PENDING;
                else options_pending
              "
            >
              <button
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="View order"
                (click)="onGoToDetail(dataItem)"
              >
                <i class="fa fa-search"></i>
              </button>
              <button
                *ngIf="!dataItem.inboundPlanId"
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="View Shipment Queue"
                (click)="onGoToAwsInventory(dataItem)"
              >
                <i class="fa fa-plane"></i>
              </button>
              <button
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Print Pick List"
                (click)="onOpenPrintView(dataItem)"
              >
                <i class="fa fa-print"></i>
              </button>
              <button
                *ngIf="itemName !== GridName.ShipmentAWD"
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Create Purchase Order"
                (click)="onCreatePurchaseOrderShipment(dataItem)"
                [disabled]="isCreatingPOButtonDisabled(dataItem)"
              >
                <i class="feather icon-shopping-cart"></i>
              </button>
              <button
                *ngIf="dataItem.inboundPlanId"
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Delete Shipment"
                (click)="deleteShipment(dataItem)"
              >
                <i class="feather icon-trash-2"></i>
              </button>
            </ng-container>

            <ng-template #options_pending>
              <button
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Update Items"
                (click)="onGoToDetail(dataItem)"
              >
                <i class="feather icon-edit"></i>
              </button>

              <button
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Add Items"
                (click)="onAddItem(dataItem, content)"
              >
                <i class="feather icon-plus-circle"></i>
              </button>

              <button
                type="button"
                class="forecast-icon btn p-0 icon-btn"
                title="Delete Shipment"
                (click)="deleteShipment(dataItem)"
              >
                <i class="feather icon-trash-2"></i>
              </button>
            </ng-template>
          </div>

          <span
            *ngIf="
              itemName === GridName.PurchaseOrder &&
              metaDataField.field === PurchaseOrderField.options
            "
            class="text-center d-flex justify-content-around po-options"
            style="width: 100%"
          >
            <!-- Edit -->
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Update Items"
              (click)="onEditPo(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'Edit')"
            >
              <i class="fa fa-edit"></i>
            </button>

            <!-- PO Detail -->
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Open PO"
              [disabled]="isButtonDisabled(dataItem, 'Search')"
              (click)="onGoToDetail(dataItem)"
            >
              <i class="fa fa-search"></i>
            </button>

            <!-- Open PDF -->
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Open PO PDF"
              (click)="onOpenPDF(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'Print')"
            >
              <i class="fa fa-print"></i>
            </button>

            <!-- Print Sticker -->
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn icon-btn__sticker"
              title="Print Sticker"
              (click)="onPrintSticker(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'PrintSticker')"
            >
              <img [src]="ICON_BARCODE" height="18">
            </button>

            <!-- Create Shipment -->
            <button
              *ngIf="isCompanyTypeASC"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Create Shipment"
              (click)="onCreatePurchaseOrderShipment(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'CreateShipment')"
            >
              <i class="fa fa-truck"></i>
            </button>

            <!-- Close -->
            <button
              *ngIf="!isCompanyTypeQBFS"
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Close PO"
              (click)="onClosePo(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'Close')"
            >
              <i class="fa fa-times-circle"></i>
            </button>

            <!-- Delete -->
            <button
              type="button"
              class="forecast-icon btn p-0 icon-btn"
              title="Delete PO"
              (click)="onDeletePo(dataItem)"
              [disabled]="isButtonDisabled(dataItem, 'Delete')"
            >
              <i class="fa fa-trash"></i>
            </button>
          </span>
        </div>

        <div *ngSwitchCase="outputTypeEnum.Button">
          <button
            class="btn btn-sm btn-outline-primary"
            (click)="removeItem(dataItem)"
          >
            Remove
          </button>
        </div>

        <div
          *ngSwitchCase="outputTypeEnum.Image"
          class="text-center d-flex justify-content-around"
          [class.is-grey]="getIsRowGrey(dataItem)"
        >
          <!-- Image of Items  -->
          <div
            *ngIf="
              isShowImageField && metaDataField.field === ItemField.imageUrl
            "
            class="text-center d-flex justify-content-around"
            style="width: 100%"
          >
            <img
              alt=""
              *ngIf="dataItem.imageUrl"
              height="65"
              [src]="dataItem.imageUrl"
              loading="lazy"
            />
          </div>
        </div>

        <div *ngSwitchCase="outputTypeEnum.Button">
          <button
            class="btn btn-sm btn-outline-primary"
            (click)="removeItem(dataItem)"
          >
            Remove
          </button>
        </div>

        <div
          *ngSwitchCase="outputTypeEnum.Date"
          [class.is-grey]="getIsRowGrey(dataItem)"
        >
          <span *ngIf="metaDataField.field === SummaryField.outOfStockDate && !dataItem[metaDataField.field]">
            > 1 year
          </span>
          {{ dataItem[metaDataField.field] | date : FORMAT_DATE }}
        </div>

        <span *ngSwitchDefault [class.is-grey]="getIsRowGrey(dataItem)">
          {{ dataItem[metaDataField.field] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridNoRecordsTemplate>
      <div [ngSwitch]="itemName">
        <span *ngSwitchCase="GridName.ForecastShipmentItemChosen">
          There are no items chosen to show. <br />
          Please go back to Inventory Selection.
        </span>
        <span *ngSwitchDefault>
          There are no
          {{ finalItemName === "Supply" ? "Purchase Order" : finalItemName }}(s)
          to show. <br />
          Please begin by reviewing the instructions above.
        </span>
      </div>
    </ng-template>

    <!-- Export data to excel (.xlsx only)  -->
    <kendo-grid-excel
      fileName="{{ downloadFileName }}"
      [fetchData]="isCustom ? allDataPOItemsCustom : null"
    >
      <kendo-excelexport-column
        *ngFor="let metaDataField of selectedColumns"
        [hidden]="getExportColumn(metaDataField)"
        [field]="metaDataField?.field"
        [title]="metaDataField?.displayName"
      >
      </kendo-excelexport-column>
    </kendo-grid-excel>

    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <div class="k-pager-info k-label">
        {{ (currentPage - 1) * displayedGridState.take + 1 }} -
        {{
          (currentPage - 1) * displayedGridState.take + displayedGridState.take
        }}
        of
        {{ totalRecords | number }}
        {{ totalRecords >= 1000000 ? "+" : "" }} items
      </div>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes
        [pageSizes]="[20, 50, 100]"
      ></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        Add {{ modalTitle === "Supply" ? "Purchase Order" : modalTitle }}
      </h5>
    </div>
    <div class="modal-body">
      <form class="row" [formGroup]="newForm">
        <div
          class="col-4 p-2"
          [ngClass]="{
            'd-none': forbiddenFields.includes(metaDataField.field)
          }"
          *ngFor="let metaDataField of metaDataFields"
        >
          <label
            class="m-r-5 m-b-1"
            *ngIf="!forbiddenFields.includes(metaDataField.field)"
            >{{ metaDataField.displayName }}:
          </label>
          <ng-container
            *ngIf="!forbiddenFields.includes(metaDataField.field)"
            [ngSwitch]="metaDataField.displayControl.input"
          >
            <input
              *ngSwitchCase="inputTypeEnum.Text"
              type="text"
              class="form-control"
              [formControl]="newForm.get(metaDataField.field)"
            />
            <input
              *ngSwitchCase="inputTypeEnum.Email"
              type="email"
              class="form-control"
              [formControl]="newForm.get(metaDataField.field)"
            />
            <input
              *ngSwitchCase="inputTypeEnum.Integer"
              decimalNumber
              type="number"
              class="form-control"
              [formControl]="newForm.get(metaDataField.field)"
            />
            <input
              *ngSwitchCase="inputTypeEnum.Decimal"
              decimalNumber
              type="number"
              class="form-control"
              [formControl]="newForm.get(metaDataField.field)"
            />
            <kendo-datepicker
              *ngSwitchCase="inputTypeEnum.Date"
              [style.width.%]="100"
              placeholder="mm/dd/yyyy"
              calendarType="classic"
              format="{{ FORMAT_DATE }}"
              [formatPlaceholder]="FORMAT_PATTERN"
              [formControl]="newForm.get(metaDataField.field)"
            >
            </kendo-datepicker>
            <app-list-input
              *ngSwitchCase="inputTypeEnum.List"
              [group]="newForm"
              [control]="newForm.get(metaDataField.field)"
              [controlName]="metaDataField.field"
              [source]="metaDataField.displayControl.source"
              [textField]="metaDataField.displayControl.textField"
              [itemName]="itemName"
              [valueField]="metaDataField.displayControl.valueField"
            >
            </app-list-input>

            <app-lookup-input
              [itemName]="itemName"
              [group]="newForm"
              *ngSwitchCase="inputTypeEnum.LookUp"
              [lookUpConfig]="metaDataField.displayControl.lookUpConfig"
              [control]="newForm.get(metaDataField.field)"
              [controlName]="metaDataField.field"
              (shouldEnableChild)="enableChild($event)"
              [isDisabled]="
                metaDataField.field === 'childName' && !isChildEnabled
              "
              [parentKey]="
                metaDataField.field === 'childName' ? lookupParentKey : ''
              "
              (selectedVendor)="handleSelectedVendor($event)"
            ></app-lookup-input>

            <input
              *ngSwitchCase="inputTypeEnum.Checkbox"
              type="checkbox"
              [formControl]="newForm.get(metaDataField.field)"
            />

            <div *ngSwitchCase="inputTypeEnum.Select">
              <kendo-dropdownlist
                *ngIf="
                  metaDataField.field !== ItemField.serviceLevel;
                  else serviceLevelDropDown
                "
                [data]="metaDataField.displayControl.selectSource"
                [formControl]="newForm.get(metaDataField.field)"
                textField="display"
                valueField="val"
                [valuePrimitive]="true"
              ></kendo-dropdownlist>

              <ng-template #serviceLevelDropDown>
                <kendo-dropdownlist
                  [popupSettings]="{ height: 200, width: 320 }"
                  [data]="metaDataField.displayControl.selectSource"
                  [formControl]="newForm.get(metaDataField.field)"
                  textField="display"
                  valueField="val"
                  [valuePrimitive]="true"
                >
                  <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <div
                      class="dropdown-list-item"
                      [style]="{
                        'background-color': dataItem.bgColor,
                        color: getContrastYIQ(dataItem.bgColor)
                      }"
                    >
                      <span class="pl-2">{{ dataItem.display }}</span>
                    </div>
                  </ng-template>
                </kendo-dropdownlist>
              </ng-template>
            </div>

            <input
              *ngSwitchDefault
              type="text"
              class="form-control"
              [formControl]="newForm.get(metaDataField.field)"
            />
          </ng-container>
          <div
            *ngIf="
              newForm.get(metaDataField.field)?.invalid &&
              !(isNew && newForm.get(metaDataField.field).untouched) &&
              !forbiddenFields.includes(metaDataField.field)
            "
            class="text-danger text-small"
          >
            <span *ngIf="newForm.get(metaDataField.field).hasError('required')">
              <small>{{ metaDataField.displayName }} is required. </small>
            </span>
            <span *ngIf="newForm.get(metaDataField.field).hasError('max')">
              <div [ngSwitch]="metaDataField.field">
                <div *ngSwitchCase="ItemField.leadTime">
                  <small *ngIf="!leadTimeAndOrderIntervalTotalError"
                    >Maximum is
                    {{ newForm.get(metaDataField.field).getError("max").max }}.
                  </small>
                  <small *ngIf="leadTimeAndOrderIntervalTotalError"
                    >Max total Lead time and Order interval must be less than or
                    equal to 360</small
                  >
                </div>
                <div *ngSwitchCase="ItemField.orderInterval">
                  <small *ngIf="!leadTimeAndOrderIntervalTotalError"
                    >Maximum is
                    {{ newForm.get(metaDataField.field).getError("max").max }}.
                  </small>
                  <small *ngIf="leadTimeAndOrderIntervalTotalError"
                    >Max total Lead time and Order interval must be less than or
                    equal to 360</small
                  >
                </div>
                <div *ngSwitchDefault>
                  <small>Maximum is {{ metaDataField | dataGetMax }}. </small>
                </div>
              </div>
            </span>
            <span *ngIf="newForm.get(metaDataField.field).hasError('min')">
              <small>Minimum is {{ metaDataField | dataGetMin }}. </small>
            </span>
            <span *ngIf="newForm.get(metaDataField.field).hasError('email')">
              <small>Invalid email address. </small>
            </span>
            <span
              *ngIf="newForm.get(metaDataField.field).hasError('isANumber')"
            >
              <small>{{ metaDataField.displayName }} must be a number.</small>
            </span>
            <span
              *ngIf="newForm.get(metaDataField.field)?.hasError('divisibleBy')"
            >
              <small>{{
                newForm.get(metaDataField.field)?.getError("divisibleBy")
              }}</small>
            </span>
            <span
              *ngIf="
                newForm.get(metaDataField.field)?.hasError('lessThanOrEqual')
              "
            >
              <small>{{
                newForm.get(metaDataField.field)?.getError("lessThanOrEqual")
              }}</small>
            </span>
            <span
              *ngIf="
                newForm.get(metaDataField.field)?.hasError('greaterThanOrEqual')
              "
            >
              <small>{{
                newForm.get(metaDataField.field)?.getError("greaterThanOrEqual")
              }}</small>
            </span>
          </div>
          <span
            *ngIf="metaDataField.field === 'dueDate' && isDateConflict"
            class="text-danger text-small"
          >
            <small>The Receive Date must be greater than the PO Date</small>
          </span>
        </div>
      </form>
      <div *ngIf="modalTitle === GridName.Supplier && isShowRestockAMZ">
        <span class="row">
          <hr class="col-7" />
          <button
            class="col-3 border-0 p-0 bg-transparent"
            [style]="{ 'font-size': '18px' }"
            (click)="openAddrerssModal(addNewAddress)"
            data-dismiss="modal"
          >
            <span> Add address </span>
            <span
              [style]="{ 'font-size': '22px' }"
              class="k-icon mb-md-3 k-i-plus-circle"
            ></span>
          </button>
          <hr class="col" />
        </span>
        <div class="row">
          <kendo-grid
            [data]="addressData"
            [height]="200"
            [selectable]="true"
            [reorderable]="true"
            (cellClick)="cellAddressClick(addNewAddress, $event)"
          >
            <kendo-grid-column
              field="countryCode"
              title="Country Code"
              editor="text"
            ></kendo-grid-column>
            <kendo-grid-column
              field="addressLine1"
              title="Street address"
            ></kendo-grid-column>
            <kendo-grid-column
              field="city"
              title="City"
              [width]="100"
            ></kendo-grid-column>
            <kendo-grid-column
              field="stateOrProvinceCode"
              title="State/Province"
            ></kendo-grid-column>
            <kendo-grid-column
              field="postalCode"
              title="Zip/Postal code"
            ></kendo-grid-column>
            <kendo-grid-column
              field="phoneNumber"
              title="Phone Number"
            ></kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
    <div class="modal-footer p-10">
      <a
        href="javascript://"
        (click)="modal.dismiss('Cross click') || cancelAddNewAddress(true)"
        >Cancel</a
      >
      <button
        type="button"
        class="btn btn-success"
        (click)="saveItem(modal)"
        [disabled]="newForm.invalid || loading"
        [ngStyle]="{ cursor: newForm.valid ? 'pointer' : 'not-allowed' }"
      >
        Save
      </button>
    </div>
  </ng-template>

  <ng-template class="supplierForm" #addNewAddress let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ modalAddressTitle }}</h5>
    </div>
    <div class="modal-body supplierForm">
      <div class="supplier-form">
        <div class="row">
          <div class="col-sm-12">
            <form *ngIf="supplierForm" [formGroup]="supplierForm">
              <div class="form-group">
                <label for="country">Country</label>
                <div>
                  <kendo-dropdownlist
                    isRequired="true"
                    id="country"
                    formControlName="country"
                    [data]="countries"
                    textField="name"
                    valueField="countryCode"
                    (valueChange)="countryChange($event)"
                    [style.width.%]="100"
                    [ngClass]="{
                      'is-invalid':
                        !country?.valid && (country?.dirty || country?.touched)
                    }"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>

              <div class="form-group">
                <label for="addressLine1">Street address</label>
                <input
                  id="addressLine1"
                  type="text"
                  class="form-control"
                  formControlName="addressLine1"
                  placeholder="Street and number, P.O. box, c/o."
                  [ngClass]="{
                    'is-invalid':
                      !addressLine1?.valid &&
                      (addressLine1?.dirty || addressLine1?.touched)
                  }"
                />
                <div
                  *ngIf="addressLine1.errors && addressLine1.dirty"
                  class="error jquery-validation-error small form-text invalid-feedback"
                >
                  <label *ngIf="addressLine1.hasError('required')">
                    <b>Street address is required.</b>
                  </label>
                  <label *ngIf="addressLine1.hasError('maxLength')">
                    <b>The maximum length is 180.</b>
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="city">City</label>
                <input
                  id="city"
                  type="text"
                  class="form-control"
                  formControlName="city"
                  [ngClass]="{
                    'is-invalid': !city?.valid && (city?.dirty || city?.touched)
                  }"
                />
                <div
                  *ngIf="city.errors && city.dirty"
                  class="error jquery-validation-error small form-text invalid-feedback"
                >
                  <label *ngIf="city.hasError('required')">
                    <b>City is required.</b>
                  </label>
                  <label *ngIf="city.hasError('maxLength')">
                    <b>The maximum length is 30.</b>
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="stateOrProvince">State/Province</label>
                <input
                  id="stateOrProvince"
                  *ngIf="country.value.countryCode !== 'US'"
                  type="text"
                  class="form-control"
                  formControlName="stateOrProvince"
                  [ngClass]="{
                    'is-invalid':
                      !stateOrProvince?.valid &&
                      (stateOrProvince?.dirty || stateOrProvince?.touched)
                  }"
                />
                <div
                  *ngIf="stateOrProvince.errors && stateOrProvince.dirty"
                  class="error jquery-validation-error small form-text invalid-feedback"
                >
                  <label *ngIf="stateOrProvince.hasError('required')">
                    <b>State/Province is required.</b>
                  </label>
                </div>
                <div *ngIf="country.value.countryCode === 'US'">
                  <kendo-dropdownlist
                    id="usState"
                    isRequired="true"
                    formControlName="usState"
                    [data]="states"
                    textField="name"
                    valueField="stateCode"
                    [style.width.%]="100"
                    [ngClass]="{
                      'is-invalid':
                        !usState?.valid && (usState?.dirty || usState?.touched)
                    }"
                  >
                  </kendo-dropdownlist>
                </div>
              </div>

              <div class="form-group">
                <label for="postalCode">Zip/Postal Code</label>
                <input
                  id="postalCode"
                  class="form-control"
                  type="text"
                  formControlName="postalCode"
                  [ngClass]="{
                    'is-invalid':
                      !postalCode?.valid &&
                      (postalCode?.dirty || postalCode?.touched)
                  }"
                />
                <div
                  *ngIf="postalCode.errors && postalCode.dirty"
                  class="error jquery-validation-error small form-text invalid-feedback"
                >
                  <label *ngIf="postalCode.hasError('required')">
                    <b>Postal code is required.</b>
                  </label>
                  <label *ngIf="postalCode.hasError('maxLength')">
                    <b>The maximum length is 30.</b>
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label for="phoneNumber">Phone number (optional)</label>
                <input
                  id="phoneNumber"
                  class="form-control"
                  type="tel"
                  formControlName="phoneNumber"
                  [ngClass]="{
                    'is-invalid':
                      !phoneNumber?.valid &&
                      (phoneNumber?.dirty || phoneNumber?.touched)
                  }"
                />
                <div
                  *ngIf="phoneNumber.errors && phoneNumber.dirty"
                  class="error jquery-validation-error small form-text invalid-feedback"
                >
                  <label *ngIf="phoneNumber.hasError('pattern')"
                    ><b>Enter a valid phone number.</b></label
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer p-10">
      <div>
        <a
          href="javascript://"
          (click)="modal.dismiss('Cross click'); cancelAddNewAddress()"
          >Cancel</a
        >
      </div>
      <div *ngIf="modalAddressTitle === 'Edit Address'">
        <button
          type="button"
          class="btn btn-danger mx-1"
          (click)="deleteAddress()"
        >
          Delete
        </button>
      </div>
      <button
        type="button"
        class="btn btn-success"
        [disabled]="supplierForm.invalid"
        (click)="handleAddress()"
        [ngStyle]="{ cursor: supplierForm.valid ? 'pointer' : 'not-allowed' }"
      >
        Save
      </button>
    </div>
  </ng-template>

  <kendo-window
    [title]="selectedFilter[0].title"
    *ngIf="isOpenFilter"
    (close)="onCloseWindowFilter()"
    [width]="FILTER_WINDOW_SIZE.WIDTH"
    [height]="FILTER_WINDOW_SIZE.HEIGHT"
    [left]="filterWindowLeft"
    [top]="filterWindowTop"
  >
    <div class="window-content">
      <div class="d-flex flex-column">
        <kendo-filter
          #popupFilter
          [filters]="selectedFilter"
          [value]="gridState.filter.filters[filterIndex] || defaultFilterValue"
          (valueChange)="onFilterChange($event)"
          class="popup-filter"
        >
        </kendo-filter>

        <button
          kendoButton
          icon="filter-add-expression"
          class="custom-add-filter"
          (click)="onAddFilter(popupFilter.value)"
        >
          Add Filter
        </button>
      </div>

      <button
        kendoButton
        type="button"
        (click)="applyExtraFilters(popupFilter.value)"
        [disabled]="isDisableFilter"
      >
        Apply
      </button>
    </div>
  </kendo-window>
</div>
