const OrderStatus = {
  Shipped: "Shipped",
  Cancelled: "Cancelled",
  Unshipped: "Unshipped",
  Closed: "Closed",
  Open: "Open",
  ToBeDeleted: "ToBeDeleted",
  Pending: "Pending",
  Complete: "Complete",
  Shipping: "Shipping",
};

module.exports = Object.freeze(OrderStatus);
