import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getMarketPlace } from '@stockaid/utils';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { concatMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { Company } from 'src/app/core/models/company';
import { Realm } from 'src/app/core/models/realm';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { CompanySelectionService } from 'src/app/core/services/company-selection.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { RealmService } from 'src/app/core/services/realm.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { SummaryService } from 'src/app/core/services/summary.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-company-dropdown',
  templateUrl: './company-dropdown.component.html',
  styleUrls: ['./company-dropdown.component.scss'],
})
export class CompanyDropdownComponent implements OnInit, OnDestroy {
  @Input() isAddCompanyVisible = true;
  @Input() currentCompanyKey: string;
  @Input() isCreateQuickBook: boolean = false;
  @Output() isAddCompany = new EventEmitter<boolean>();

  unsubscribe: Subject<boolean> = new Subject<boolean>();
  realms: Realm[] = [];
  company: Company = new Company();
  selectCompanyName: string = 'Select Company';
  selectedRealm: Realm;
  realms$: Observable<Realm[]>;
  isShowDropdown: boolean = false;

  readonly CompanyType = CompanyType;

  constructor(
    private companyService: CompanyService,
    private companySelectionService: CompanySelectionService,
    private realmService: RealmService,
    private router: Router,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private summaryService: SummaryService,
    private userService: UserService,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {  
    this.companySelectionService
      .getCompany()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((c) => {
        let newRealm = new Realm({
          companyKey: c.companyKey,
          companyName: c.companyName,
          companyType: c.companyType,
          userId: this.authService.currentUser.userId,
          marketplaceName: getMarketPlace(c.marketplaceId),
          created_at: c.createdAt,
          updated_at: c.updatedAt,
        });

        this.realms.push(newRealm);
        this.selectedRealm = newRealm;
        this.selectCompanyName = newRealm.companyName;
        this.switchRealms();
      });

    this.companySelectionService
      .getRemovedCompany()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((companyToRemove) => {
        this.realms = this.realms.filter(
          (realm) => companyToRemove.companyKey !== realm.companyKey
        );
        
        if (this.realms.length === 0) {
          location.reload();
          return;
        }

        if (companyToRemove.companyKey === this.selectedRealm.companyKey) {
          this.selectedRealm = this.realms[0];
          this.selectCompanyName = this.selectedRealm?.companyName;
          this.switchRealms();
        }

        this.realms$ = of(this.realms);
      });

    this.headerService
      .getCompanyName()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ newName, companyKey }) => {
        let idxToBeChanged;
        const realmToBeChanged = this.realms.find((realm, idx) => {
          idxToBeChanged = idx;
          return companyKey === realm.companyKey;
        });

        if (realmToBeChanged) {
          realmToBeChanged.companyName = newName;
          this.realms[idxToBeChanged] = realmToBeChanged;

          if (this.selectedRealm.companyKey === companyKey) {
            this.selectedRealm = realmToBeChanged;
            this.selectCompanyName = newName;
            this.switchRealms();
          }

          this.realms$ = of(this.realms);
        }
      });

    this.headerService
      .getLatestRealm()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((realm) => {
        this.selectedRealm = realm;
        this.switchRealms(false);
      });

    combineLatest([
      this.realmService.getAll(),
      this.companyService.getAll()
    ])
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(([realms, companies]) => {
      realms = this.mappingRealms(realms, companies);
      this.realms = realms;
      if (this.isAddCompanyVisible) {
        const newRealm = new Realm({
          companyKey: '0',
          companyName: '+ Add Company',
        });
        this.realms = [newRealm].concat(this.realms);
      }

      let realm: Realm =
        realms.find((r) => this.currentCompanyKey === r.companyKey) ||
        this.realmService.getLastUsedRealm(realms);
      this.selectedRealm = realm;

      this.realmService.save(realm, 'companyKey').subscribe();

      if (realms.length === 0) {
        this.router.navigate(['/onboarding/create']);
      }

      const companyKey = this.route.snapshot.queryParamMap.get('companyKey');
      const companyType = this.route.snapshot.queryParamMap.get('companyType');
      if (companyKey && companyType) {
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { companyKey: null, companyType: null },
            queryParamsHandling: 'merge',
          });
        const redirectedRealm = realms.find((r) => r.companyKey === companyKey && r.companyType === companyType);
        const lastUsedRealm = this.realmService.getLastUsedRealm(realms);
        if (lastUsedRealm.companyKey !== companyKey || lastUsedRealm.companyType !== companyType) {
          this.switchRealms(redirectedRealm);
          return;
        }
      }

      if (!this.companyService.currentCompany()) {
        return this.determineCompany(realm?.companyKey).subscribe();
      } else {
        this.company = this.companyService.currentCompany();
        this.headerService.sendCompany(this.company);
        this.headerService.setChange(true);
        this.selectCompanyName =
          this.companyService.currentCompany().companyName;
      }
    })  
  }

  mappingRealms(realms, data) {
    return realms.map((realm) => {
      const d = data.find((d) => {
        return d.companyType === realm.companyType && d.companyKey === realm.companyKey
      })
      if (d) {
        return { ...realm, marketplaceName: getMarketPlace(d.marketplaceId), updated_at: d.updatedAt };
      }
    });
  }

  determineCompany(companyKey: string): Observable<Company> {
    return this.companyService.getById(companyKey, true).pipe(
      // take(1),
      tap((c) => {
        this.company = c;
        this.selectCompanyName = c?.companyName;
        this.headerService.sendCompany(this.company);
        this.headerService.setChange(true);
      }),
      concatMap((res) => {
        this.chooseRouteDirection(res);
        return of(res);
      })
    );
  }

  switchRealms(realm?, shouldEmitRealm = true): void {
    if(realm) {
      this.selectedRealm = realm;
    }

    // Remove metadata when switch company
    this.sessionStorage.remove('metadata');

    // Clear current Items in PO when switch company
    this.summaryService.saveItemsToCache([]);

    //currently selected
    if (this.selectedRealm.companyKey === this.company?.companyKey) {
      return;
    }

    //selected + Add Company
    if (this.selectedRealm.companyKey === '0') {
      this.isAddCompany.emit(true)
      this.router.navigate(['/onboarding/create']);
      return;
    }

    // Reset page offset of grids when switching company
    this.authService
      .getLoggedInUserFromSession()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((user) => {
          const [currentUser] = user?.model || [];

          for (const key in currentUser) {
            if (key.includes('gridState') && currentUser[key].pagination) {
              currentUser[key].pagination.paginationCurrentPage = 0;
            }
          }

          return this.userService.editUser(currentUser);
        })
      )
      .subscribe();

    this.realmService
      .save(this.selectedRealm, 'companyKey')
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap((res) => {
          return this.determineCompany(this.selectedRealm.companyKey);
        })
      )
      .subscribe(() => {
        if (shouldEmitRealm) {
          this.headerService.sendRealm(this.selectedRealm);
        }
      });
  }

  chooseRouteDirection(company: Company) {
    //if on the admin page.. do nothing.
    if (!company
      || this.router.url.includes('administration')
      || this.router.url.includes('subscription')
      || (company.companyType === CompanyType.ASC && !company.isAuthorized)) {
      return;
    }

    //if no forecast date, the company may need more work on the onboarding.
    if (company.jobInitiator || !company.lastForecastDate) {
      this.router.navigate([
        '/onboarding/processing',
        { companyKey: company.companyKey },
      ]);
    }

    //check to see if we are on the the dashboard page
    if (
      !['/dashboard', '/profile', '/notification'].some((partialUrl) =>
        this.router.url.includes(partialUrl)
      ) &&
      company.lastForecastDate
    ) {
      this.router.navigate([
        '/dashboard/home'
      ]);
    }
  }
}
