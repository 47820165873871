import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from './../../../environments/environment';
import { AuthenticationService } from './authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: any;

  constructor(private authService: AuthenticationService) {
    this.pusher = new Pusher(environment.pusher.PUSHER_API_KEY, {
      cluster: environment.pusher.PUSHER_API_CLUSTER,
      forceTLS: false,
    });
  }

  // https://pusher.com/tutorials/live-countdown-angular
  subScribeToChannel(channelName: string, events: string[], cb: Function) {
    const channel = this.pusher.subscribe(channelName);

    events.forEach((event) => {
      channel.bind(event, function (data) {
        cb(data);
      });
    });
  }

  get userChannel() {
    return this.pusher.channel(this.authService.currentUser?.userId);
  }

  // triggerEvent(channelName: String, event: String, data: Object): Observable<Object> {
  //   return this.http.post(`${environment.apiUrl}/pusher/trigger`, {
  //     channel_name: channelName,
  //     event_name: event,
  //     data: data
  //   })
  // }
}
