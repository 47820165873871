import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { StockaidResponse } from '../misc/stockaid-response';
import { Pagination } from 'src/app/core/infrastructure/classes/pagination';
import { SortAttribute } from 'src/app/core/infrastructure/classes/sort-attribute';
import { SortDescriptor } from '@progress/kendo-data-query';
import { v4 as uuid } from 'uuid';
import { stringifyJSONWithDates } from 'src/app/core/utils';
import {
  IAdditionalKeys,
  AdditionalKeysType,
} from 'src/app/core/infrastructure/interfaces/additional-keys.interface';
import { GridName } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import _ from 'lodash';

export abstract class ResourceService<T> {
  private countCache$: Observable<number>;
  private countFilter: any;
  private countItemsKeySelected: string[] = [];
  private countTime = 0;
  protected currentCompanyKey: string;
  constructor(protected httpClient: HttpClient, protected apiUrl: string) {}

  getStockAid(): Observable<StockaidResponse<T[]>> {
    return this.httpClient.get<StockaidResponse<T[]>>(this.apiUrl);
  }

  getAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.apiUrl);
  }

  getFiltered(
    pagination: Pagination,
    sort: SortAttribute[],
    filter: any,
    altApiUrl: string,
    itemsKeySelected: string[],
    additionalOptions: {
      restockKey?: string;
      marketplaceId?: string;
      currencyCode?: string;
      demandSourcePreference?: string;
      isAWD?: boolean;
      purchaseOrderKey?: string;
    }
  ): Observable<T[]> {
    const {
      restockKey,
      marketplaceId,
      currencyCode,
      demandSourcePreference,
      isAWD,
      purchaseOrderKey,
    } = additionalOptions || {};
    pagination = pagination || null;
    sort = sort || [];
    filter = filter || null;

    let params = new HttpParams();

    if (sort.length) {
      params = params.set('sort', JSON.stringify(sort));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (itemsKeySelected?.length) {
      const getRequests = this.getMultiple(
        itemsKeySelected,
        this.apiUrl,
        params
      );

      return forkJoin(getRequests).pipe(
        map((results) => {
          let items = [];
          results.forEach((rs) => (items = items.concat(rs)));

          return items as T[];
        })
      );
    }

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (restockKey) {
      params = params.set('restockKey', restockKey);
    }

    if (isAWD) {
      params = params.set('isAWD', isAWD.toString());
    }

    if (purchaseOrderKey) {
      params = params.set('purchaseOrderKey', purchaseOrderKey);
    }

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    if (demandSourcePreference) {
      params = params.set('demandSourcePreference', demandSourcePreference);
    }

    return this.httpClient.get<T[]>(altApiUrl || `${this.apiUrl}`, { params });
  }

  getFilteredUserTelerikEvent(
    skip: number,
    take: number,
    filter: any,
    sort: SortDescriptor[] = [],
    altApiUrl = '',
    itemsKeySelected?: string[],
    additionalOptions?: {
      restockKey?: string;
      marketplaceId?: string;
      currencyCode?: string;
      demandSourcePreference?: string;
      isAWD?: boolean;
      purchaseOrderKey?: string;
    }
  ): Observable<T[]> {
    const {
      restockKey,
      marketplaceId,
      currencyCode,
      demandSourcePreference,
      isAWD,
      purchaseOrderKey,
    } = additionalOptions || {};
    const pagination = new Pagination({ offset: skip, limit: take });

    const sortArray = this.parseSortT(sort);

    return this.getFiltered(
      pagination,
      sortArray,
      filter,
      altApiUrl,
      itemsKeySelected,
      {
        restockKey,
        marketplaceId,
        currencyCode,
        demandSourcePreference,
        isAWD,
        purchaseOrderKey,
      }
    );
  }

  protected parseSortT(sort: SortDescriptor[]): SortAttribute[] {
    //if undefined, return empty array.
    if (!sort.length) {
      return [];
    }

    return sort.map((sorter) => {
      return new SortAttribute({
        field: sorter.field,
        direction: sorter.dir || 'asc',
      });
    });
  }

  getCount(
    filter: any = null,
    altApiUrl = '',
    companyKey: string = null,
    itemsKeySelected: string[] = [],
    additionalOptions?: {
      marketplaceId?: string;
      currencyCode?: string;
      demandSourcePreference?: string;
      isAWD?: boolean;
    }
  ): Observable<number> {
    const { marketplaceId, currencyCode, demandSourcePreference, isAWD } =
      additionalOptions || {};

    let params = new HttpParams();

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (itemsKeySelected.length) {
      const getRequests = this.getMultiple(
        itemsKeySelected,
        this.apiUrl,
        params
      );

      return forkJoin(getRequests).pipe(
        map((results) => {
          let count: number = 0;
          results.forEach((rs) => (count += rs.length));
          return count;
        })
      );
    }

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    if (demandSourcePreference) {
      params = params.set('demandSourcePreference', demandSourcePreference);
    }

    if (isAWD) {
      params = params.set('isAWD', isAWD.toString());
      return this.httpClient.get<number>(`${altApiUrl || this.apiUrl}/count`, {
        params,
      });
    }

    const cacheExpiration = 1000 * 60;
    const currentTime = new Date().getTime();
    if (
      !this.countCache$ ||
      currentTime - this.countTime > cacheExpiration ||
      !_.isEqual(filter, this.countFilter) ||
      !_.isEqual(itemsKeySelected, this.countItemsKeySelected) ||
      (companyKey && companyKey !== this.currentCompanyKey)
    ) {
      this.countCache$ = this.httpClient
        .get<number>(`${altApiUrl || this.apiUrl}/count`, {
          params,
        })
        .pipe(shareReplay(1));

      this.countTime = currentTime;
      this.countFilter = filter;
      this.countItemsKeySelected = itemsKeySelected;
      this.currentCompanyKey = companyKey;
    }

    return this.countCache$;
  }

  // The api for getting count is 'api/count/[demand, supply, bom]'
  getCountDSB(filter: any = null): Observable<number> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }
    const url = this.apiUrl.split('/');

    return this.httpClient.get<number>(`api/count/${url[2]}`, { params });
  }

  getById(
    id: any,
    marketplaceId?: string | boolean,
    currencyCode?: string
  ): Observable<T> {
    let params = new HttpParams();
    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId as string)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.get<T>(`${this.apiUrl}/${encodeURIComponent(id)}`, {
      params,
    });
  }

  save(
    item: T | Partial<T>,
    objectKey: any = 'id',
    additionalKeys: IAdditionalKeys = {},
    additionalOptions: any = {}
  ): Observable<any> {
    // Only Demand, Supply, Bom will have additionalKeys.type
    if (!additionalKeys.type && (item[objectKey] === '' || !item[objectKey])) {
      // new item
      return this.httpClient.post(`${this.apiUrl}`, item);
    }
    let params = new HttpParams();
    switch (additionalKeys.type) {
      case AdditionalKeysType.createBom:
        return this.httpClient.post(
          `${this.apiUrl}/${encodeURIComponent(
            item['parentKey']
          )}/${encodeURIComponent(item['childKey'])}`,
          item
        );

      case AdditionalKeysType.updateBom:
        if (additionalKeys.parentKey && additionalKeys.childKey) {
          // When updating parent name or child name of a bom row
          // Need to preserve the old parentKey and childKey
          return this.httpClient.put(
            `${this.apiUrl}/${encodeURIComponent(
              additionalKeys.parentKey
            )}/${encodeURIComponent(additionalKeys.childKey)}`,
            item
          );
        }

        // When simply updating quantity of a bom row or through adding a bom (same parent name, child name)
        return this.httpClient.put(
          `${this.apiUrl}/${encodeURIComponent(
            item['parentKey']
          )}/${encodeURIComponent(item['childKey'])}`,
          item
        );

      case AdditionalKeysType.demand:
      case AdditionalKeysType.supply:
        if (item['docType'] && item['orderKey'] && item['rowKey']) {
          let query = `${encodeURIComponent(
            item['docType']
          )}/${encodeURIComponent(item['orderKey'])}/${encodeURIComponent(
            item['rowKey']
          )}`;
          if (additionalKeys.type === AdditionalKeysType.demand) {
            query += `/${encodeURIComponent(item['orderItemId'])}`;
          }

          return this.httpClient.put(`${this.apiUrl}/${query}`, item);
        }

        if (
          additionalOptions?.marketplaceId &&
          additionalOptions?.currencyCode
        ) {
          params = params
            .set('marketplaceId', additionalOptions.marketplaceId)
            .set('currencyCode', additionalOptions.currencyCode);
        }

        if (additionalOptions?.isClosePo) {
          params = params.set('isClosePo', additionalOptions.isClosePo);
        }

        item['docType'] = 'manual';
        item['orderKey'] = uuid();
        item['rowKey'] = uuid();

        let query = `${encodeURIComponent(
          item['docType']
        )}/${encodeURIComponent(item['orderKey'])}/${encodeURIComponent(
          item['rowKey']
        )}`;
        if (additionalKeys.type === AdditionalKeysType.demand) {
          item['orderItemId'] = 'null';
          query += `/${encodeURIComponent(item['orderItemId'])}`;
        }

        return this.httpClient.post(
          `${this.apiUrl}/${query}`,
          item,
          { params }
        );

      case AdditionalKeysType.itemSite:
        return this.httpClient.put(
          `${this.apiUrl}/${encodeURIComponent(
            item['itemKey']
          )}/${encodeURIComponent(item['key'])}`,
          item
        );

      default:
        if (additionalOptions.shipmentItemKey) {
          params = params.set(
            'shipmentItemKey',
            additionalOptions.shipmentItemKey
          );
        }

        if (
          additionalOptions?.marketplaceId &&
          additionalOptions?.currencyCode
        ) {
          params = params
            .set('marketplaceId', additionalOptions.marketplaceId)
            .set('currencyCode', additionalOptions.currencyCode);
        }

        if (additionalOptions?.isClosePo) {
          params = params.set('isClosePo', additionalOptions.isClosePo);
        }

        return this.httpClient.put(
          `${this.apiUrl}/${encodeURIComponent(item[objectKey])}`,
          item,
          { params }
        );
    }
  }

  delete(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }

  deleteHideMultiple(
    ids: string[],
    itemName?: string,
    isHiding = false
  ): Observable<any> {
    let finalIds: string[];
    if ([GridName.Bom, GridName.ItemSite].includes(itemName as GridName)) {
      // Filter out ids of bom that already included in the parent keys
      const parentKeys = [];
      ids.forEach((id) => id.split('/').length === 1 && parentKeys.push(id));
      finalIds = ids.filter((id) => {
        const [parentKey, childKey] = id.split('/') || [];
        return !childKey || !parentKeys.includes(parentKey);
      });
    } else {
      finalIds = ids;
    }

    const idsChunks: string[][] = [];
    while (finalIds?.length) {
      idsChunks.push(finalIds.splice(0, 5000));
    }

    const updateRequests: Observable<any>[] = [];
    idsChunks.forEach((idsToBeUpdated) => {
      const httpOptions = {
        body: {
          ids: idsToBeUpdated,
        },
      } as any;
      updateRequests.push(
        isHiding
          ? this.httpClient.post(`${this.apiUrl}/hide`, { ids: idsToBeUpdated })
          : this.httpClient.delete(`${this.apiUrl}`, httpOptions)
      );
    });

    let isFinalPODeleted = false;
    return forkJoin(updateRequests).pipe(
      map((results) => {
        let failToUpdateItems = [];
        results.forEach(({ failItems, isPODeleted }) => {
          isFinalPODeleted = isPODeleted ? isPODeleted : isFinalPODeleted;
          failToUpdateItems = failToUpdateItems.concat(failItems);
        });

        return {
          failToUpdateItems: failToUpdateItems.filter((i) => i),
          isPODeleted: isFinalPODeleted,
        };
      })
    );
  }

  getByMultipleIds(ids: string[]): Observable<T[]> {
    if (!ids.length) {
      return of([]);
    }

    const idsChunks: string[][] = [];
    while (ids?.length) {
      idsChunks.push(ids.splice(0, 100));
    }

    const getRequests: Observable<any>[] = [];
    idsChunks.forEach((chunk) => {
      let includedIds = '';
      chunk.forEach(
        (id, idx) =>
          (includedIds +=
            idx === chunk.length - 1
              ? encodeURIComponent(id)
              : `${encodeURIComponent(id)},`)
      );

      getRequests.push(
        this.httpClient.get<T[]>(`${this.apiUrl}?ids=${includedIds}`)
      );
    });

    return forkJoin(getRequests).pipe(
      map((results) => {
        let items = [];
        results.forEach((rs) => (items = items.concat(rs)));

        return items;
      })
    );
  }

  getMultiple(idsOrigin, url, params): Observable<any>[] {
    const idsChunks: string[][] = [];
    const ids = [...idsOrigin];
    while (ids?.length) {
      idsChunks.push(ids.splice(0, 100));
    }

    const getRequests: Observable<any>[] = [];
    idsChunks.forEach((chunk) => {
      let includedIds = '';
      chunk.forEach(
        (id, idx) =>
          (includedIds +=
            idx === chunk.length - 1
              ? encodeURIComponent(id)
              : `${encodeURIComponent(id)},`)
      );

      const newParams = params.set('ids', includedIds);

      getRequests.push(this.httpClient.get(url, { params: newParams }));
    });

    return getRequests;
  }

  get serviceApiUrl() {
    return this.apiUrl;
  }

  updateMultiple(dataItems: T[]): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}`, dataItems);
  }
}
