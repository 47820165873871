const getTopLevelDomain = (marketplaceId: string): string => {
  switch (marketplaceId) {
    case "A2EUQ1WTGCTBG2":
      return ".ca"; // Canada
    case "A1AM78C64UM0Y8":
      return ".com.mx"; // Mexico
    case "A1PA6795UKMFR9":
    case "EU":
      return ".de"; // Germany
    case "A1RKKUPIHCS9HS":
      return ".es"; // Spain
    case "A13V1IB3VIYZZH":
      return ".fr"; // France
    case "A1805IZSGTT6HS":
      return ".nl"; // Netherlands
    case "APJ6JRA9NG5V4":
      return ".it"; // Italy
    case "A2NODRKZP88ZB9":
      return ".se"; // Sweden
    case "A1C3SOZRARQ6R3":
      return ".pl"; // Poland
    case "A33AVAJ2PDY3EV":
      return ".com.tr"; // Turkey
    case "A1F83G8C2ARO7P":
      return ".co.uk"; // UK
    case "A21TJRUUN4KGV":
      return ".in"; // India
    case "A1VC38T7YXB528":
      return ".jp"; // Japan
    case "AAHKV2X7AFYLW":
      return ".com.cn"; // China
    default:
      return ".com"; // US
  }
}

export const amazonShipmentDetailLink = (shipmentId: string, isAWD = false): string => {
  return `https://sellercentral.amazon.com/${isAWD ? 'awd' : 'fba'}/inbound-shipment/summary/${shipmentId}/shipmentEvents`;
}

export const amazonInventoryAllLink = (marketplaceId: string): string => {
  const topLevelDomain = getTopLevelDomain(marketplaceId);
  return `https://sellercentral.amazon${topLevelDomain}/inventory/view/FBAKNIGHTS?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:;pagination:1;`;
}

export const amazonProductLink = (asin: string, marketplaceId: string): string => {
  const topLevelDomain = getTopLevelDomain(marketplaceId);
  return `https://www.amazon${topLevelDomain}/gp/product/${asin}`;
}

export const amazonSkuCentralLink = (sku: string, marketplaceId: string): string => {
  const topLevelDomain = getTopLevelDomain(marketplaceId);
  return `https://sellercentral.amazon${topLevelDomain}/skucentral?mSku=${sku}`;
}

export const amazonInventoryLink = (searchQuery: string, marketplaceId: string): string => {
  const topLevelDomain = getTopLevelDomain(marketplaceId);
  return `https://sellercentral.amazon${topLevelDomain}/hz/inventory?noRedirect=true&viewId=FBAKNIGHTS&tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:${searchQuery};pagination:1;`;
}

export const getSendToAmazonLink = (marketplaceId: string, inboundPlanId: string): string => {
  const topLevelDomain = getTopLevelDomain(marketplaceId);
  return `https://sellercentral.amazon${topLevelDomain}/fba/sendtoamazon/pack_mixed_unit_step?wf=${inboundPlanId}`;
}

export const getCurrencyCodeByMarketplaceId = (marketplaceId: string): string => {
  switch (marketplaceId) {
    case 'A2EUQ1WTGCTBG2': // Canada
      return 'CAD';
    case 'ATVPDKIKX0DER': // US
    case 'A1805IZSGTT6HS': // Netherlands
    case 'NA':
      return 'USD';
    case 'A1AM78C64UM0Y8': // Mexico
      return 'MXN';
    case 'A2Q3Y263D00KWC': // Brazil
      return 'BRL'
    case 'A1F83G8C2ARO7P': // UK
      return 'GBP'
    case 'A1RKKUPIHCS9HS': // Spain
    case 'A13V1IB3VIYZZH': // France
    case 'A1PA6795UKMFR9': // Germany
    case 'APJ6JRA9NG5V4': // Italy
    case 'EU':
      return 'EUR';
    case 'A2NODRKZP88ZB9': // Sweden
      return 'SEK';
    case 'A1C3SOZRARQ6R3': // Poland
      return 'PLN'
    case 'ARBP9OOSHTCHU': // Egypt
      return 'EGP';
    case 'A33AVAJ2PDY3EV': // Turkey
      return 'TRY'
    case 'A2VIGQ35RCS4UG': // United Arab Emirates
      return 'AED';
    case 'A21TJRUUN4KGV': // India
      return 'INR';
    case "A19VAU5U5O7RUS": // Singapore
      return 'SGD'
    case "A39IBJ37TRP1C6": // Australia
      return 'AUD'
    case "A1VC38T7YXB528": // Japan
      return 'JPY'
    case "AAHKV2X7AFYLW": // China
      return 'CNY'
  }
}
