import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTestIDDirective, IntegerNumberDirective } from './directives';

@NgModule({
  declarations: [DataTestIDDirective, IntegerNumberDirective],
  exports: [DataTestIDDirective, IntegerNumberDirective],
  imports: [CommonModule],
})
export class CoreModule {}
