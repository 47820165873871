import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { Shipment } from '../models/shipment';
import { InboundAnalyzer } from '../models/inbound-analyzer';
import { ShipmentItem } from '../models/shipment-item';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { FileDetails } from '../models/file-details';
import { OrderQtyAutofillType } from '@stockaid/shared-enums';
import { map } from 'rxjs/operators';
import { PurchaseOrder } from '../models/purchase-order';
import { PlacementOption } from '../models/placement-option';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService extends ResourceService<Shipment> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/shipment');
  }

  downloadInventoryTemplate(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}/download-inventory-template`, {
      responseType: 'text',
    });
  }

  uploadInventory(
    shipmentName: string,
    fileDetails?: FileDetails,
    oldRestockKey: string = null,
    supplier: string = null
  ): Observable<Shipment> {
    const body: any = { shipmentName, oldRestockKey, supplier };
    if (fileDetails) {
      body.fileDetails = fileDetails;
    }

    return this.httpClient.post<Shipment>(
      `${this.apiUrl}/upload-inventory`,
      body
    );
  }

  createShipmentWithOneItem(
    shipmentName: string,
    supplier: string,
    shipmentItem: ShipmentItem,
    supplierCost: number
  ): Observable<Shipment> {
    return this.httpClient.post<Shipment>(`${this.apiUrl}`, {
      shipmentName,
      supplier,
      shipmentItem,
      supplierCost,
    });
  }

  createShipmentWithMultipleItems(
    shipmentName: string,
    supplier: string,
    recommendedField: string
  ): Observable<Shipment> {
    return this.httpClient.post<Shipment>(`${this.apiUrl}/multiple-items`, {
      shipmentName,
      supplier,
      recommendedField,
    });
  }

  createShipmentFromPurchaseOrder(
    shipmentName: string,
    purchaseOrder: PurchaseOrder
  ): Observable<Shipment> {
    return this.httpClient.post<Shipment>(`${this.apiUrl}/purchase-order`, {
      shipmentName,
      purchaseOrderKey: purchaseOrder.key,
      refNum: purchaseOrder.refNum,
      addressFromKey: purchaseOrder.supplierKey,
    });
  }

  createShipmentPlan(
    restockKey,
    address,
    labelPrepPreference
  ): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/create-shipment-plan`, {
      restockKey,
      address,
      labelPrepPreference,
    });
  }

  createShipment(restockKey: string): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/create-shipment`, {
      restockKey,
    });
  }

  completeShipment(restockKey: string): Observable<Shipment> {
    return this.httpClient.post<any>(`${this.apiUrl}/complete`, { restockKey });
  }

  refreshSingleShipment(shipmentKey: string): Observable<Shipment> {
    return this.httpClient.put<Shipment>(
      `${this.apiUrl}/${shipmentKey}/refresh`,
      {}
    );
  }

  modifyShipment(shipmentKey: string, modifyShipment): Observable<Shipment> {
    return this.httpClient.put<Shipment>(
      `${this.apiUrl}/${shipmentKey}/modify`,
      { ...modifyShipment }
    );
  }

  getByIdWithAdditionalInfos(
    shipmentKey: string,
    additionalInfos: string[],
    marketplaceId?: string,
    currencyCode?: string
  ): Observable<Shipment> {
    let params = new HttpParams();
    additionalInfos.forEach((i) => (params = params.set(i, 'true')));

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.get<Shipment>(`${this.apiUrl}/${shipmentKey}`, {
      params,
    });
  }

  checkShipmentLocalQtyError(shipmentKey: string): Observable<{
    doesInvalidShipmentItemsExist: boolean;
    doesInvalidCasePackItemsExist: boolean;
  }> {
    return this.httpClient.get<{
      doesInvalidShipmentItemsExist: boolean;
      doesInvalidCasePackItemsExist: boolean;
    }>(`${this.apiUrl}/${shipmentKey}/check-local-qty-error`);
  }

  getRsShipments(
    searchTerm: string
  ): Observable<(Shipment & { vendorName: string })[]> {
    let params = new HttpParams();
    if (searchTerm) {
      params = params.set('shipmentName', searchTerm);
    }

    return this.httpClient
      .get<Shipment[]>(`${this.apiUrl}/restock-suggestion`, { params })
      .pipe(
        map((shipments) =>
          shipments.map((s) => ({ ...s, vendorName: s.vendorNameItemBelongTo }))
        )
      );
  }

  updateShipmentOrderQty(
    shipmentKey: string,
    autofillType: OrderQtyAutofillType,
    isChecked: boolean,
    supplier?: string
  ) {
    return this.httpClient.put<{ waitForJob: boolean }>(
      `${this.apiUrl}/${shipmentKey}/update-order-qty/${autofillType}`,
      {
        isChecked,
        supplier,
      }
    );
  }

  getInboundAnalyzerByItemKey(itemKey: string): Observable<InboundAnalyzer> {
    return this.httpClient.get<InboundAnalyzer>(
      `${this.apiUrl}/inbound-analyzer/${itemKey}`
    );
  }

  delete(id: string, isSubtractWarehouse?: boolean): Observable<any> {
    if (!id) {
      return of(null);
    }
    let params = new HttpParams();
    if (isSubtractWarehouse) {
      params = params.set('isSubtractWarehouse', String(isSubtractWarehouse));
    }
    return this.httpClient.delete(`${this.apiUrl}/${id}`, { params });
  }

  checkCanApplyRecommendedQty(supplier: string, recommendedField: string) {
    const params = new HttpParams()
      .set('supplier', supplier)
      .set('recommendedField', recommendedField);

    return this.httpClient
      .get<{ canApplyRecommendedQty: boolean }>(
        `${this.apiUrl}/check-can-apply-recommended-qty`,
        { params }
      )
      .pipe(map(({ canApplyRecommendedQty }) => canApplyRecommendedQty));
  }

  getPlacementOptionsByRestockKey(restockKey: string, currencyCode: string) {
    const params = new HttpParams().set('currencyCode', currencyCode);

    return this.httpClient.get<PlacementOption[]>(
      `${this.apiUrl}/${restockKey}/placement-option`,
      { params }
    );
  }

  confirmPlacementOption(restockKey: string) {
    return this.httpClient.post(
      `${this.apiUrl}/${restockKey}/confirm-placement-option`,
      {}
    );
  }
}
