const OBJECT_TYPE = "object";
const _ = require("lodash");

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === OBJECT_TYPE && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

function splitToArray(str, delimiter = ",") {
  if (!str || !str.length) {
    throw new Error("splitToArray() > Please provide str");
  }

  const regex = new RegExp(`${delimiter}|\s+${delimiter}\s+`, "g");

  return str
    .split(regex)
    .reduce((result, item) => (item ? result.concat(item.trim()) : result), []);
}

function timezoneOffset() {
  return {
    _value: null,

    get value() {
      return this._value;
    },

    set value(newValue) {
      this._value = newValue;
    },
  };
}

function stringify(obj) {
  try {
    return JSON.stringify(obj, getCircularReplacer());
  } catch (error) {
    return String(obj);
  }
}

function sleep(s) {
  return new Promise((resolve) => setTimeout(resolve, s * 1000));
}

function parseNumber(number) {
  if (!number) {
    return 0;
  }

  return !isNaN(number) ? Number(number) : 0;
}

function roundFloatNumber(number) {
  return Math.round((parseNumber(number) + Number.EPSILON) * 100) / 100;
}

/**
 * Transform Oversize to Yes/No
 *
 * @param value
 * @returns Yes/No
 */
function transformOversizeToYesNo(value) {
  if (!_.isString(value) || value === "") {
    return "";
  }

  return value.toLowerCase().includes("oversize") ? "Yes" : "No";
}

function transformInfinityToZero(value) {
  if ([Infinity, -Infinity].includes(value)) {
    return 0;
  }

  return value;
}

function getAutoAdjustedWeightings(itemHistoryLength) {
  switch (true) {
    case itemHistoryLength <= 30:
      return {
        percent2Day: 10,
        percent7Day: 45,
        percent14Day: 45,
        percent30Day: 0,
        percent60Day: 0,
        percent90Day: 0,
        percent180Day: 0,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 31 && itemHistoryLength <= 60:
      return {
        percent2Day: 10,
        percent7Day: 30,
        percent14Day: 30,
        percent30Day: 30,
        percent60Day: 0,
        percent90Day: 0,
        percent180Day: 0,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 61 && itemHistoryLength <= 90:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 40,
        percent60Day: 30,
        percent90Day: 0,
        percent180Day: 0,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 91 && itemHistoryLength <= 120:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 30,
        percent60Day: 15,
        percent90Day: 25,
        percent180Day: 0,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 121 && itemHistoryLength <= 180:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 30,
        percent60Day: 20,
        percent90Day: 20,
        percent180Day: 0,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 181 && itemHistoryLength <= 360:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 20,
        percent60Day: 20,
        percent90Day: 20,
        percent180Day: 10,
        percentForecasted: 0,
      };

    case itemHistoryLength >= 361 && itemHistoryLength <= 750:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 20,
        percent60Day: 10,
        percent90Day: 10,
        percent180Day: 10,
        percentForecasted: 20,
      };

    case itemHistoryLength >= 751:
      return {
        percent2Day: 10,
        percent7Day: 10,
        percent14Day: 10,
        percent30Day: 0,
        percent60Day: 0,
        percent90Day: 0,
        percent180Day: 0,
        percentForecasted: 70,
      };
  }
}

const conditionalOperator = (expression, a, b) => {
  return expression ? a : b;
};

const logicalORAssignmentOperator = (a, b) => {
  return a || b;
};

function asyncFunction(dbService, action, params, customOptions) {
  return new Promise((resolve, reject) =>
    dbService[action](
      params,
      (err, model) => (err ? reject(err) : resolve(model)),
      customOptions
    )
  );
}

function roundArray(a) {
  if (Array.isArray(a)) {
    a.forEach(function (x, i) {
      if (typeof x == "string") x = parseFloat(x);
      a[i] = Math.round(x);
    });
    return a;
  } else {
    const emptyArray = Array(...Array(12)).map(function (x, i) {
      return 0;
    });

    return emptyArray;
  }
}

const transformStringToMathOperator = (operator) => {
  switch (operator) {
    case "eq":
      return "=";

    case "neq":
      return "!=";

    case "gte":
      return ">=";

    case "gt":
      return ">";

    case "lte":
      return "<=";

    case "lt":
      return "<";

    case "isnull":
      return "IS";

    case "isnotnull":
      return "IS NOT";
  }
};

const formatNumber = (value, decimalDigits = 2) => {
  if (!_.isNumber(value)) {
    return 0;
  }
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalDigits
  });
}

const parseCSVRow = (row) => {
  const matches = [];
  const regex = /(?:^|,)(?:"([^"]*(?:""[^"]*)*)"|\s*([^,]*)\s*)/g;
  let match;

  while ((match = regex.exec(row)) !== null) {
    const value = match[1] !== undefined ? match[1] : match[2];
    matches.push(value === '' ? null : value);
  }

  return matches;
}

module.exports = {
  timezoneOffset,
  splitToArray,
  stringify,
  sleep,
  parseNumber,
  roundFloatNumber,
  transformOversizeToYesNo,
  transformInfinityToZero,
  getAutoAdjustedWeightings,
  conditionalOperator,
  logicalORAssignmentOperator,
  asyncFunction,
  roundArray,
  transformStringToMathOperator,
  formatNumber,
  parseCSVRow
};
