import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShipmentDetail } from '../models/shipment-detail';
import { State } from '@progress/kendo-data-query';
import { Pagination } from 'src/app/core/infrastructure/classes/pagination';
import { stringifyJSONWithDates } from '@stockaid/utils';
import { ShipmentDetailType } from '@stockaid/shared-enums';
import { RestockType } from '../infrastructure/enums/restock-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ShipmentDetailService extends ResourceService<ShipmentDetail> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/shipment-detail');
  }

  getShipmentDetailsByRestockKey(
    key: string,
    type: ShipmentDetailType,
    state: State = {},
    restockType: RestockType = RestockType.Supplier,
    placementOptionId?: string,
    marketplaceId?: string,
    currencyCode?: string
  ): Observable<ShipmentDetail[]> {
    const { skip, take, sort, filter } = state;
    const pagination = new Pagination({ offset: skip || 0, limit: take });
    const sortArray = this.parseSortT(sort || []);
    let params = new HttpParams();

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (sortArray.length) {
      params = params.set('sort', JSON.stringify(sortArray));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (key) {
      params = params.set('key', key);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (restockType) {
      params = params.set('restockType', restockType);
    }

    if (placementOptionId) {
      params = params.set('placementOptionId', placementOptionId);
    }

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.get<ShipmentDetail[]>(this.apiUrl, { params });
  }

  getShipmentDetailsCountByRestockKey(
    key: string,
    type: ShipmentDetailType,
    filter = null,
    restockType: RestockType = RestockType.Supplier
  ): Observable<number> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (key) {
      params = params.set('key', key);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (restockType) {
      params = params.set('restockType', restockType);
    }

    return this.httpClient.get<number>(`${this.apiUrl}/count`, { params });
  }

  updateMultipleShipmentItems(
    shipmentItems: ShipmentDetail[]
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}`, shipmentItems);
  }

  updateMultiplePendingShipmentItems(
    shipmentItems: ShipmentDetail[]
  ): Observable<any> {
    return this.httpClient.put<any>(`${this.apiUrl}/pending`, shipmentItems);
  }

  getShipmentWorkflow(
    key: string,
    type: ShipmentDetailType,
    restockType: RestockType = RestockType.Supplier,
    isCasePack = false
  ): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();

    if (key) {
      params = params.set('key', key);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (restockType) {
      params = params.set('restockType', restockType);
    }

    if (isCasePack) {
      params = params.set('isCasePack', 'true');
    }

    return this.httpClient.get(
      `${this.apiUrl}/workflow-export?${params.toString()}`,
      { responseType: 'blob', observe: 'response' }
    );
  }
}
