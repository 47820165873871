export enum AdditionalKeysType {
  createBom = 'createBom',
  updateBom = 'updateBom',
  demand = 'demand',
  supply = 'supply',
  itemSite = 'itemSite'
}

export interface IAdditionalKeys {
  type?: AdditionalKeysType;
  parentKey?: string;
  childKey?: string;
}
