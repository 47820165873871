export enum MetaDataFieldName {
  company = 'company',
  demand = 'demand',
  item = 'item',
  vendor = 'vendor',
  supply = 'supply',
  summary = 'summary',
  consolidation = 'consolidation',
  snapshot = 'snapshot',
  expedite = 'expedite',
  address = 'address',
  summaryByVendor = 'summaryByVendor',
  poDocument = 'poDocument',
  receiptMatrix = 'receiptMatrix',
  bom = 'bom',
  shipmentPlan = 'shipmentPlan',
  supplier = 'supplier',
  shipment = 'shipment',
  shipmentDetail = 'shipmentDetail',
  restockSuggestionByVendor = 'restockSuggestionByVendor',
  restockSuggestion = 'restockSuggestion',
  forecastedHistorySetting = 'forecastedHistorySetting',
  itemSite = 'itemSite',
  purchaseOrder = 'purchaseOrder',
  purchaseOrderItem = 'purchaseOrderItem',
}

export enum VendorField {
  name = 'name',
  links = 'links',
  description = 'description',
  isHidden = 'isHidden',
  email = 'email',
  moq = 'moq',
  leadTime = 'leadTime',
  orderInterval = 'orderInterval',
  serviceLevel = 'serviceLevel',
  targetOrderValue = 'targetOrderValue',
  freeFreightMinimum = 'freeFreightMinimum',
  restockModel = 'restockModel',
  averageHistoryLength = 'averageHistoryLength',
  countryCode = 'countryCode',
  phoneNumber = 'phoneNumber',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  stateOrProvinceCode = 'stateOrProvinceCode',
  postalCode = 'postalCode',
}

export enum SupplyField {
  supplyUuid = 'supplyUuid',
  docType = 'docType',
  orderKey = 'orderKey',
  rowKey = 'rowKey',
  refNum = 'refNum',
  vendorKey = 'vendorKey',
  vendorName = 'vendorName',
  dueDate = 'dueDate',
  docDate = 'docDate',
  imageUrl = 'imageUrl',
  itemKey = 'itemKey',
  itemName = 'itemName',
  asin = 'asin',
  fnsku = 'fnsku',
  orderQty = 'orderQty',
  openQty = 'openQty',
  purchasingSummariesUuid = 'purchasingSummariesUuid',
  documentUuid = 'documentUuid',
  reconciledStatus = 'reconciledStatus',
  isHidden = 'isHidden',
}

export enum DemandField {
  orderKey = 'orderKey',
  refNum = 'refNum',
  itemKey = 'itemKey',
  itemName = 'itemName',
  asin = 'asin',
  dueDate = 'dueDate',
  orderQty = 'orderQty',
  openQty = 'openQty',
  fnsku = 'fnsku',
  isHidden = 'isHidden',
}

export enum ItemField {
  history = 'history',
  links = 'links',
  name = 'name',
  asin = 'asin',
  fnsku = 'fnsku',
  description = 'description',
  vendorName = 'vendorName',
  vendorPrice = 'vendorPrice',
  moq = 'moq',
  leadTime = 'leadTime',
  orderInterval = 'orderInterval',
  serviceLevel = 'serviceLevel',
  onHand = 'onHand',
  onHandMin = 'onHandMin',
  onHandFbm = 'onHandFbm',
  onHandThirdParty = 'onHandThirdParty',
  onHandThirdPartyMin = 'onHandThirdPartyMin',
  awdInventoryQty = 'awdInventoryQty',
  isHidden = 'isHidden',
  useHistoryOverride = 'useHistoryOverride',
  lotMultipleQty = 'lotMultipleQty',
  lotMultipleItemName = 'lotMultipleItemName',
  tags = 'tags',
  useBackfill = 'useBackfill',
  createdAt = 'createdAt',
  isFbm = 'isFbm',
  inventorySourcePreference = 'inventorySourcePreference',
  imageUrl = 'imageUrl',
  packageWeight = 'packageWeight',
  skuNotes = 'skuNotes',
  prepGuide = 'prepGuide',
  prepNotes = 'prepNotes',
  supplierRebate = 'supplierRebate',
  inboundShippingCost = 'inboundShippingCost',
  reshippingCost = 'reshippingCost',
  repackagingMaterialCost = 'repackagingMaterialCost',
  repackingLaborCost = 'repackingLaborCost',
  dimensionalWeight = 'dimensionalWeight',
  hazmat = 'hazmat',
  oversized = 'oversized',
  category = 'category',
  upc = 'upc',
  ean = 'ean',
  inbound = 'inbound',
  reserved = 'reserved',
  inboundUnfulfillable = 'inboundUnfulfillable',
  newBuyBox = 'newBuyBox',
  inboundWorking = 'inboundWorking',
  inboundShipped = 'inboundShipped',
  inboundReceiving = 'inboundReceiving',
  referralFee = 'referralFee',
  listPrice = 'listPrice',
  fba = 'fba',
  lowestFba = 'lowestFba',
  nonFba = 'nonFba',
  lowestNonFba = 'lowestNonFba',
  soldBy = 'soldBy',
  fbaFee = 'fbaFee',
  useLostSalesOverride = 'useLostSalesOverride',
  inboundPrice = 'inboundPrice',
  inboundSalesLast30Days = 'inboundSalesLast30Days',
  inboundAvailable = 'inboundAvailable',
  inboundFcTransfer = 'inboundFcTransfer',
  inboundCustomerOrder = 'inboundCustomerOrder',
  inboundAlert = 'inboundAlert',
  mwsFulfillmentFee = 'mwsFulfillmentFee',
  condition = 'condition',
  rank = 'rank',
  average7DayPrice = 'average7DayPrice',
  tag = 'tag',
  itemHistoryLength = 'itemHistoryLength',
  doNotOrder = 'doNotOrder',
  doNotRestock = 'doNotRestock',
  boxLength = 'boxLength',
  boxWidth = 'boxWidth',
  boxHeight = 'boxHeight',
  boxWeight = 'boxWeight',
  itemVolume = 'itemVolume',
  volumeUnitMeasurement = 'volumeUnitMeasurement',
  storageVolume = 'storageVolume',
  storageType = 'storageType',
  historicalDaysOfSupply = 'historicalDaysOfSupply',
  inboundAwd = 'inboundAwd',
  totalSurcharge = 'totalSurcharge',
}

export enum ItemMetricField {
  s2d = 's2d',
  s7d = 's7d',
  s14d = 's14d',
  s30d = 's30d',
  s60d = 's60d',
  s90d = 's90d',
  s180d = 's180d',
  s365d = 's365d',
}

export enum SummaryByVendorField {
  vendorKey = 'vendorKey',
  vendorName = 'vendorName',
  earliestDueDate = 'earliestDueDate',
  uniqueItems = 'uniqueItems',
  totalQty = 'totalQty',
  totalPrice = 'totalPrice',
  earliestRecommendedOrderDate = 'earliestRecommendedOrderDate',
}

export enum SummaryField {
  removeAll = 'removeAll',
  details = 'details',
  itemName = 'itemName',
  asin = 'asin',
  fnsku = 'fnsku',
  imageUrl = 'imageUrl',
  description = 'description',
  tag = 'tag',
  tags = 'tags',
  vendorName = 'vendorName',
  consolidatedQty = 'consolidatedQty',
  onNewPo = 'onNewPo',
  recommendedQty = 'recommendedQty',
  type = 'type',
  dueDate = 'dueDate',
  moq = 'moq',
  lotMultipleQty = 'lotMultipleQty',
  purchaseQty = 'purchaseQty',
  truePurchaseQty = 'truePurchaseQty',
  vendorPrice = 'vendorPrice',
  total = 'total',
  leadTime = 'leadTime',
  orderInterval = 'orderInterval',
  addedToSupplies = 'addedToSupplies',
  documentUuid = 'documentUuid',
  purchasingSummariesUuid = 'purchasingSummariesUuid',
  s7d = 's7d',
  s2d = 's2d',
  s30d = 's30d',
  s60d = 's60d',
  s90d = 's90d',
  s180d = 's180d',
  s365d = 's365d',
  sMtd = 'sMtd',
  sYtd = 'sYtd',
  snapshotQty = 'snapshotQty',
  meanLtd = 'meanLtd',
  safetyStockLtd = 'safetyStockLtd',
  onHand = 'onHand',
  onHandMin = 'onHandMin',
  onHandThirdParty = 'onHandThirdParty',
  onHandThirdPartyMin = 'onHandThirdPartyMin',
  lastOrderDate = 'lastOrderDate',
  nextScheduledOrderDate = 'nextScheduledOrderDate',
  daysUntilNextScheduledOrder = 'daysUntilNextScheduledOrder',
  outOfStockDate = 'outOfStockDate',
  daysLeft = 'daysLeft',
  recommendedOrderDate = 'recommendedOrderDate',
  daysRemaining = 'daysRemaining',
  openPurchaseOrders = 'openPurchaseOrders',
  openSalesOrders = 'openSalesOrders',
  inbound = 'inbound',
  inboundAwd = 'inboundAwd',
  inboundPrice = 'inboundPrice',
  inboundSalesLast30Days = 'inboundSalesLast30Days',
  inboundAvailable = 'inboundAvailable',
  inboundFcTransfer = 'inboundFcTransfer',
  inboundFcProcessing = 'inboundFcProcessing',
  inboundCustomerOrder = 'inboundCustomerOrder',
  inboundUnfulfillable = 'inboundUnfulfillable',
  inboundAlert = 'inboundAlert',
  inboundWorking = 'inboundWorking',
  mwsFulfillmentFee = 'mwsFulfillmentFee',
  isFbm = 'isFbm',
  inventorySourcePreference = 'inventorySourcePreference',
  onHandFbm = 'onHandFbm',
  trueRecommendedQty = 'trueRecommendedQty',
  links = 'links',
  doNotOrder = 'doNotOrder',
  salesVelocitySettingData = 'salesVelocitySettingData',
  alertAutoAdjust = 'alertAutoAdjust',
}

export enum SupplierField {
  countryCode = 'countryCode',
  fullName = 'fullName',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  stateOrProvinceCode = 'stateOrProvinceCode',
  postalCode = 'postalCode',
  phoneNumber = 'phoneNumber',
}

export enum ShipmentPlanField {
  warehouseId = 'warehouseId',
  labelType = 'labelType',
  shipmentQty = 'shipmentQty',
}

export enum ShipmentField {
  shipmentId = 'shipmentId',
  shipmentName = 'shipmentName',
  shipmentSource = 'shipmentSource',
  destinationFulfillmentCenterId = 'destinationFulfillmentCenterId',
  labelPrepPreference = 'labelPrepPreference',
  status = 'status',
  requestedQty = 'requestedQty',
  receivedQty = 'receivedQty',
  totalCost = 'totalCost',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  nextStep = 'nextStep',
  options = 'options',
  orderNotes = 'orderNotes',
  restockType = 'restockType',
  receiveDate = 'receiveDate',
  refNum = 'refNum',
}

export enum ShipmentDetailField {
  shipmentItemKey = 'shipmentItemKey',
  shipmentId = 'shipmentId',
  destinationFulfillmentCenterId = 'destinationFulfillmentCenterId',
  labelType = 'labelType',
  localQty = 'localQty',
  orderQty = 'orderQty',
  shipmentQty = 'shipmentQty',
  isShipByCase = 'isShipByCase',
  whoPreps = 'whoPreps',
  caseQty = 'caseQty',
  cost = 'cost',
  receivedQty = 'receivedQty',
  hasSticker = 'hasSticker',
  stickerQty = 'stickerQty',
  itemName = 'itemName',
  description = 'description',
  asin = 'asin',
  tags = 'tags',
  vendorName = 'vendorName',
  fnsku = 'fnsku',
  lotMultipleQty = 'lotMultipleQty',
  s7d = 's7d',
  s30d = 's30d',
  s90d = 's90d',
  mwsFulfillmentFee = 'mwsFulfillmentFee',
  inbound = 'inbound',
  onHand = 'onHand',
  inboundWorking = 'inboundWorking',
  amazonRemaining = 'amazonRemaining',
  localRemaining = 'localRemaining',
  inboundUnfulfillable = 'inboundUnfulfillable',
  recommendedQty = 'recommendedQty',
  openPurchaseOrders = 'openPurchaseOrders',
  reserved = 'reserved',
  supplierSku = 'supplierSku',
  notes = 'notes',
  imageUrl = 'imageUrl',
  forecastedQty = 'forecastedQty',
  useForecastedQty = 'useForecastedQty',
  useRecommendedQty = 'useRecommendedQty',
  inboundShippingCost = 'inboundShippingCost',
  reshippingCost = 'reshippingCost',
  repackagingMaterialCost = 'repackagingMaterialCost',
  repackingLaborCost = 'repackingLaborCost',
  fba = 'fba',
  nonFba = 'nonFba',
  lowestFba = 'lowestFba',
  lowestNonFba = 'lowestNonFba',
  packageWeight = 'packageWeight',
  dimensionalWeight = 'dimensionalWeight',
  casePackQuantity = 'casePackQuantity',
  hazmat = 'hazmat',
  oversized = 'oversized',
  average7DayPrice = 'average7DayPrice',
  listPrice = 'listPrice',
  newBuyBox = 'newBuyBox',
  s60d = 's60d',
  s2d = 's2d',
  s14d = 's14d',
  s180d = 's180d',
  estimatedMargin = 'estimatedMargin',
  estimatedMarginPercentage = 'estimatedMarginPercentage',
  estimatedMarkupPercentage = 'estimatedMarkupPercentage',
  inboundFcTransfer = 'inboundFcTransfer',
  inboundAwd = 'inboundAwd',
  sum = 'sum',
  inboundShipped = 'inboundShipped',
  inboundReceiving = 'inboundReceiving',
  inboundTotal = 'inboundTotal',
  category = 'category',
  rank = 'rank',
  referralFee = 'referralFee',
  fbaFee = 'fbaFee',
  difference = 'difference',
  warehouseQty = 'warehouseQty',
  isHidden = 'isHidden',
  boxLength = 'boxLength',
  boxWidth = 'boxWidth',
  boxHeight = 'boxHeight',
  boxWeight = 'boxWeight',
  receiveDate = 'receiveDate',
  casePackSuggShip = 'casePackSuggShip',
  casePackSuggReorder = 'casePackSuggReorder',
  casePackForecastRecommendedQty = 'casePackForecastRecommendedQty',
  useReCasePackQty = 'useReCasePackQty',
  useReForecastQtyByCasePackQty = 'useReForecastQtyByCasePackQty',
  useReSupplierQtyByCasePackQty = 'useReSupplierQtyByCasePackQty',
  useReWarehouseQtyByCasePackQty = 'useReWarehouseQtyByCasePackQty',
  itemCreatedAt = 'itemCreatedAt',
  pending = 'pending',
  onHandFbm = 'onHandFbm',
  shipmentName = 'shipmentName',
}

export enum RestockSuggestionSupplierField {
  flag = 'flag',
  vendorName = 'vendorName',
  orangeAlerts = 'orangeAlerts',
  yellowAlerts = 'yellowAlerts',
  tealAlerts = 'tealAlerts',
  redAlerts = 'redAlerts',
  greenAlerts = 'greenAlerts',
  restockUnits = 'restockUnits',
  restockTotal = 'restockTotal',
  targetOrderValue = 'targetOrderValue',
  freeFreightMinimum = 'freeFreightMinimum',
}

export enum RestockSuggestionItemField {
  flag = 'flag',
  alertDate = 'alertDate',
  status = 'status',
  sku = 'sku',
  productName = 'productName',
  asin = 'asin',
  type = 'type',
  imageUrl = 'imageUrl',
  tags = 'tags',
  prepGuide = 'prepGuide',
  skuNotes = 'skuNotes',
  prepNotes = 'prepNotes',
  supplier = 'supplier',
  supplierSku = 'supplierSku',
  supplierCost = 'supplierCost',
  supplierRebate = 'supplierRebate',
  inboundShippingCost = 'inboundShippingCost',
  reshippingCost = 'reshippingCost',
  repackagingMaterialCost = 'repackagingMaterialCost',
  repackingLaborCost = 'repackingLaborCost',
  restockModel = 'restockModel',
  fnsku = 'fnsku',
  upc = 'upc',
  ean = 'ean',
  fba = 'fba',
  lowestFba = 'lowestFba',
  nonFba = 'nonFba',
  lowestNonFba = 'lowestNonFba',
  packageWeight = 'packageWeight',
  dimensionalWeight = 'dimensionalWeight',
  casePackQuantity = 'casePackQuantity',
  hazmat = 'hazmat',
  oversized = 'oversized',
  s2d = 's2d',
  s7d = 's7d',
  s14d = 's14d',
  s30d = 's30d',
  s60d = 's60d',
  s90d = 's90d',
  s180d = 's180d',
  average7DayPrice = 'average7DayPrice',
  listPrice = 'listPrice',
  soldBy = 'soldBy',
  newBuyBox = 'newBuyBox',
  estimatedMargin = 'estimatedMargin',
  estimatedMarginPercentage = 'estimatedMarginPercentage',
  estimatedMarkupPercentage = 'estimatedMarkupPercentage',
  qoh = 'qoh',
  inbound = 'inbound',
  sum = 'sum',
  inboundWorking = 'inboundWorking',
  inboundShipped = 'inboundShipped',
  inboundReceiving = 'inboundReceiving',
  inboundAwd = 'inboundAwd',
  inboundTotal = 'inboundTotal',
  targetDays = 'targetDays',
  remaining = 'remaining',
  demand = 'demand',
  outOfStockPercentage = 'outOfStockPercentage',
  reserved = 'reserved',
  unfulfillable = 'unfulfillable',
  pending = 'pending',
  localQty = 'localQty',
  awdInventoryQty = 'awdInventoryQty',
  maximumShipmentQty = 'maximumShipmentQty',
  suggShip = 'suggShip',
  suggReorder = 'suggReorder',
  onOrder = 'onOrder',
  restockNeeded = 'restockNeeded',
  category = 'category',
  rank = 'rank',
  referralFee = 'referralFee',
  fbaFee = 'fbaFee',
  orderQty = 'orderQty',
  inboundFcTransfer = 'inboundFcTransfer',
  forecastRecommendedQty = 'forecastRecommendedQty',
  recommendedWarehouseQty = 'recommendedWarehouseQty',
  recommendedSupplierQty = 'recommendedSupplierQty',
  casePackSuggShip = 'casePackSuggShip',
  casePackSuggReorder = 'casePackSuggReorder',
  casePackForecastRecommendedQty = 'casePackForecastRecommendedQty',
  expeditedRecommendationQty = 'expeditedRecommendationQty',
  recommendationByCasePackQty = 'recommendationByCasePackQty',
  useReForecastQty = 'useReForecastQty',
  useReWarehouseQty = 'useReWarehouseQty',
  useReSupplierQty = 'useReSupplierQty',
  useReCasePackQty = 'useReCasePackQty',
  useReForecastQtyByCasePackQty = 'useReForecastQtyByCasePackQty',
  useReSupplierQtyByCasePackQty = 'useReSupplierQtyByCasePackQty',
  useReWarehouseQtyByCasePackQty = 'useReWarehouseQtyByCasePackQty',
  useExpeditedRecommendationQty = 'useExpeditedRecommendationQty',
  warehouseQtyUpdatedDate = 'warehouseQtyUpdatedDate',
  doNotRestock = 'doNotRestock',
  alertAutoAdjust = 'alertAutoAdjust',
  boxLength = 'boxLength',
  boxWidth = 'boxWidth',
  boxHeight = 'boxHeight',
  boxWeight = 'boxWeight',
  createdAt = 'createdAt',
  itemVolume = 'itemVolume',
  volumeUnitMeasurement = 'volumeUnitMeasurement',
  storageVolume = 'storageVolume',
  storageType = 'storageType',
  historicalDaysOfSupply = 'historicalDaysOfSupply',
  onHandFbm = 'onHandFbm',
  estimatedExcessUnits = 'estimatedExcessUnits',
  totalSurcharge = 'totalSurcharge',
  inventoryAges = 'inventoryAges',
  estimatedAgedInventorySurcharges = 'estimatedAgedInventorySurcharges',
  previousValue = 'previousValue'
}

export enum ForecastedHistorySettingField {
  groupName = 'groupName',
  itemName = 'itemName',
  asin = 'asin',
  fnsku = 'fnsku',
  customPercentage = 'customPercentage',
  month1 = 'month1',
  month2 = 'month2',
  month3 = 'month3',
  month4 = 'month4',
  month5 = 'month5',
  month6 = 'month6',
  month7 = 'month7',
  month8 = 'month8',
  month9 = 'month9',
  month10 = 'month10',
  month11 = 'month11',
  month12 = 'month12',
}

export enum ItemSiteField {
  key = 'key',
  warehouseName = 'warehouseName',
  itemKey = 'itemKey',
  itemName = 'itemName',
  onHand = 'onHand',
}

export enum PurchaseOrderField {
  key = 'key',
  refNum = 'refNum',
  vendorName = 'vendorName',
  vendorKey = 'vendorKey',
  dueDate = 'dueDate',
  docDate = 'docDate',
  taxesFee = 'taxesFee',
  shippingFee = 'shippingFee',
  otherFee = 'otherFee',
  shipVia = 'shipVia',
  fob = 'fob',
  paymentTerms = 'paymentTerms',
  status = 'status',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  shipTo = 'shipTo',
  orderQty = 'orderQty',
  openQty = 'openQty',
  totalCost ='totalCost',
  options = 'options',
  shipmentId = 'shipmentId',
}

export enum PurchaseOrderItemField {
  key = 'key',
  purchaseOrderKey = 'purchaseOrderKey',
  itemName = 'itemName',
  asin = 'asin',
  description = 'description',
  vendorPrice = 'vendorPrice',
  purchaseQty = 'purchaseQty',
  truePurchaseQty = 'truePurchaseQty',
  restockPurchaseQty = 'restockPurchaseQty',
  restockTruePurchaseQty = 'restockTruePurchaseQty',
  tags = 'tags',
  fnsku = 'fnsku',
  lotMultipleQty = 'lotMultipleQty',
  moq = 'moq',
  orderQty = 'orderQty',
  useForecastedQty = 'useForecastedQty',
  useRecommendedQty = 'useRecommendedQty',
  useReCasePackQty = 'useReCasePackQty',
  autofillOrderQty = 'autofillOrderQty',
  useReForecastQtyByCasePackQty = 'useReForecastQtyByCasePackQty',
  useReSupplierQtyByCasePackQty = 'useReSupplierQtyByCasePackQty',
  dueDate = 'dueDate',
  docDate = 'docDate',
  refNum = 'refNum',
  openQty = 'openQty',
  notes = 'notes',
  hasSticker = 'hasSticker'
}
