import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { format } from 'date-fns';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent, MODAL_DATA } from '@stockaid/core';
import { SnotifyService } from 'ng-snotify';
import { RESTOCK_MODELS } from 'src/app/core/constants/restock-model.constant';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.service';
import { VendorSalesVelocitySettingsService } from 'src/app/core/services/vendor-sales-velocity-settings.service';
import {
  AVERAGE_VELOCITY_WEIGHTINGS,
  DATE_RANGE_SALES_VELOCITY,
  SALES_VELOCITY_SETTINGS,
} from 'src/app/core/constants/sales-velocity.constant';
import {
  Vendor,
  VendorSalesVelocitySettings,
} from 'src/app/core/models/vendor';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { ItemSalesVelocitySettings } from 'src/app/core/models/item';
import { ItemSalesVelocitySettingsService } from 'src/app/core/services/item-sales-velocity-settings.service';
import { ItemService } from 'src/app/core/services/item.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';
import { DefaultWeightPercentage } from './restock-settings.constant';
import { getAutoAdjustedWeightings } from '@stockaid/shared-utils';
import { COMMON_FORMAT_DATE, FORMAT_PATTERN } from 'src/app/core/constants/format-date.constant';
import { isEqual } from 'lodash';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { PurchasingSummary } from 'src/app/core/models/purchasing-summary';

@Component({
  selector: 'app-sales-velocity-modal',
  templateUrl: './restock-settings.component.html',
  styleUrls: ['./restock-settings.component.scss'],
})
export class SalesVelocityModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() isModal: boolean = true;
  @Input() salesVelocitySettingsType: SalesVelocitySettingsType =
    SalesVelocitySettingsType.restockAMZ;
  @Input() isNewUI: boolean = false;

  @Output() valueChanges = new EventEmitter();

  salesVelocityForm: FormGroup;
  totalWeightings: number;
  salesVelocityType: string;
  previousSalesVelocityType: string;
  errorsDateRange;
  invalidDateRange: boolean;
  currentCompany: Company;
  isCanSave: boolean = false;

  settingFormGroup: FormGroup;
  supplierSettingFormGroup: FormGroup;
  savingSetting: boolean = false;
  settingLocalLeadTime: string;
  settingRestockModel: number;
  settingTargetQtyOnHandMin: number;
  settingTargetQtyOnHandMax: number;
  companyKey: string;
  isSupplier: boolean = false;
  itemName: string;
  dataItem: any;
  ascInitialDateRange: any = {};
  dataItemSettings: any = {};
  disabledDate = [new Date()];
  disableSettings = {
    isNotAverageDailySaleRate: false,
    isNotRestockModel: false,
    isNotWarehouseLeadTime: false,
    isNotFbaInventory: false,
    isNotAdditionalInventory: false,
    averageDailySaleRateFrom: 'GLOBAL',
    restockModelFrom: 'GLOBAL',
    warehouseLeadTimeFrom: 'GLOBAL',
    fbaInventoryFrom: 'GLOBAL',
    additionalInventoryFrom: 'GLOBAL',
  };
  globalSettings: any = {};
  supplierSetting: any = {};
  autoAdjustSetting: any = {};
  currentSupplier: Vendor;
  purchasingSummary: PurchasingSummary;

  readonly FORMAT_DATE = COMMON_FORMAT_DATE;
  readonly FORMAT_PATTERN = FORMAT_PATTERN;
  readonly SALES_VELOCITY_SETTINGS = SALES_VELOCITY_SETTINGS;
  readonly DATE_RANGE_SALES_VELOCITY = DATE_RANGE_SALES_VELOCITY;
  readonly RESTOCK_MODELS = RESTOCK_MODELS;
  readonly AVERAGE_VELOCITY_WEIGHTINGS = AVERAGE_VELOCITY_WEIGHTINGS;
  readonly VELOCITY_WEIGHTINGS = AVERAGE_VELOCITY_WEIGHTINGS.map((o) => {
    const [key] = Object.keys(o);
    return key;
  });

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private snotifyService: SnotifyService,
    private vendorSettingsService: VendorSalesVelocitySettingsService,
    private itemSettingsService: ItemSalesVelocitySettingsService,
    private itemService: ItemService,
    private vendorService: VendorService,
    @Inject(MODAL_DATA) private data: any
  ) {
    super();
  }

  public get restockModel(): AbstractControl {
    return this.settingFormGroup?.controls?.restockModel;
  }

  public get localLeadTime(): AbstractControl {
    return this.settingFormGroup?.controls?.localLeadTime;
  }

  public get targetQtyOnHandMin(): AbstractControl {
    return this.settingFormGroup?.controls?.targetQtyOnHandMin;
  }

  public get targetQtyOnHandMax(): AbstractControl {
    return this.settingFormGroup?.controls?.targetQtyOnHandMax;
  }

  public get additionalInventory(): AbstractControl {
    return this.finalFormGroup?.controls?.additionalInventory;
  }

  public get modalTitle(): string {
    const temp = this.salesVelocitySettingsType;
    return (
      temp.replace(temp[0], temp[0].toUpperCase()) +
      ' Daily Sales Rate Settings'
    );
  }

  get isPurchasing(): boolean {
    return (
      this.salesVelocitySettingsType === SalesVelocitySettingsType.purchasing
    );
  }

  get isMinPercentageError(): boolean {
    const isErrorArray = this.VELOCITY_WEIGHTINGS.map((key) =>
      this.salesVelocityForm?.get(key)?.hasError('min')
    );
    return isErrorArray.some((isError) => isError);
  }

  get isMaxPercentageError(): boolean {
    const isErrorArray = this.VELOCITY_WEIGHTINGS.map((key) =>
      this.salesVelocityForm?.get(key)?.hasError('max')
    );
    return isErrorArray.some((isError) => isError);
  }

  get saleVelocityTypeSubtext(): string {
    switch (this.salesVelocityType) {
      case SALES_VELOCITY_SETTINGS.average:
        return 'Daily Sales Rate Weightings';

      case SALES_VELOCITY_SETTINGS.dateRange:
        return 'Past date range to be used to calculate';

      case SALES_VELOCITY_SETTINGS.auto:
        return 'Auto-apply weight to calculate';

      default:
        return '';
    }
  }

  get isCompanyTypeASC(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  get finalFormGroup(): FormGroup {
    return this.isSupplier
      ? this.supplierSettingFormGroup
      : this.settingFormGroup;
  }

  ngOnInit() {
    this.dataItem = this.data?.dataItem;
    this.itemName = this.dataItem?.name || this.dataItem?.fullName;

    if (this.data?.salesVelocitySettingsType) {
      this.salesVelocitySettingsType = this.data.salesVelocitySettingsType;
    }

    this.getPurchasingSummary()
      .pipe(
        this.autoCleanUp(),
        tap(() => this.initDataSetting())
      )
      .subscribe();
  }

  getCurrentCompany(): Observable<Company> {
    return this.companyService.getCurrentCompany().pipe(
      tap((company) => {
        this.currentCompany = company;
      })
    );
  }

  onChangeData() {
    let valid = false;

    // If Sales Velocity Setting is Date Range, check if the Start Date is earlier than the End Date
    const isDateRangeSettingValid =
      this.salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange &&
      !this.invalidDateRange &&
      Object.keys(this.checkDateValid())?.length === 0;
    if (this.data?.gridName === GridName.Item) {
      valid =
        this.salesVelocityForm?.valid &&
        // If Sales Velocity Setting is Average or Auto, check if the total weightings is 100
        (([
          SALES_VELOCITY_SETTINGS.average,
          SALES_VELOCITY_SETTINGS.auto,
        ].includes(this.salesVelocityType as SALES_VELOCITY_SETTINGS) &&
          this.totalWeightings === 100) ||
          isDateRangeSettingValid);
    } else {
      valid =
        (this.salesVelocityForm?.valid &&
          // If Sales Velocity Setting is Average, check if the total weightings is 100
          ((this.salesVelocityType === SALES_VELOCITY_SETTINGS.average &&
            this.totalWeightings === 100) ||
            isDateRangeSettingValid)) ||
        this.salesVelocityType === SALES_VELOCITY_SETTINGS.auto;
    }

    this.isCanSave = valid;
    if (!this.isModal) {
      this.valueChanges.emit({
        valid,
        data: this.salesVelocityForm?.value || {},
        type: this.salesVelocityType,
      });
    }
    return valid;
  }

  getVendorDataSetting(): Observable<any> {
    return combineLatest([
      this.vendorService.getById(
        this.dataItem?.vendorKey || this.dataItem?.key
      ),
      this.vendorSettingsService.getByIdWithType(
        this.dataItem?.vendorKey || this.dataItem?.key,
        this.salesVelocitySettingsType
      ),
      this.getCurrentCompany(),
    ]).pipe(
      this.autoCleanUp(),
      map(([vendor, vendorItem, company]) => {
        this.currentSupplier = vendor;
        if (company) {
          const {
            localLeadTime,
            restockModel,
            targetQtyOnHandMax,
            targetQtyOnHandMin,
            additionalInventory,
          } = company;
          let salesVelocityType;
          let salesVelocitySettingData;
          switch (this.salesVelocitySettingsType) {
            case SalesVelocitySettingsType.restockAMZ:
              salesVelocityType = company.salesVelocityType;
              salesVelocitySettingData = company.salesVelocitySettingData;
              break;

            case SalesVelocitySettingsType.purchasing:
              salesVelocityType = company.purchasingSalesVelocityType;
              salesVelocitySettingData =
                company.purchasingSalesVelocitySettingData;
              break;
          }

          this.salesVelocityType = salesVelocityType;
          this.dataItemSettings.localLeadTime = localLeadTime;
          this.dataItemSettings.restockModel = restockModel;
          this.dataItemSettings.targetQtyOnHandMax = targetQtyOnHandMax;
          this.dataItemSettings.targetQtyOnHandMin = targetQtyOnHandMin;
          this.dataItemSettings.salesVelocitySettingData =
            salesVelocitySettingData;
          this.dataItemSettings.salesVelocityType = salesVelocityType;
          this.dataItemSettings.additionalInventory = additionalInventory;
          this.globalSettings = { ...this.dataItemSettings };
        }

        if (
          vendorItem?.salesVelocityType &&
          vendorItem.salesVelocitySettingData
        ) {
          const { salesVelocityType, salesVelocitySettingData } = vendorItem;
          this.dataItemSettings = {
            ...this.dataItemSettings,
            salesVelocityType,
            salesVelocitySettingData,
          };
          this.salesVelocityType = salesVelocityType;
        }
        if (
          vendor &&
          (vendor.additionalInventory || vendor.additionalInventory === 0)
        ) {
          this.dataItemSettings.additionalInventory =
            vendor.additionalInventory;
        }

        this.initSupplierSettingForm();
        this.createVelocityForm(this.salesVelocityType);
        this.onChangeData();
        this.setDisableSetting(vendorItem, 'salesVelocitySettingData', null);
        this.setDisableSetting(vendor, 'additionalInventory', null);
      })
    );
  }

  getItemDataSetting(): Observable<any> {
    return combineLatest([
      this.vendorService.getById(this.dataItem.vendorKey),
      this.itemSettingsService.getByIdWithType(
        this.dataItem.key,
        this.salesVelocitySettingsType
      ),
      this.vendorSettingsService.getByIdWithType(
        this.dataItem.vendorKey,
        this.salesVelocitySettingsType
      ),
      this.getCurrentCompany(),
    ]).pipe(
      this.autoCleanUp(),
      map(([vendor, item, vendorItem, company]) => {
        this.currentSupplier = vendor;
        this.itemName =
          this.dataItem?.name || `Item Without Name ( ${this.dataItem?.asin} )`;

        // Item > Supplier > Company
        if (company) {
          const {
            localLeadTime,
            restockModel,
            targetQtyOnHandMax,
            targetQtyOnHandMin,
            additionalInventory,
          } = company;
          let salesVelocityType;
          let salesVelocitySettingData;
          switch (this.salesVelocitySettingsType) {
            case SalesVelocitySettingsType.restockAMZ:
              salesVelocityType = company.salesVelocityType;
              salesVelocitySettingData = company.salesVelocitySettingData;
              break;

            case SalesVelocitySettingsType.purchasing:
              salesVelocityType = company.purchasingSalesVelocityType;
              salesVelocitySettingData =
                company.purchasingSalesVelocitySettingData;
              break;
          }

          this.salesVelocityType = salesVelocityType;
          this.dataItemSettings.localLeadTime = localLeadTime;
          this.dataItemSettings.restockModel = restockModel;
          this.dataItemSettings.targetQtyOnHandMax = targetQtyOnHandMax;
          this.dataItemSettings.targetQtyOnHandMin = targetQtyOnHandMin;
          this.dataItemSettings.salesVelocitySettingData =
            salesVelocitySettingData;
          this.dataItemSettings.salesVelocityType = salesVelocityType;
          this.dataItemSettings.additionalInventory = additionalInventory;
          this.globalSettings = { ...this.dataItemSettings };
        }

        // Detect where setting from is?
        // Case 1: Vendor and item settings is null;
        if (
          !vendorItem &&
          !item &&
          !vendor &&
          !this.dataItem?.additionalInventory &&
          this.dataItem?.additionalInventory !== 0
        ) {
          this.disableSettings = {
            ...this.disableSettings,
            isNotAverageDailySaleRate: true,
            isNotFbaInventory: true,
            isNotRestockModel: true,
            isNotWarehouseLeadTime: true,
            isNotAdditionalInventory: true,
          };
          this.initSetting(this.salesVelocityType);
          this.onChangeData();
          this.applyDisableSetting();
          return;
        }

        if (this.isSupplier && vendorItem) {
          [
            'salesVelocitySettingData',
            'restockModel',
            'localLeadTime',
            'targetQtyOnHandMax',
            'targetQtyOnHandMin',
          ].forEach((key) => {
            if (vendorItem?.[key]) {
              this.dataItemSettings[key] = vendorItem[key];
            }

            this.setDisableSetting(vendorItem, key, null);
          });

          const { salesVelocityType, salesVelocitySettingData } = vendorItem;
          this.dataItemSettings = {
            ...this.dataItemSettings,
            salesVelocityType,
            salesVelocitySettingData,
          };
          this.salesVelocityType = salesVelocityType;
          this.initSetting(this.salesVelocityType);
          this.onChangeData();
          this.applyDisableSetting();
          return;
        }

        this.getItemDefaultSetting(item, vendorItem, vendor);
      })
    );
  }

  getItemDefaultSetting(item: any, vendorItem: any, vendor: any): void {
    if (this.isSupplier) {
      return;
    }

    this.supplierSetting = vendorItem;
    [
      'salesVelocitySettingData',
      'restockModel',
      'localLeadTime',
      'targetQtyOnHandMax',
      'targetQtyOnHandMin',
    ].forEach((key) => {
      if (vendorItem?.[key]) {
        this.dataItemSettings[key] = vendorItem[key];
      }

      if (item?.[key]) {
        this.dataItemSettings[key] = item[key];
      }

      this.setDisableSetting(item, key, vendorItem);
    });

    if (vendor?.additionalInventory || vendor?.additionalInventory === 0) {
      this.dataItemSettings.additionalInventory = vendor.additionalInventory;
    }

    if (
      this.dataItem?.additionalInventory ||
      this.dataItem?.additionalInventory === 0
    ) {
      this.dataItemSettings.additionalInventory =
        this.dataItem.additionalInventory;
    }

    if (vendorItem?.salesVelocityType) {
      this.salesVelocityType = vendorItem.salesVelocityType;
    }

    if (item?.salesVelocityType) {
      this.salesVelocityType = item.salesVelocityType;
    }

    this.setDisableSetting(this.dataItem, 'additionalInventory', null, vendor);

    this.initSetting(this.salesVelocityType);
    this.onChangeData();
    this.applyDisableSetting();
  }

  applyDisableSetting(): void {
    if (this.disableSettings.isNotAverageDailySaleRate) {
      this.salesVelocityForm.disable();
    }
    if (this.disableSettings.isNotRestockModel) {
      this.settingFormGroup.controls['restockModel'].disable();
    }
    if (this.disableSettings.isNotFbaInventory) {
      this.settingFormGroup.controls['targetQtyOnHandMax'].disable();
      this.settingFormGroup.controls['targetQtyOnHandMin'].disable();
    }
    if (this.disableSettings.isNotWarehouseLeadTime) {
      this.settingFormGroup.controls['localLeadTime'].disable();
    }
    if (this.disableSettings.isNotAdditionalInventory) {
      this.finalFormGroup.controls['additionalInventory'].disable();
    }
  }

  setDisableSetting(
    item: any,
    key: string,
    vendorItem: any,
    vendor?: any
  ): void {
    if (vendorItem) {
      this.disableSettings = {
        ...this.disableSettings,
        averageDailySaleRateFrom: 'SUPPLIER',
        restockModelFrom: 'SUPPLIER',
        warehouseLeadTimeFrom: 'SUPPLIER',
        fbaInventoryFrom: 'SUPPLIER',
      };
    }

    if (vendor?.additionalInventory || vendor?.additionalInventory === 0) {
      this.disableSettings = {
        ...this.disableSettings,
        additionalInventoryFrom: 'SUPPLIER',
      };
    }

    if (item?.[key] || item?.[key] === 0) {
      return;
    }

    let fromField;
    switch (key) {
      case 'salesVelocitySettingData':
        fromField = 'averageDailySaleRateFrom';
        break;
      case 'restockModel':
        this.disableSettings.isNotRestockModel = true;
        fromField = 'restockModelFrom';
        break;
      case 'localLeadTime':
        this.disableSettings.isNotWarehouseLeadTime = true;
        fromField = 'warehouseLeadTimeFrom';
        break;
      case 'targetQtyOnHandMax':
      case 'targetQtyOnHandMin':
        this.disableSettings.isNotFbaInventory = true;
        fromField = 'fbaInventoryFrom';
        break;
      case 'additionalInventory':
        this.disableSettings.isNotAdditionalInventory = true;
        if (this.finalFormGroup) {
          this.finalFormGroup.controls.additionalInventory.disable();
        }

        if (vendor?.[key] && item && !item[key]) {
          this.disableSettings.additionalInventoryFrom = 'SUPPLIER';
        }
    }

    if (fromField && vendorItem?.[key] && item && !item[key]) {
      this.disableSettings[fromField] = 'SUPPLIER';
    }
  }

  changeSetting(section: string): void {
    switch (section) {
      case 'AverageSaleRate':
        this.salesVelocityForm?.enable();
        this.disableSettings.isNotAverageDailySaleRate = false;
        break;
      case 'RestockModel':
        this.settingFormGroup?.controls['restockModel'].enable();
        this.disableSettings.isNotRestockModel = false;
        break;
      case 'WarehouseLeadTime':
        this.settingFormGroup?.controls['localLeadTime'].enable();
        this.disableSettings.isNotWarehouseLeadTime = false;
        break;
      case 'IdealFbaInventory':
        this.settingFormGroup?.controls['targetQtyOnHandMin'].enable();
        this.settingFormGroup?.controls['targetQtyOnHandMax'].enable();
        this.disableSettings.isNotFbaInventory = false;
        break;
      case 'AdditionalInventory':
        this.finalFormGroup?.controls['additionalInventory'].enable();
        this.disableSettings.isNotAdditionalInventory = false;
        break;
    }
  }

  resetSetting(section: string): void {
    switch (section) {
      case 'AverageSaleRate':
        // if()
        this.salesVelocityForm?.disable();
        // Check global or supplier setting;
        if (this.disableSettings.averageDailySaleRateFrom === 'GLOBAL') {
          this.salesVelocityType = this.globalSettings.salesVelocityType;
          this.dataItemSettings.salesVelocitySettingData =
            this.globalSettings.salesVelocitySettingData;
        } else {
          this.salesVelocityType = this.supplierSetting.salesVelocityType;
          this.dataItemSettings.salesVelocitySettingData =
            this.supplierSetting.salesVelocitySettingData;
        }
        this.disableSettings.isNotAverageDailySaleRate = true;

        break;
      case 'RestockModel':
        this.settingFormGroup.controls['restockModel'].disable();
        this.disableSettings.isNotRestockModel = true;
        if (this.disableSettings.restockModelFrom === 'GLOBAL') {
          this.dataItemSettings.restockModel = this.globalSettings.restockModel;
          break;
        }

        this.dataItemSettings.restockModel = this.supplierSetting.restockModel;
        break;
      case 'WarehouseLeadTime':
        this.settingFormGroup.controls['localLeadTime'].disable();
        this.disableSettings.isNotWarehouseLeadTime = true;
        if (this.disableSettings.warehouseLeadTimeFrom === 'GLOBAL') {
          this.dataItemSettings.localLeadTime =
            this.globalSettings.localLeadTime;
          break;
        }
        this.dataItemSettings.localLeadTime =
          this.supplierSetting.localLeadTime;

        break;
      case 'IdealFbaInventory':
        this.settingFormGroup.controls['targetQtyOnHandMin'].disable();
        this.settingFormGroup.controls['targetQtyOnHandMax'].disable();
        this.disableSettings.isNotFbaInventory = true;
        if (this.disableSettings.fbaInventoryFrom === 'GLOBAL') {
          this.dataItemSettings.targetQtyOnHandMax =
            this.globalSettings.targetQtyOnHandMax;
          this.dataItemSettings.targetQtyOnHandMin =
            this.globalSettings.targetQtyOnHandMin;
        } else {
          this.dataItemSettings.targetQtyOnHandMax =
            this.supplierSetting.targetQtyOnHandMax;
          this.dataItemSettings.targetQtyOnHandMin =
            this.supplierSetting.targetQtyOnHandMin;
        }
        break;
      case 'AdditionalInventory':
        this.finalFormGroup.controls['additionalInventory'].disable();
        this.disableSettings.isNotAdditionalInventory = true;
        if (this.disableSettings.additionalInventoryFrom === 'GLOBAL') {
          this.dataItemSettings.additionalInventory =
            this.globalSettings.additionalInventory;
        } else {
          this.dataItemSettings.additionalInventory =
            this.currentSupplier.additionalInventory;
        }
        this.initSupplierSettingForm();
        break;
    }

    this.initSetting(this.salesVelocityType);
  }

  getCompanyDetailSetting(): Observable<any> {
    return this.getCurrentCompany().pipe(
      this.autoCleanUp(),
      tap((company) => {
        this.salesVelocityType =
          this.salesVelocitySettingsType ===
          SalesVelocitySettingsType.purchasing
            ? company?.purchasingSalesVelocityType
            : company?.salesVelocityType;

        this.createVelocityForm(this.salesVelocityType);
      })
    );
  }

  initDataSetting() {
    const min = new Date();
    const max = new Date();
    min.setFullYear(new Date().getFullYear() - 1);
    this.ascInitialDateRange = {
      min,
      max,
    };

    let getDataSettingObservable: Observable<any>;
    switch (this.data?.gridName) {
      case GridName.Supplier:
      case GridName.ManageSupplier:
        this.isSupplier = true;
        getDataSettingObservable = this.getVendorDataSetting();
        break;
      case GridName.Item:
        getDataSettingObservable = this.getItemDataSetting();
        break;
      default:
        getDataSettingObservable = this.getCompanyDetailSetting();
    }

    getDataSettingObservable.pipe(this.autoCleanUp()).subscribe();
  }

  handleSalesVelocityChange(): Observable<any> {
    return this.salesVelocityForm?.valueChanges.pipe(
      tap((data) => {
        // Automatically change to 'Average' setting if user edit any weighting fields
        if (
          !isEqual(data, this.autoAdjustSetting) &&
          this.salesVelocityType === SALES_VELOCITY_SETTINGS.auto
        ) {
          this.salesVelocityType = SALES_VELOCITY_SETTINGS.average;
        }

        // Recalculate the total weightings user edit any weighting fields
        if (
          [
            SALES_VELOCITY_SETTINGS.average,
            SALES_VELOCITY_SETTINGS.auto,
          ].includes(this.salesVelocityType as SALES_VELOCITY_SETTINGS)
        ) {
          this.totalWeightings = Object.keys(data).reduce(
            (acc, curr) => acc + data[curr],
            0
          );
        }
      })
    );
  }

  initSetting(value: string): void {
    this.createVelocityForm(value);
    this.settingFormGroup = this.fb.group({
      restockModel: [
        this.dataItemSettings?.restockModel || RESTOCK_MODELS[0].val,
        Validators.required,
      ],
      localLeadTime: [
        this.dataItemSettings?.localLeadTime ?? 7,
        [Validators.required, Validators.min(7), Validators.max(45)],
      ],
      targetQtyOnHandMin: [
        this.dataItemSettings?.targetQtyOnHandMin ?? 30,
        [Validators.required, Validators.min(0), Validators.max(365)],
      ],
      targetQtyOnHandMax: [
        this.dataItemSettings?.targetQtyOnHandMax ?? 60,
        [Validators.required, Validators.min(0), Validators.max(365)],
      ],
      additionalInventory: [
        this.dataItemSettings?.additionalInventory ?? 0,
        [Validators.required, Validators.min(0), Validators.max(365)],
      ],
    });
  }

  saveSettings(): void {
    // Convert dateTime to date
    if (this.salesVelocityForm?.value['startDate']) {
      this.salesVelocityForm.value['startDate'] = format(
        this.salesVelocityForm.value['startDate'],
        'yyyy-MM-dd'
      );
    }

    if (this.salesVelocityForm?.value['endDate']) {
      this.salesVelocityForm.value['endDate'] = format(
        this.salesVelocityForm.value['endDate'],
        'yyyy-MM-dd'
      );
    }

    switch (this.data?.gridName) {
      case GridName.ManageSupplier:
      case GridName.Supplier: {
        let data: VendorSalesVelocitySettings =
          new VendorSalesVelocitySettings();
        data.companyKey = this.dataItem?.companyKey;
        data.companyType = this.dataItem?.companyType;
        data.salesVelocityType = this.salesVelocityType;
        data.vendorKey = this.dataItem?.vendorKey || this.dataItem?.key;
        data.salesVelocitySettingData = this.salesVelocityForm?.value;
        data.salesVelocitySettingsType = this.salesVelocitySettingsType;

        this.currentSupplier.additionalInventory =
          this.additionalInventory?.value;

        if (this.disableSettings.isNotAdditionalInventory) {
          this.currentSupplier.additionalInventory = null;
        }

        if (this.disableSettings.isNotAverageDailySaleRate) {
          data.salesVelocitySettingData = null;
          data.salesVelocityType = null;
        }
        this.vendorSettingsService
          .save(data, 'vendorKey')
          .pipe(
            this.autoCleanUp(),
            switchMap(() =>
              this.vendorService.save(this.currentSupplier, 'key')
            ),
            switchMap(() =>
              this.companyService.updateCompanyInfo({
                ...this.currentCompany,
                willRemindToRunForecast: true,
              })
            )
          )
          .subscribe((c) => {
            this.snotifyService.success(`Save Settings successfully.`);
            this.activeModal.close('Close click');
            this.companyService.updateCacheCompany({
              ...c,
              isAuthorized: this.currentCompany.isAuthorized,
            });
          });
        break;
      }

      case GridName.Item: {
        let data: ItemSalesVelocitySettings = new ItemSalesVelocitySettings();
        data.companyKey = this.dataItem?.companyKey;
        data.companyType = this.dataItem?.companyType;
        data.salesVelocityType = this.salesVelocityType;
        data.itemKey = this.dataItem?.key;
        data.salesVelocitySettingData = this.salesVelocityForm?.value;
        data.restockModel = this.restockModel?.value;
        data.localLeadTime = this.localLeadTime?.value;
        data.targetQtyOnHandMin = this.targetQtyOnHandMin?.value;
        data.targetQtyOnHandMax = this.targetQtyOnHandMax?.value;
        data.salesVelocitySettingsType = this.salesVelocitySettingsType;

        this.dataItem.additionalInventory = this.additionalInventory?.value;

        this.setNullWhenSaving(data);

        this.itemSettingsService
          .save(data, 'itemKey')
          .pipe(
            this.autoCleanUp(),
            switchMap(() => this.itemService.save(this.dataItem, 'key'))
          )
          .subscribe(() => {
            this.snotifyService.success(`Save Settings successfully.`);
            this.activeModal.close('Close click');
          });
        break;
      }
    }
  }

  createVelocityForm(value: string): void {
    const isSwitchedToAverage =
      [
        SALES_VELOCITY_SETTINGS.dateRange,
        SALES_VELOCITY_SETTINGS.auto,
      ].includes(this.previousSalesVelocityType as SALES_VELOCITY_SETTINGS) &&
      value === SALES_VELOCITY_SETTINGS.average;
    this.previousSalesVelocityType = value;
    this.salesVelocityType = value;
    let dataSettings;
    switch (true) {
      case this.data?.gridName === GridName.ManageSupplier:
      case this.data?.gridName === GridName.Supplier:
      case this.data?.gridName === GridName.Item:
        dataSettings = this.dataItemSettings;
        break;

      case this.salesVelocitySettingsType ===
        SalesVelocitySettingsType.restockAMZ:
        dataSettings = this.currentCompany;
        break;

      case this.salesVelocitySettingsType ===
        SalesVelocitySettingsType.purchasing:
        dataSettings = {
          salesVelocityType: this.currentCompany?.purchasingSalesVelocityType,
          salesVelocitySettingData:
            this.currentCompany?.purchasingSalesVelocitySettingData,
        };
        break;
    }

    this.initSVSettingForm(value, isSwitchedToAverage, dataSettings);
    this.onChangeData();
  }

  initSVSettingForm(
    value: string,
    isSwitchedToAverage: boolean,
    dataSettings: any
  ): void {
    switch (value) {
      case SALES_VELOCITY_SETTINGS.average:
        this.handleAverageSetting(isSwitchedToAverage, dataSettings);
        break;

      case SALES_VELOCITY_SETTINGS.auto:
        if (this.data?.gridName !== GridName.Item) {
          this.salesVelocityForm = null;
          this.onChangeData();
          return;
        }
        this.errorsDateRange = {};
        this.invalidDateRange = false;

        let itemHistoryLength = this.isPurchasing
          ? this.purchasingSummary?.itemHistoryLength
          : this.purchasingSummary?.restockItemHistoryLength;
        if (!this.purchasingSummary) {
          itemHistoryLength = this.dataItem?.itemHistoryLength;
        }

        const percentageData = getAutoAdjustedWeightings(
          (itemHistoryLength || 0) * 30
        );
        this.autoAdjustSetting = percentageData;
        this.salesVelocityForm = this.fb.group({
          percent2Day: [
            percentageData.percent2Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent7Day: [
            percentageData.percent7Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent14Day: [
            percentageData.percent14Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent30Day: [
            percentageData.percent30Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent60Day: [
            percentageData.percent60Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent90Day: [
            percentageData.percent90Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percent180Day: [
            percentageData.percent180Day,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
          percentForecasted: [
            percentageData.percentForecasted,
            [Validators.required, Validators.min(0), Validators.max(100)],
          ],
        });

        this.totalWeightings = Object.keys(this.salesVelocityForm.value).reduce(
          (acc, curr) => acc + this.salesVelocityForm.value[curr],
          0
        );

        this.handleSalesVelocityChange().pipe(this.autoCleanUp()).subscribe();
        break;

      case SALES_VELOCITY_SETTINGS.dateRange:
        this.salesVelocityForm = this.fb.group({
          startDate: [
            dataSettings?.salesVelocitySettingData?.startDate
              ? new Date(dataSettings?.salesVelocitySettingData?.startDate)
              : null,
            [Validators.required],
          ],
          endDate: [
            dataSettings?.salesVelocitySettingData?.endDate
              ? new Date(dataSettings?.salesVelocitySettingData?.endDate)
              : null,
            [Validators.required],
          ],
        });
        this.errorsDateRange = this.checkDateValid();
        this.salesVelocityForm.valueChanges.subscribe(() => {
          this.errorsDateRange = this.checkDateValid();
          this.onChangeData();
        });
        break;
    }
  }

  handleAverageSetting(isSwitchedToAverage: boolean, dataSettings: any): void {
    this.errorsDateRange = {};
    this.invalidDateRange = false;
    this.salesVelocityForm = this.fb.group({
      percent2Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent2Day',
          dataSettings,
          0
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent7Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent7Day',
          dataSettings,
          20
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent14Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent14Day',
          dataSettings,
          0
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent30Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent30Day',
          dataSettings,
          20
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent60Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent60Day',
          dataSettings,
          10
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent90Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent90Day',
          dataSettings,
          0
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percent180Day: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percent180Day',
          dataSettings,
          0
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      percentForecasted: [
        this.getDefaultPercentage(
          isSwitchedToAverage,
          'percentForecasted',
          dataSettings,
          50
        ),
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
    });

    this.totalWeightings = Object.keys(this.salesVelocityForm.value).reduce(
      (acc, curr) => acc + this.salesVelocityForm.value[curr],
      0
    );

    this.handleSalesVelocityChange().pipe(this.autoCleanUp()).subscribe();
  }

  getDefaultPercentage(
    isSwitchedToAverage: boolean,
    svNumberDaysSetting: string,
    dataSettings: any,
    defaultPercent: number = 0
  ): number {
    return this.getDefaultNumber(
      isSwitchedToAverage && DefaultWeightPercentage[svNumberDaysSetting],
      dataSettings?.salesVelocitySettingData?.[svNumberDaysSetting],
      dataSettings?.salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange &&
        defaultPercent
    );
  }

  getDefaultNumber(number1: number, number2: number, number3: number) {
    if (number1 > 0 || number1 === 0) return number1;
    if (number2 > 0 || number2 === 0) return number2;
    return number3;
  }

  checkDateValid() {
    const errors: any = {};

    const startDate = new Date(
      this.salesVelocityForm.value?.startDate
    ).getTime();
    const endDate = new Date(this.salesVelocityForm.value?.endDate).getTime();
    if (!startDate || !endDate) {
      this.invalidDateRange = true;
      return null;
    }

    //Compare Date, endDate must greater than startDate
    if (startDate >= endDate) {
      errors.invalidDate = true;
    } else {
      delete errors.invalidDate;
    }
    this.invalidDateRange = Object.keys(errors)?.length !== 0;
    return errors;
  }

  initSupplierSettingForm() {
    this.supplierSettingFormGroup = this.fb.group({
      additionalInventory: [
        this.dataItemSettings?.additionalInventory || 0,
        [Validators.required, Validators.min(0), Validators.max(365)],
      ],
    });
  }

  private setNullWhenSaving(data) {
    if (this.disableSettings.isNotAdditionalInventory) {
      this.dataItem.additionalInventory = null;
    }

    if (this.disableSettings.isNotAverageDailySaleRate) {
      data.salesVelocitySettingData = null;
      data.salesVelocityType = null;
    }

    if (this.disableSettings.isNotFbaInventory) {
      data.targetQtyOnHandMin = null;
      data.targetQtyOnHandMax = null;
    }

    if (this.disableSettings.isNotRestockModel) {
      data.restockModel = null;
    }

    if (this.disableSettings.isNotWarehouseLeadTime) {
      data.localLeadTime = null;
    }
  }

  private getPurchasingSummary() {
    if (!this.dataItem) {
      return of(null);
    }

    return this.itemService.getConsolidatedQty(
      this.dataItem.key || this.dataItem.itemKey,
      true
    ).pipe(
      tap((purchasingSummaries) => {
        [this.purchasingSummary] = purchasingSummaries || [];
      })
    );
  }
}
