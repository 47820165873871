import { PurchaseOrderStatus } from "../infrastructure/enums/purchase-order-status";
import { RestockType } from "../infrastructure/enums/restock-type.enum";
import { ShipmentStatus } from "../infrastructure/enums/shipment-status.enum";
import { Supplier } from "./supplier";

export class Shipment {
  key: string = '';
  restockKey: string = '';
  shipmentId: string = ''
  shipmentName: string = '';
  shipmentSource: string = '';
  labelPrepPreference: string = '';
  addressFromKey: string = '';
  destinationFulfillmentCenterId: string = '';
  status: ShipmentStatus;
  requestedQty: number = 0;
  receivedQty: number = 0;
  totalCost: number = 0;
  orderNotes: string = '';
  restockType: RestockType;
  isShipByCase: boolean = false;
  whoPreps: string = '';
  stepProgress: {
    uploadInventory?: boolean,
    inventorySelection?: boolean,
    shipmentSummary?: boolean,
    shipmentOptions?: boolean,
    packingOptions?: boolean,
    shipmentReview?: boolean,
    shipmentCasePack?: boolean,
    complete?: boolean,
    isFromRestockSuggestion?: boolean,
    isFromPurchaseOrder?: boolean,
  };
  etaDate: Date = new Date();
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  totalSKUs: number = 0;
  totalUnits: number = 0;
  totalCosts: number = 0;
  totalWeight: number = 0;
  shipFromAddress: Supplier;
  shipToAddress: {
    destinationFulfillmentCenterId: string,
    countryCode: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateOrProvinceCode: string,
    postalCode: string
  };
  selectedShipmentItemKeys: string[] = [];
  receiveDate: Date = new Date();
  vendorNameItemBelongTo: string;
  waitForJob: boolean;
  isShipManual: boolean;
  refNum?: string;
  purchaseOrderKey?: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
  shipmentConfirmationId?: string;
  placementOptionId?: string;
  inboundPlanId?: string;

  constructor(init?: Partial<Shipment>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
