import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { COUNTRIES } from '../constants/countries.constant';
import { MetaData } from '../infrastructure/classes/meta-data';
import { MetaDataField } from '../infrastructure/classes/meta-data-field';
import { DisplayControl } from '../infrastructure/classes/metadataDisplayControls/controls/display-control';
import { ListDisplayControl } from '../infrastructure/classes/metadataDisplayControls/controls/list-display-control';
import { LookUpDisplayControl } from '../infrastructure/classes/metadataDisplayControls/controls/look-up-display-control';
import { LookUpConfig } from '../infrastructure/classes/metadataDisplayControls/support/look-up-config';
import { LookUpMapping } from '../infrastructure/classes/metadataDisplayControls/support/look-up-mapping';
import { EmailValidator } from '../infrastructure/classes/metadataDisplayControls/validators/email-validator';
import { MinMaxValidator } from '../infrastructure/classes/metadataDisplayControls/validators/min-max-validator';
import { CompanyType } from '../infrastructure/enums/company-type.enum';
import { DisplayControlInput } from '../infrastructure/enums/display-control-input.enum';
import { DisplayControlOutput } from '../infrastructure/enums/display-control-output.enum';
import { InventorySourcePreferenceLabel } from '../infrastructure/enums/inventory-source-preference.enum';
import { LookUpMappingType } from '../infrastructure/enums/look-up-mapping-type.enum';
import {
  DemandField,
  ForecastedHistorySettingField,
  ItemField,
  ItemMetricField,
  ItemSiteField,
  MetaDataFieldName,
  PurchaseOrderField,
  PurchaseOrderItemField,
  RestockSuggestionItemField,
  RestockSuggestionSupplierField,
  ShipmentDetailField,
  ShipmentField,
  SummaryByVendorField,
  SummaryField,
  SupplierField,
  SupplyField,
  VendorField,
} from '../infrastructure/enums/meta-data-field.enum';
import { StockaidResponse } from './../misc/stockaid-response';
import { ItemService } from './item.service';
import { SessionStorageService } from './session-storage.service';
import { VendorService } from './vendor.service';

import { generateMonthPercentages } from '@stockaid/utils';
import { RESTOCK_MODELS } from '../constants/restock-model.constant';
import { ServiceLevelList } from '../constants/service-level.constant';
import { RestockType } from '../infrastructure/enums/restock-type.enum';
import { WhoPrepType } from '../infrastructure/enums/who-preps.enum';
import { Company } from '../models/company';
import { CompanyService } from './company.service';
import { WarehouseService } from './warehouse.service';
import { INTEGER_PATTERN } from '../constants/patterns.constant';
import { CustomPatternValidator } from '../infrastructure/classes/metadataDisplayControls/validators/custom-pattern-validator';
@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  apiUrl = '/api/metadata';
  companyType: string;

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private companyService: CompanyService
  ) {}

  get isAscCompany(): boolean {
    return (
      this.companyService.currentCompany()?.companyType === CompanyType.ASC
    );
  }

  getMetaData(forceReload: boolean = false): Observable<MetaData[]> {
    const refreshMetadataTime: number = this.sessionStorage.get(
      'refreshMetadataTime'
    );
    const metaData: MetaData[] = this.sessionStorage.get('metadata') || [];
    if (
      !forceReload &&
      metaData.length &&
      new Date().getTime() / 1000 - (refreshMetadataTime || 0) < 600
    ) {
      return of(metaData);
    }

    return this.http.get<StockaidResponse<MetaData[]>>(this.apiUrl).pipe(
      tap((res) => {
        this.sessionStorage.set(
          'refreshMetadataTime',
          new Date().getTime() / 1000
        );
        this.sessionStorage.set('metadata', res.model);
      }),
      map((res) => {
        return res.model;
      })
    );
  }

  getItemMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name.toLowerCase() === MetaDataFieldName.item;
        });

        const fieldsToUpdate: any[] = [
          {
            name: ItemField.name,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.asin,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.fnsku,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.description,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.vendorName,
            displayName: 'Supplier Name',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              lookUpConfig: new LookUpConfig({
                service: new VendorService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'vendorName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'vendorKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: ItemField.vendorPrice,
            displayName: 'Supplier Price',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
            }),
          },
          {
            name: ItemField.moq,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.orderInterval,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.serviceLevel,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Select,
              selectSource: [
                { display: 'Get by Supplier/Global', val: null },
              ].concat(ServiceLevelList),
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [new MinMaxValidator({ min: 50, max: 99 })],
          },
          {
            name: ItemField.onHand,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.onHandFbm,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.onHandMin,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.onHandThirdParty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Decimal,
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.awdInventoryQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.inboundAwd,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.onHandThirdPartyMin,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Decimal,
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.isHidden,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.doNotOrder,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.doNotRestock,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.useHistoryOverride,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.lotMultipleQty,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.lotMultipleItemName,
            displayControl: new DisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: ItemField.lotMultipleItemName,
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'lotMultipleItemKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: ItemField.tags,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.useBackfill,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.leadTime,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.createdAt,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.isFbm,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.inventorySourcePreference,
            displayControl: new ListDisplayControl({
              textField: 'name',
              valueField: 'id',
              source: of(
                Array.from(InventorySourcePreferenceLabel, ([id, name]) => ({
                  id,
                  name,
                }))
              ),
              input: DisplayControlInput.List,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.packageWeight,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.skuNotes,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.prepGuide,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Icon,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.prepNotes,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.supplierRebate,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.inboundShippingCost,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.reshippingCost,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.repackagingMaterialCost,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.repackingLaborCost,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.dimensionalWeight,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.hazmat,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.oversized,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.category,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.upc,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.ean,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.rank,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.average7DayPrice,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ItemField.itemHistoryLength,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [
                CompanyType.ASC,
                CompanyType.CSV,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: ItemField.boxLength,
            displayName: 'Box Length (in)',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.boxWidth,
            displayName: 'Box Width (in)',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.boxHeight,
            displayName: 'Box Height (in)',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ItemField.boxWeight,
            displayName: 'Box Weight (lbs)',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
        ];

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.item
        );

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getVendorMetaData(company: Company = null): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name.toLowerCase() === MetaDataFieldName.vendor;
        });

        const currentRestockModel = RESTOCK_MODELS.find(
          (rm) => rm.val === (company?.restockModel || RESTOCK_MODELS[0].val)
        );
        let fieldsToUpdate: any[] = [
          {
            name: VendorField.name,
            displayName: 'Supplier Name',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.description,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.averageHistoryLength,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.ASC,
                CompanyType.CSV,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.isHidden,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.email,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Email,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [new EmailValidator()],
          },
          {
            name: VendorField.moq,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: VendorField.leadTime,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.orderInterval,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: VendorField.serviceLevel,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Select,
              selectSource: [{ display: 'Get by Global', val: null }].concat(
                ServiceLevelList
              ),
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [new MinMaxValidator({ min: 50, max: 99 })],
          },
          {
            name: VendorField.targetOrderValue,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [new MinMaxValidator({ min: 0, max: 999999 })],
          },
          {
            name: VendorField.freeFreightMinimum,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            validators: [new MinMaxValidator({ min: 0, max: 999999 })],
          },
          {
            name: VendorField.restockModel,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Select,
              displayWithCompanyType: [CompanyType.ASC, CompanyType.ASCO],
              selectSource: [
                {
                  display: `Use Store Rule (${currentRestockModel.display})`,
                  val: 'GLOBAL',
                },
              ].concat(RESTOCK_MODELS),
            }),
            includedInChooser: true,
          },
        ];

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.vendor
        );

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getDemandMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.demand;
        });
        const fieldsToUpdate: any[] = [
          {
            name: DemandField.itemKey,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: DemandField.itemName,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: DemandField.itemName,
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: DemandField.itemKey,
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: DemandField.asin,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: DemandField.dueDate,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: DemandField.orderQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Integer,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: DemandField.openQty,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: DemandField.refNum,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: DemandField.isHidden,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC, CompanyType.QBFS],
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
            }),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.demand
        );

        return metadata;
      })
    );
  }

  getSupplyMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.supply;
        });
        const fieldsToUpdate: any[] = [
          {
            name: 'refNum',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: 'vendorName',
            required: true,
            displayName: 'Supplier Name',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              lookUpConfig: new LookUpConfig({
                service: new VendorService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'vendorName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'vendorKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: SupplyField.dueDate,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: SupplyField.docDate,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: 'itemKey',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
          {
            name: 'itemName',
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'itemName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'itemKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: 'asin',
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: 'orderQty',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              input: DisplayControlInput.Integer,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
              new CustomPatternValidator({
                pattern: INTEGER_PATTERN,
              }),
            ],
          },
          {
            name: 'openQty',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
              new CustomPatternValidator({
                pattern: INTEGER_PATTERN,
              }),
            ],
          },
          {
            name: 'isHidden',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.supply
        );

        return metadata;
      })
    );
  }

  getBomMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name.toLowerCase() === 'bom';
        });
        const fieldsToUpdate: any[] = [
          {
            name: 'parentName',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'parentName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'parentKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: 'childName',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'childName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'childKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: 'qty',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getSummaryByVendorMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.summaryByVendor;
        });

        let fieldsToUpdate: any[] = [
          { name: SummaryByVendorField.vendorKey },
          {
            name: SummaryByVendorField.vendorName,
            displayName: 'Supplier Name',
          },
          {
            name: SummaryByVendorField.earliestDueDate,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Date,
            }),
          },
          { name: SummaryByVendorField.totalPrice },
          { name: SummaryByVendorField.uniqueItems },
          { name: SummaryByVendorField.totalQty },
          {
            name: SummaryByVendorField.earliestRecommendedOrderDate,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Date,
            }),
          },
        ];

        fieldsToUpdate = fieldsToUpdate?.map((item) => {
          item = {
            ...item,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              output:
                item.name === SummaryByVendorField.totalPrice &&
                DisplayControlOutput.Currency,
            }),
          };

          return item;
        });

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getPOItemsMetaData(
    companyType?: string,
    isAdmin?: boolean,
    displayRestockAMZ?: boolean
  ): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.summary;
        });

        // Create fields contain icons
        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.summary
        );

        const forecastedQtyIdx = metadata.fields.findIndex(
          (i) => i.field === SummaryField.recommendedQty
        );

        metadata.fields.splice(forecastedQtyIdx + 1, 0, {
          field: SummaryField.trueRecommendedQty,
          dataType: null,
          displayName: 'Recommended Qty',
          initialUpload: false,
          required: false,
          displayControl: {
            output: DisplayControlOutput.Text,
            lookUpConfig: null,
            displayWithCompanyType: [CompanyType.ASC, CompanyType.ASCO],
          },
          includedInChooser: false,
          includedInExport: true,
        });

        let fieldsToUpdate: any[] = [
          {
            name: SummaryField.itemName,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
          },
          { name: SummaryField.asin },
          { name: SummaryField.description },
          // { name: SummaryField.tag },
          { name: SummaryField.tags },
          { name: SummaryField.vendorName, displayName: 'Supplier Name' },
          // { name: SummaryField.consolidatedQty },
          { name: SummaryField.onNewPo },
          {
            name: SummaryField.recommendedQty,
            displayName: 'Forecast Recommended Qty',
            restrictFilterAndSort: true,
          },
          // { name: SummaryField.type },
          {
            name: SummaryField.dueDate
          },
          { name: SummaryField.moq },
          { name: SummaryField.lotMultipleQty },
          {
            name: SummaryField.purchaseQty,
            displayName: 'Forecast Purchase Qty',
          },
          {
            name: SummaryField.truePurchaseQty,
            displayName: 'Daily Sales Rate Purchase Qty',
          },
          { name: SummaryField.vendorPrice, displayName: 'Supplier Price' },
          { name: SummaryField.total },
          // { name: SummaryField.leadTime },
          // { name: SummaryField.orderInterval },
          // { name: SummaryField.addedToSupplies },
          // { name: SummaryField.documentUuid },
          // { name: SummaryField.purchasingSummariesUuid },
          {
            name: SummaryField.s7d,
            displayName: this.getItemMetricDisplayName('7 Days'),
          },
          {
            name: SummaryField.s30d,
            displayName: this.getItemMetricDisplayName('30 Days'),
          },
          {
            name: SummaryField.s90d,
            displayName: this.getItemMetricDisplayName('90 Days'),
          },
          {
            name: SummaryField.s180d,
            displayName: this.getItemMetricDisplayName('180 Days'),
          },
          {
            name: SummaryField.s365d,
            displayName: this.getItemMetricDisplayName('365 Days'),
          },
          {
            name: SummaryField.sMtd,
            displayName: this.getItemMetricDisplayName('MTD'),
          },
          {
            name: SummaryField.sYtd,
            displayName: this.getItemMetricDisplayName('YTD'),
          },
          {
            name: SummaryField.snapshotQty,
            displayName: 'Demand - Purchase Orders',
          },
          { name: SummaryField.meanLtd },
          { name: SummaryField.safetyStockLtd },
          { name: SummaryField.onHand },
          { name: SummaryField.onHandMin },
          { name: SummaryField.onHandThirdParty },
          { name: SummaryField.onHandThirdPartyMin },
          {
            name: SummaryField.lastOrderDate
          },
          {
            name: SummaryField.nextScheduledOrderDate
          },
          { name: SummaryField.daysUntilNextScheduledOrder },
          {
            name: SummaryField.outOfStockDate
          },
          { name: SummaryField.daysLeft },
          {
            name: SummaryField.recommendedOrderDate
          },
          { name: SummaryField.daysRemaining },
          { name: SummaryField.openPurchaseOrders },
          { name: SummaryField.openSalesOrders },
        ];

        if (companyType === CompanyType.ASC) {
          fieldsToUpdate = [
            ...fieldsToUpdate,
            { name: SummaryField.fnsku },
            { name: SummaryField.inbound },
            { name: SummaryField.inboundAwd },
            { name: SummaryField.inboundPrice },
            { name: SummaryField.inboundSalesLast30Days },
            { name: SummaryField.inboundFcTransfer },
            { name: SummaryField.inboundFcProcessing },
            { name: SummaryField.inboundCustomerOrder },
            { name: SummaryField.inboundUnfulfillable },
            { name: SummaryField.inboundAlert },
            { name: SummaryField.inboundWorking },
            { name: SummaryField.mwsFulfillmentFee },
            { name: SummaryField.isFbm },
            { name: SummaryField.onHandFbm },
            { name: SummaryField.inventorySourcePreference },
          ];
        }

        const getOutputConfig = (itemName) => {
          switch (itemName) {
            case SummaryField.vendorPrice:
            case SummaryField.total:
            case SummaryField.inboundPrice:
            case SummaryField.inboundSalesLast30Days:
            case SummaryField.mwsFulfillmentFee:
              return DisplayControlOutput.Currency;

            case SummaryField.isFbm:
              return DisplayControlOutput.Checkbox;

            case SummaryField.tags:
              return DisplayControlOutput.Icon;

            case SummaryField.dueDate:
            case SummaryField.lastOrderDate:
            case SummaryField.nextScheduledOrderDate:
            case SummaryField.outOfStockDate:
            case SummaryField.recommendedOrderDate:
              return DisplayControlOutput.Date;

            default:
              return DisplayControlOutput.Text;
          }
        };

        fieldsToUpdate = fieldsToUpdate?.map((item) => {
          item = {
            ...item,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
              output: getOutputConfig(item.name),
            }),
          };

          return item;
        });

        fieldsToUpdate = fieldsToUpdate.concat(
          {
            field: SummaryField.imageUrl,
            name: SummaryField.imageUrl,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Image,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: SummaryField.doNotOrder,
            name: SummaryField.doNotOrder,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            restrictFilterAndSort: true,
          }
        );

        metadata.fields = metadata.fields.filter((item) =>
          fieldsToUpdate.find((field) => field.name === item.field)
        );

        metadata.fields.unshift({
          field: SummaryField.details,
          displayName: 'Links',
          dataType: null,
          displayControl: new DisplayControl({
            output: DisplayControlOutput.Icon,
            lookUpConfig: null,
            displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
          }),
          initialUpload: true,
          required: true,
        });

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getItemMetricMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name.toLowerCase() === 'itemmetric';
        });
        const fieldsToUpdate: any[] = [
          {
            name: 's7d',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            displayName: this.getItemMetricDisplayName('7 Days'),
          },
          {
            name: 's30d',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            displayName: this.getItemMetricDisplayName('30 Days'),
          },
          {
            name: 's90d',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            displayName: this.getItemMetricDisplayName('90 Days'),
          },
          {
            name: 's365d',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            displayName: this.getItemMetricDisplayName('365 Days'),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getSupplierMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name.toLowerCase() === MetaDataFieldName.supplier;
          }) || ({} as MetaData);
        const fieldsToUpdate: any[] = [
          {
            name: SupplierField.countryCode,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Select,
              displayWithCompanyType: [CompanyType.ASC, CompanyType.ASCO],
              selectSource: COUNTRIES.map((c) => ({
                display: c.name,
                val: c.countryCode,
              })),
            }),
          },
          {
            name: SupplierField.fullName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.addressLine1,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.addressLine2,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.city,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.stateOrProvinceCode,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.postalCode,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: SupplierField.phoneNumber,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.supplier
        );

        return metadata;
      })
    );
  }

  getShipmentMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.shipment;
          }) || ({} as MetaData);

        const options = [
          {
            field: ShipmentField.nextStep,
            displayName: 'Next Step',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              lookUpConfig: null,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            dataType: null,
            initialUpload: true,
            required: true,
          },
          {
            field: ShipmentField.restockType,
            displayName: 'Restock Type',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              lookUpConfig: null,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            dataType: null,
            initialUpload: true,
            required: true,
          },
          {
            field: ShipmentField.refNum,
            displayName: 'Linked PO',
            displayControl: new DisplayControl({
              // input: DisplayControlInput.LookUp,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
              // lookUpConfig: new LookUpConfig({
              //   service: new PurchaseOrderService(this.http),
              //   sourceLabelProperty: 'refNum',
              //   sourceValueProperty: 'refNum',
              //   mapping: [
              //     new LookUpMapping({
              //       from: 'refNum',
              //       to: 'refNum',
              //       type: LookUpMappingType.Value,
              //     }),
              //     new LookUpMapping({
              //       from: 'key',
              //       to: 'purchaseOrderKey',
              //       type: LookUpMappingType.Key,
              //     }),
              //   ],
              //   searchField: 'refNum'
              // }),
            }),
            dataType: null,
            initialUpload: true,
            required: false,
          },
          {
            field: ShipmentField.options,
            displayName: 'Options',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              lookUpConfig: null,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            dataType: null,
            initialUpload: true,
            required: true,
          },
        ];

        const fieldsToUpdate: any[] = [
          {
            name: ShipmentField.shipmentId,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.shipmentName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.shipmentSource,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.labelPrepPreference,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.destinationFulfillmentCenterId,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.status,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.requestedQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.receivedQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentField.totalCost,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Currency,
            }),
          },
          {
            name: ShipmentField.receiveDate,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: ShipmentField.createdAt,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: ShipmentField.updatedAt,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Date,
            }),
          },
          {
            name: ShipmentField.orderNotes,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
        ];

        metadata.fields = [...metadata.fields, ...options];

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getShipmentDetailMetaData(
    type: string = null,
    restockType?: RestockType
  ): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.shipmentDetail;
          }) || ({} as MetaData);

        const fieldsToUpdate: any[] = [
          {
            name: ShipmentDetailField.shipmentId,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.destinationFulfillmentCenterId,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.labelType,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.fnsku,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.localQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.orderQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.shipmentQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.whoPreps,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Select,
              displayWithCompanyType: [CompanyType.ASC],
              selectSource: [
                {
                  display: 'Seller',
                  val: WhoPrepType.SELLER,
                },
                {
                  display: 'Amazon',
                  val: WhoPrepType.AMAZON,
                },
              ],
              output: DisplayControlOutput.Text,
            }),
            required: true,
          },
          {
            name: ShipmentDetailField.boxLength,
            displayName: 'Box Length (in)',
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.boxHeight,
            displayName: 'Box Height (in)',
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.boxWeight,
            displayName: 'Box Weight (lbs)',
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.boxWidth,
            displayName: 'Box Width (in)',
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.cost,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Currency,
            }),
          },
          {
            name: ShipmentDetailField.receivedQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.hasSticker,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
            }),
          },
          {
            name: ShipmentDetailField.stickerQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
          {
            name: ShipmentDetailField.itemName,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new LookUpDisplayControl({
              input: DisplayControlInput.LookUp,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'itemName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'itemKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
          },
          {
            name: ShipmentDetailField.description,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.shipmentName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.asin,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.tags,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Icon,
            }),
          },
          {
            name: ShipmentDetailField.vendorName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.fnsku,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.lotMultipleQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.mwsFulfillmentFee,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.inbound,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.onHand,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.onHandFbm,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.inboundWorking,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.amazonRemaining,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.localRemaining,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.inboundUnfulfillable,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.recommendedQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.openPurchaseOrders,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.reserved,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.supplierSku,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.packageWeight,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.receivedQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.notes,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              input: DisplayControlInput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.forecastedQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: ShipmentDetailField.isHidden,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
            }),
          },
          {
            name: ShipmentDetailField.itemCreatedAt,
            displayName: 'Created At',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.fbaFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.inboundShippingCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.reshippingCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.repackagingMaterialCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.repackingLaborCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.lowestFba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.lowestNonFba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.average7DayPrice,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.listPrice,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.newBuyBox,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.estimatedMargin,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.referralFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: ShipmentDetailField.mwsFulfillmentFee,
            displayName: 'Fulfillment Fee',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            name: RestockSuggestionItemField.alertDate,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Date,
            }),
          },
        ];

        // Default value is Warehouse
        let recommendationHeader = 'Warehouse Restock Recommendation';
        if (restockType === RestockType.Supplier) {
          recommendationHeader = 'Supplier Restock Recommendation';
        }

        this.updateMetadataFields(fieldsToUpdate, metadata);
        let includedColumns = [];
        let additionalFieldsToUpdate = [];

        metadata.fields = metadata.fields.concat([
          {
            field: ShipmentDetailField.useForecastedQty,
            dataType: null,
            displayName: 'Use Forecast Recommended Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ShipmentDetailField.useRecommendedQty,
            dataType: null,
            displayName: `Use ${recommendationHeader}`,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ShipmentDetailField.useReCasePackQty,
            dataType: null,
            displayName: 'Use Recommendation by Case Pack',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
        ]);

        if (
          !metadata.fields.find(
            (f) => f.field === ShipmentDetailField.onHandFbm
          )
        ) {
          metadata.fields.push({
            field: ShipmentDetailField.onHandFbm,
            dataType: null,
            displayName: 'On Hand FBM Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          });
        }

        switch (type) {
          case 'inventorySelection':
            includedColumns = [
              RestockSuggestionItemField.flag,
              RestockSuggestionItemField.status,
              RestockSuggestionItemField.alertDate,
              ShipmentDetailField.itemName,
              ShipmentDetailField.description,
              ShipmentDetailField.asin,
              ShipmentDetailField.fnsku,
              ShipmentDetailField.tags,
              ShipmentDetailField.s7d,
              ShipmentDetailField.s30d,
              ShipmentDetailField.s90d,
              ShipmentDetailField.mwsFulfillmentFee,
              ShipmentDetailField.onHand,
              ShipmentDetailField.onHandFbm,
              ShipmentDetailField.inbound,
              ShipmentDetailField.inboundWorking,
              ShipmentDetailField.inboundUnfulfillable,
              ShipmentDetailField.amazonRemaining,
              ShipmentDetailField.localRemaining,
              ShipmentDetailField.localQty,
              ShipmentDetailField.shipmentQty,
              ShipmentDetailField.recommendedQty,
              ShipmentDetailField.forecastedQty,
              ShipmentDetailField.useForecastedQty,
              ShipmentDetailField.useRecommendedQty,
              ShipmentDetailField.inboundShippingCost,
              ShipmentDetailField.reshippingCost,
              ShipmentDetailField.repackagingMaterialCost,
              ShipmentDetailField.repackingLaborCost,
              ShipmentDetailField.fba,
              ShipmentDetailField.lowestFba,
              ShipmentDetailField.nonFba,
              ShipmentDetailField.lowestNonFba,
              ShipmentDetailField.packageWeight,
              ShipmentDetailField.dimensionalWeight,
              ShipmentDetailField.hazmat,
              ShipmentDetailField.oversized,
              ShipmentDetailField.average7DayPrice,
              ShipmentDetailField.listPrice,
              ShipmentDetailField.newBuyBox,
              ShipmentDetailField.s60d,
              ShipmentDetailField.s2d,
              ShipmentDetailField.s14d,
              ShipmentDetailField.s180d,
              ShipmentDetailField.estimatedMargin,
              ShipmentDetailField.estimatedMarginPercentage,
              ShipmentDetailField.estimatedMarkupPercentage,
              ShipmentDetailField.inboundFcTransfer,
              ShipmentDetailField.inboundAwd,
              ShipmentDetailField.sum,
              ShipmentDetailField.inboundWorking,
              ShipmentDetailField.inboundShipped,
              ShipmentDetailField.inboundReceiving,
              ShipmentDetailField.inboundTotal,
              ShipmentDetailField.category,
              ShipmentDetailField.rank,
              ShipmentDetailField.referralFee,
              ShipmentDetailField.fbaFee,
              RestockSuggestionItemField.maximumShipmentQty,
              RestockSuggestionItemField.pending,
              RestockSuggestionItemField.restockModel,
              RestockSuggestionItemField.upc,
              RestockSuggestionItemField.ean,
              ShipmentDetailField.isHidden,
              ShipmentDetailField.lotMultipleQty,
              ShipmentDetailField.casePackSuggShip,
              ShipmentDetailField.casePackSuggReorder,
              ShipmentDetailField.casePackForecastRecommendedQty,
              ShipmentDetailField.boxLength,
              ShipmentDetailField.boxWidth,
              ShipmentDetailField.boxHeight,
              ShipmentDetailField.boxWeight,
              ShipmentDetailField.itemCreatedAt,
            ];
            additionalFieldsToUpdate = [
              {
                name: ShipmentDetailField.localQty,
                displayName: 'Warehouse Quantity',
              },
              {
                name: ShipmentDetailField.recommendedQty,
                displayName: recommendationHeader,
              },
              {
                name: ShipmentDetailField.shipmentQty,
                displayName: 'Order Quantity',
              },
            ];
            break;

          case 'shipmentSummary':
            includedColumns = [
              ShipmentDetailField.itemName,
              ShipmentDetailField.description,
              ShipmentDetailField.fnsku,
              ShipmentDetailField.localQty,
              ShipmentDetailField.whoPreps,
              ShipmentDetailField.shipmentQty,
            ];
            additionalFieldsToUpdate = [
              {
                name: ShipmentDetailField.whoPreps,
                displayName: 'Who Preps',
              },
              {
                name: ShipmentDetailField.localQty,
                displayName: 'Warehouse Quantity',
              },
            ];
            break;

          case 'shipmentReview':
            includedColumns = [
              ShipmentDetailField.itemName,
              ShipmentDetailField.description,
              ShipmentDetailField.shipmentName,
              ShipmentDetailField.fnsku,
              ShipmentDetailField.destinationFulfillmentCenterId,
              ShipmentDetailField.shipmentQty,
            ];
            additionalFieldsToUpdate = [
              {
                name: ShipmentDetailField.destinationFulfillmentCenterId,
                displayName: 'Warehouse Id',
              },
            ];
            break;

          case 'shipmentCasePack':
            includedColumns = [
              ShipmentDetailField.itemName,
              ShipmentDetailField.description,
              ShipmentDetailField.shipmentQty,
              ShipmentDetailField.boxLength,
              ShipmentDetailField.boxWidth,
              ShipmentDetailField.boxHeight,
              ShipmentDetailField.boxWeight,
            ];

            additionalFieldsToUpdate = [
              {
                name: ShipmentDetailField.boxLength,
                displayName: 'Box Length (in)',
              },
              {
                name: ShipmentDetailField.boxWidth,
                displayName: 'Box Width (in)',
              },
              {
                name: ShipmentDetailField.boxHeight,
                displayName: 'Box Height (in)',
              },
              {
                name: ShipmentDetailField.boxWeight,
                displayName: 'Box Weight (lbs)',
              },
            ];
            break;

          case 'shipmentDetails':
            includedColumns = [
              ShipmentDetailField.itemName,
              ShipmentDetailField.supplierSku,
              ShipmentDetailField.asin,
              ShipmentDetailField.fnsku,
              ShipmentDetailField.description,
              ShipmentDetailField.packageWeight,
              ShipmentDetailField.cost,
              ShipmentDetailField.localQty,
              ShipmentDetailField.shipmentQty,
              ShipmentDetailField.receivedQty,
              ShipmentDetailField.notes,
            ];
            additionalFieldsToUpdate = [
              {
                name: ShipmentDetailField.localQty,
                displayName: 'Warehouse Quantity',
              },
              {
                name: ShipmentDetailField.shipmentQty,
                displayName: 'Quantity Sent',
              },
              {
                name: ShipmentDetailField.receivedQty,
                displayName: 'Quantity Received',
              },
            ];
            break;

          case 'sticker':
            includedColumns = [
              ShipmentDetailField.itemName,
              ShipmentDetailField.description,
              ShipmentDetailField.hasSticker,
              ShipmentDetailField.stickerQty,
            ];
            break;
        }

        metadata.fields = metadata.fields
          .filter((f) => includedColumns.includes(f.field))
          .sort(
            (a, b) =>
              includedColumns.indexOf(a?.field) -
              includedColumns.indexOf(b?.field)
          );

        metadata.fields = this.addMetadataFields(
          metadata.fields,
          MetaDataFieldName.shipmentDetail
        );

        this.updateMetadataFields(additionalFieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getRsSupplierMetadata(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.restockSuggestionByVendor;
          }) || ({} as MetaData);

        const fieldsToUpdate: any[] = [
          {
            name: RestockSuggestionSupplierField.vendorName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.orangeAlerts,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.yellowAlerts,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.tealAlerts,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.redAlerts,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.greenAlerts,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.restockUnits,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.restockTotal,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Currency,
            }),
          },
          {
            name: RestockSuggestionSupplierField.targetOrderValue,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: RestockSuggestionSupplierField.freeFreightMinimum,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.ASC],
              output: DisplayControlOutput.Text,
            }),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getRestockSuggestionsMetadata(): Observable<MetaData> {
    return forkJoin([
      this.getMetaData(),
      this.getItemMetaData(),
      this.getItemMetricMetaData(),
      this.getVendorMetaData(),
    ]).pipe(
      map(([res, itemMetadata, itemMetricMetadata, vendorMetadata]) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.restockSuggestion;
          }) || ({} as MetaData);

        metadata.fields = metadata.fields.concat([
          {
            field: RestockSuggestionItemField.targetDays,
            dataType: null,
            displayName: 'Target Days',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.inboundFcTransfer,
            dataType: null,
            displayName: 'FC Transfers',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.inboundAwd,
            dataType: null,
            displayName: 'Inbound to AWD',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.unfulfillable,
            dataType: null,
            displayName: 'Unfulfillable',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.sum,
            dataType: null,
            displayName: 'Sum',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.inboundTotal,
            dataType: null,
            displayName: 'Inbound Total',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.orderQty,
            dataType: null,
            displayName: 'Order Qty',
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.type,
            dataType: null,
            displayName: 'Type',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.forecastRecommendedQty,
            dataType: null,
            displayName: 'Forecast Recommended Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.recommendedWarehouseQty,
            dataType: null,
            displayName: 'Warehouse Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.recommendedSupplierQty,
            dataType: null,
            displayName: 'Supplier Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.expeditedRecommendationQty,
            dataType: null,
            displayName: 'Expedited Recommendation Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReForecastQty,
            dataType: null,
            displayName: 'Use Forecast Recommended Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReWarehouseQty,
            dataType: null,
            displayName: 'Use Warehouse Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReSupplierQty,
            dataType: null,
            displayName: 'Use Supplier Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReCasePackQty,
            dataType: null,
            displayName: 'Use Recommendation by Case Pack',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReForecastQtyByCasePackQty,
            dataType: null,
            displayName: 'Use Forecast Recommended Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReSupplierQtyByCasePackQty,
            dataType: null,
            displayName: 'Use Supplier Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useReWarehouseQtyByCasePackQty,
            dataType: null,
            displayName: 'Use Warehouse Restock Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.useExpeditedRecommendationQty,
            dataType: null,
            displayName: 'Use Expedited Recommendation',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.itemVolume,
            dataType: null,
            displayName: 'Item Volume',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.volumeUnitMeasurement,
            dataType: null,
            displayName: 'Unit of Measurement',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.storageVolume,
            dataType: null,
            displayName: 'Storage Volume',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.storageType,
            dataType: null,
            displayName: 'Storage Type',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.historicalDaysOfSupply,
            dataType: null,
            displayName: 'Historical Days of Purchase Orders (Parent ASIN)',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemField.onHandFbm,
            dataType: null,
            displayName: 'On Hand FBM Qty',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.estimatedExcessUnits,
            dataType: null,
            displayName: 'Estimated excess units',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.totalSurcharge,
            dataType: null,
            displayName: 'Estimated aged inventory surcharge',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.inventoryAges,
            dataType: null,
            displayName: 'Inventory age',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: RestockSuggestionItemField.awdInventoryQty,
            dataType: null,
            displayName: 'AWD Inventory',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
        ]);

        const fieldsToUpdate = [
          {
            field: RestockSuggestionItemField.flag,
            name: RestockSuggestionItemField.flag,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Flag,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.alertDate,
            name: RestockSuggestionItemField.alertDate,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Date,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.status,
            name: RestockSuggestionItemField.status,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Dropdown,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.createdAt,
            name: RestockSuggestionItemField.createdAt,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.imageUrl,
            name: RestockSuggestionItemField.imageUrl,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Image,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.sku,
            name: RestockSuggestionItemField.sku,
            displayName: 'SKU',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Link,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.productName,
            name: ItemField.description,
            displayName: 'Product Name',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Link,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.asin,
            name: RestockSuggestionItemField.asin,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Link,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.tags,
            name: RestockSuggestionItemField.tags,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.doNotRestock,
            name: RestockSuggestionItemField.doNotRestock,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Checkbox,
              output: DisplayControlOutput.Checkbox,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.prepGuide,
            name: RestockSuggestionItemField.prepGuide,
            displayName: 'Amazon Prep Guide',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.skuNotes,
            name: RestockSuggestionItemField.skuNotes,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.prepNotes,
            name: RestockSuggestionItemField.prepNotes,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.supplier,
            name: RestockSuggestionItemField.supplier,
            displayName: 'Supplier',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.supplierSku,
            name: ItemField.lotMultipleItemName,
            displayName: 'Supplier SKU',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.supplierCost,
            name: ItemField.vendorPrice,
            displayName: 'Supplier Cost',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.supplierRebate,
            name: RestockSuggestionItemField.supplierRebate,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.inboundShippingCost,
            name: RestockSuggestionItemField.inboundShippingCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.reshippingCost,
            name: RestockSuggestionItemField.reshippingCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.repackagingMaterialCost,
            name: RestockSuggestionItemField.repackagingMaterialCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.repackingLaborCost,
            name: RestockSuggestionItemField.repackingLaborCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.restockModel,
            name: RestockSuggestionItemField.restockModel,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.fnsku,
            name: RestockSuggestionItemField.fnsku,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.upc,
            name: RestockSuggestionItemField.upc,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.ean,
            name: RestockSuggestionItemField.ean,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.fba,
            name: RestockSuggestionItemField.fba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.lowestFba,
            name: RestockSuggestionItemField.lowestFba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.nonFba,
            name: RestockSuggestionItemField.nonFba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.lowestNonFba,
            name: RestockSuggestionItemField.lowestNonFba,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.packageWeight,
            name: RestockSuggestionItemField.packageWeight,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.dimensionalWeight,
            name: RestockSuggestionItemField.dimensionalWeight,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.casePackQuantity,
            name: ItemField.lotMultipleQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.hazmat,
            name: RestockSuggestionItemField.hazmat,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.oversized,
            name: RestockSuggestionItemField.oversized,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.s2d,
            name: RestockSuggestionItemField.s2d,
            displayName: '2D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.s7d,
            name: RestockSuggestionItemField.s7d,
            displayName: '7D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.s14d,
            name: RestockSuggestionItemField.s14d,
            displayName: '14D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.s30d,
            name: RestockSuggestionItemField.s30d,
            displayName: '30D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.s60d,
            name: RestockSuggestionItemField.s60d,
            displayName: '60D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.s90d,
            name: RestockSuggestionItemField.s90d,
            displayName: '90D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.s180d,
            name: RestockSuggestionItemField.s180d,
            displayName: '180D',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.average7DayPrice,
            name: RestockSuggestionItemField.average7DayPrice,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.listPrice,
            name: RestockSuggestionItemField.listPrice,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.soldBy,
            name: RestockSuggestionItemField.soldBy,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.newBuyBox,
            name: RestockSuggestionItemField.newBuyBox,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.estimatedMargin,
            name: RestockSuggestionItemField.estimatedMargin,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.estimatedMarginPercentage,
            name: RestockSuggestionItemField.estimatedMarginPercentage,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.estimatedMarkupPercentage,
            name: RestockSuggestionItemField.estimatedMarkupPercentage,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.qoh,
            name: ItemField.onHand,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.inbound,
            name: RestockSuggestionItemField.inbound,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.inboundWorking,
            name: RestockSuggestionItemField.inboundWorking,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.inboundShipped,
            name: RestockSuggestionItemField.inboundShipped,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.inboundReceiving,
            name: RestockSuggestionItemField.inboundReceiving,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.targetDays,
            name: RestockSuggestionItemField.targetDays,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.remaining,
            name: RestockSuggestionItemField.remaining,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.demand,
            name: RestockSuggestionItemField.demand,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.outOfStockPercentage,
            name: RestockSuggestionItemField.outOfStockPercentage,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.reserved,
            name: RestockSuggestionItemField.reserved,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.unfulfillable,
            name: RestockSuggestionItemField.unfulfillable,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.pending,
            name: RestockSuggestionItemField.pending,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.localQty,
            name: ItemField.onHandThirdParty,
            displayName: 'Warehouse Inventory',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.maximumShipmentQty,
            name: RestockSuggestionItemField.maximumShipmentQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.suggShip,
            name: RestockSuggestionItemField.suggShip,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.suggReorder,
            name: RestockSuggestionItemField.suggReorder,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.onOrder,
            name: RestockSuggestionItemField.onOrder,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.restockNeeded,
            name: RestockSuggestionItemField.restockNeeded,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.category,
            name: RestockSuggestionItemField.category,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.rank,
            name: RestockSuggestionItemField.rank,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.referralFee,
            name: RestockSuggestionItemField.referralFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.fbaFee,
            name: RestockSuggestionItemField.fbaFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              displayWithCompanyType: [CompanyType.ASC],
            }),
          },
          {
            field: RestockSuggestionItemField.forecastRecommendedQty,
            name: RestockSuggestionItemField.forecastRecommendedQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.recommendedWarehouseQty,
            name: RestockSuggestionItemField.recommendedWarehouseQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.casePackSuggShip,
            name: RestockSuggestionItemField.casePackSuggShip,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.casePackSuggReorder,
            name: RestockSuggestionItemField.casePackSuggReorder,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
          {
            field: RestockSuggestionItemField.casePackForecastRecommendedQty,
            name: RestockSuggestionItemField.casePackForecastRecommendedQty,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
          },
        ];

        const itemMetadataFields = itemMetadata.fields.filter((f) =>
          [
            ItemField.name,
            ItemField.imageUrl,
            ItemField.description,
            ItemField.asin,
            ItemField.prepGuide,
            ItemField.doNotRestock,
            ItemField.skuNotes,
            ItemField.prepNotes,
            ItemField.tags,
            ItemField.lotMultipleItemName,
            ItemField.vendorPrice,
            ItemField.supplierRebate,
            ItemField.inboundShippingCost,
            ItemField.reshippingCost,
            ItemField.repackagingMaterialCost,
            ItemField.repackingLaborCost,
            ItemField.fnsku,
            ItemField.upc,
            ItemField.ean,
            ItemField.packageWeight,
            ItemField.dimensionalWeight,
            ItemField.lotMultipleQty,
            ItemField.hazmat,
            ItemField.oversized,
            ItemField.onHand,
            ItemField.inbound,
            ItemField.reserved,
            ItemField.inboundUnfulfillable,
            ItemField.onHandThirdParty,
            ItemField.category,
            ItemField.newBuyBox,
            ItemField.inboundWorking,
            ItemField.inboundShipped,
            ItemField.inboundReceiving,
            ItemField.referralFee,
            ItemField.listPrice,
            ItemField.fba,
            ItemField.nonFba,
            ItemField.lowestFba,
            ItemField.lowestNonFba,
            ItemField.soldBy,
            ItemField.fbaFee,
            ItemField.average7DayPrice,
            ItemField.boxLength,
            ItemField.boxWidth,
            ItemField.boxHeight,
            ItemField.boxWeight,
            ItemField.rank,
          ].includes(f.field as ItemField)
        );
        const itemNameMetadata = itemMetadataFields.find(
          (i) => i.field === ItemField.name
        );
        itemNameMetadata.field = RestockSuggestionItemField.sku;

        metadata.fields = metadata.fields.concat(itemMetadataFields);

        const itemMetricMetadataFields = itemMetricMetadata.fields.filter((f) =>
          [
            ItemMetricField.s2d,
            ItemMetricField.s7d,
            ItemMetricField.s14d,
            ItemMetricField.s30d,
            ItemMetricField.s60d,
            ItemMetricField.s90d,
            ItemMetricField.s180d,
          ].includes(f.field as ItemMetricField)
        );
        metadata.fields = metadata.fields.concat(itemMetricMetadataFields);

        const vendorMetadataFields = vendorMetadata.fields.filter((f) =>
          [VendorField.name, VendorField.restockModel].includes(
            f.field as VendorField
          )
        );
        const vendorNameMetadata = vendorMetadataFields.find(
          (v) => v.field === VendorField.name
        );
        vendorNameMetadata.field = RestockSuggestionItemField.supplier;
        metadata.fields = metadata.fields.concat(vendorMetadataFields);
        metadata.fields = metadata.fields.reduce((fields, currField) => {
          return fields.some((field) => field?.field === currField.field)
            ? fields
            : [...fields, currField];
        }, []);

        const itemFields = metadata.fields.splice(
          metadata.fields.findIndex((m) => m.field === DemandField.itemName),
          1
        );
        metadata.fields = itemFields.concat(metadata.fields);

        this.updateMetadataFields(fieldsToUpdate, metadata);

        return metadata;
      })
    );
  }

  getForecastedHistorySettingMetaData(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.forecastedHistorySetting;
        });

        const monthPercentages = generateMonthPercentages().map((p, index) => ({
          field: `month${index + 1}`,
          displayName: p,
        }));

        metadata.fields = metadata.fields.concat([
          {
            field: ForecastedHistorySettingField.itemName,
            dataType: null,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ForecastedHistorySettingField.asin,
            dataType: null,
            displayName: 'ASIN',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ForecastedHistorySettingField.fnsku,
            dataType: null,
            displayName: 'FNSKU',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
            }),
            initialUpload: true,
            required: true,
          },
          ...monthPercentages.map((p) => ({
            field: p.field,
            dataType: null,
            displayName: p.displayName,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.CSV,
              ],
            }),
            initialUpload: true,
            required: true,
          })),
        ]);

        const fieldsToUpdate: any[] = [
          {
            name: ForecastedHistorySettingField.groupName,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
                CompanyType.QBO,
              ],
            }),
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getPrintStickerMetaData(isPOVault): Observable<MetaData> {
    const metadata$ = isPOVault
      ? this.getPurchaseOrderItemMetaData()
      : this.getItemMetaData();

    return metadata$.pipe(
      map((itemMetadata) => {
        itemMetadata.fields = itemMetadata.fields
          .filter((m) =>
            [ItemField.name, PurchaseOrderItemField.itemName, ItemField.description].includes(
              m.field as ItemField
            )
          )
          .concat([
            {
              dataType: null,
              displayName: 'Sticker?',
              field: 'hasSticker',
              displayControl: new DisplayControl({
                displayWithCompanyType: [CompanyType.ASC],
                input: DisplayControlInput.Checkbox,
                output: DisplayControlOutput.Checkbox,
              }),
              required: true,
              initialUpload: true,
            },
            {
              dataType: null,
              displayName: 'Sticker#',
              field: 'stickerQty',
              displayControl: new DisplayControl({
                displayWithCompanyType: [CompanyType.ASC],
                input: DisplayControlInput.Decimal,
                output: DisplayControlOutput.Text,
              }),
              validators: [
                new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
                new CustomPatternValidator({
                  pattern: INTEGER_PATTERN,
                }),
              ],
              required: true,
              initialUpload: true,
            },
          ]);

        const fieldsToUpdate = [
          {
            name: ItemField.name,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            initialUpload: true,
          },
          {
            name: PurchaseOrderItemField.itemName,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            initialUpload: true,
          },
          {
            name: ItemField.description,
            displayName: 'Name',
            initialUpload: true,
          },
        ];
        this.updateMetadataFields(fieldsToUpdate, itemMetadata);

        return itemMetadata;
      })
    );
  }

  getItemSiteMetadataFields(): Observable<MetaData> {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata = res.find((item) => {
          return item.name === MetaDataFieldName.itemSite;
        });

        metadata.fields = [
          {
            field: ItemSiteField.itemName,
            dataType: null,
            displayName: 'Item Name',
            displayControl: new DisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [CompanyType.CSV],
              lookUpConfig: new LookUpConfig({
                service: new ItemService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'itemName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'itemKey',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
            initialUpload: true,
            required: true,
          },
          {
            field: ItemSiteField.warehouseName,
            dataType: null,
            displayName: 'Warehouse Name',
            displayControl: new DisplayControl({
              input: DisplayControlInput.LookUp,
              displayWithCompanyType: [CompanyType.CSV],
              lookUpConfig: new LookUpConfig({
                service: new WarehouseService(this.http),
                sourceLabelProperty: 'name',
                sourceValueProperty: 'name',
                mapping: [
                  new LookUpMapping({
                    from: 'name',
                    to: 'warehouseName',
                    type: LookUpMappingType.Value,
                  }),
                  new LookUpMapping({
                    from: 'key',
                    to: 'key',
                    type: LookUpMappingType.Key,
                  }),
                ],
              }),
            }),
            initialUpload: true,
            required: true,
          },
        ].concat(metadata.fields as any);

        const fieldsToUpdate: any[] = [
          {
            name: ItemSiteField.onHand,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.CSV],
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
            ],
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getPurchaseOrderMetaData() {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.purchaseOrder;
          }) || ({} as MetaData);

        const additionalFields = [
          {
            field: PurchaseOrderField.shipmentId,
            displayName: 'Linked FBA Shipments',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Text,
              displayWithCompanyType: [CompanyType.ASC],
              willShowTooltip: true,
            }),
            dataType: null,
            initialUpload: true,
            required: false,
            restrictFilterAndSort: true
          },
          {
            field: PurchaseOrderField.options,
            displayName: 'Options',
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Icon,
              lookUpConfig: null,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
            dataType: null,
            initialUpload: true,
            required: true,
            filterable: false,
          },
        ]

        let fieldsToUpdate: any[] = [
          {
            name: PurchaseOrderField.refNum,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
            initialUpload: true,
          },
          {
            name: PurchaseOrderField.status,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderField.shipTo,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderField.orderQty,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.openQty,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Integer,
              displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
            }),
          },
          {
            name: PurchaseOrderField.totalCost,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              input: DisplayControlInput.Currency,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.dueDate,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
            initialUpload: false,
          },
          {
            name: PurchaseOrderField.docDate,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Date,
              output: DisplayControlOutput.Date,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
            initialUpload: false,
          },
          {
            name: PurchaseOrderField.taxesFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              input: DisplayControlInput.Currency,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.shippingFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              input: DisplayControlInput.Currency,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.otherFee,
            displayControl: new DisplayControl({
              output: DisplayControlOutput.Currency,
              input: DisplayControlInput.Currency,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.shipVia,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.fob,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.paymentTerms,
            displayControl: new DisplayControl({
              input: DisplayControlInput.Text,
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
            }),
          },
          {
            name: PurchaseOrderField.createdAt,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              output: DisplayControlOutput.Date,
            }),
            initialUpload: true,
          },
          {
            name: PurchaseOrderField.updatedAt,
            displayControl: new DisplayControl({
              displayWithCompanyType: [CompanyType.CSV, CompanyType.ASC],
              output: DisplayControlOutput.Date,
            }),
            initialUpload: true,
          },
        ];

        metadata.fields = metadata.fields.concat(additionalFields);
        metadata.fields.splice(2, 0, {
          field: PurchaseOrderField.vendorName,
          displayName: 'Supplier Name',
          displayControl: new LookUpDisplayControl({
            input: DisplayControlInput.LookUp,
            displayWithCompanyType: [
              CompanyType.CSV,
              CompanyType.ASC,
              CompanyType.QBFS,
            ],
            lookUpConfig: new LookUpConfig({
              service: new VendorService(this.http),
              sourceLabelProperty: 'name',
              sourceValueProperty: 'name',
              mapping: [
                new LookUpMapping({
                  from: 'name',
                  to: 'vendorName',
                  type: LookUpMappingType.Value,
                }),
                new LookUpMapping({
                  from: 'key',
                  to: 'vendorKey',
                  type: LookUpMappingType.Key,
                }),
              ],
            }),
          }),
          required: true,
          dataType: null,
          initialUpload: true,
        });
        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  getPurchaseOrderItemMetaData() {
    return this.getMetaData().pipe(
      map((res) => {
        const metadata =
          res.find((item) => {
            return item.name === MetaDataFieldName.purchaseOrderItem;
          }) || ({} as MetaData);

        const casePackQtyField = metadata.fields.find(
          (f) => f.field === SummaryField.lotMultipleQty
        );
        casePackQtyField.field = PurchaseOrderItemField.lotMultipleQty;

        let fieldsToUpdate: any[] = [
          {
            name: PurchaseOrderItemField.itemName,
            displayName: this.isAscCompany ? 'SKU' : 'Item Name',
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderItemField.fnsku,
            initialUpload: true,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.ASC,
              ],
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderItemField.asin,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.ASC,
              ],
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderItemField.vendorPrice,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Decimal,
              output: DisplayControlOutput.Currency,
            }),
          },
          {
            name: PurchaseOrderItemField.description,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.TextArea,
              output: DisplayControlOutput.Text,
            }),
          },
          {
            name: PurchaseOrderItemField.tags,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              output: DisplayControlOutput.Icon,
            }),
            initialUpload: true,
          },
          {
            name: PurchaseOrderItemField.lotMultipleQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            initialUpload: true,
          },
          {
            name: PurchaseOrderItemField.moq,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            initialUpload: true,
          },
          {
            name: PurchaseOrderItemField.purchaseQty,
            displayName: 'Forecast Purchase Qty',
          },
          {
            name: PurchaseOrderItemField.truePurchaseQty,
            displayName: 'Daily Sales Rate Purchase Qty',
          },
          {
            name: PurchaseOrderItemField.orderQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 1, max: Number.MAX_VALUE }),
              new CustomPatternValidator({
                pattern: INTEGER_PATTERN,
              }),
            ],
          },
          {
            name: PurchaseOrderItemField.openQty,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
              ],
              input: DisplayControlInput.Integer,
              output: DisplayControlOutput.Text,
            }),
            validators: [
              new MinMaxValidator({ min: 0, max: Number.MAX_VALUE }),
              new CustomPatternValidator({
                pattern: INTEGER_PATTERN,
              }),
            ],
            initialUpload: true,
          },
          {
            name: PurchaseOrderItemField.notes,
            displayControl: new DisplayControl({
              displayWithCompanyType: [
                CompanyType.CSV,
                CompanyType.ASC,
                CompanyType.QBFS,
              ],
              input: DisplayControlInput.Text,
              output: DisplayControlOutput.Text,
            }),
            initialUpload: true,
          },
        ];

        this.updateMetadataFields(fieldsToUpdate, metadata);
        return metadata;
      })
    );
  }

  addMetadataFields(metadataToUpdate: any[], metadataType: string) {
    const linkField = {
      field:
        metadataType === MetaDataFieldName.item
          ? ItemField.links
          : SummaryField.details,
      displayName: 'Links',
      dataType: null,
      displayControl: new DisplayControl({
        output: DisplayControlOutput.Icon,
        lookUpConfig: null,
        displayWithCompanyType:
          metadataType === MetaDataFieldName.item
            ? [CompanyType.ASC, CompanyType.QBFS]
            : [CompanyType.CSV, CompanyType.ASC],
      }),
      initialUpload: true,
      required: true,
    };

    const vendorLinkField = {
      field: VendorField.links,
      displayName: 'Links',
      dataType: null,
      displayControl: new DisplayControl({
        output: DisplayControlOutput.Icon,
        lookUpConfig: null,
        displayWithCompanyType: [CompanyType.ASC, CompanyType.QBFS],
      }),
      initialUpload: true,
      includedInChooser: false,
    };

    const imageField = {
      field: ItemField.imageUrl,
      displayName: 'Image',
      dataType: null,
      displayControl: new DisplayControl({
        output: DisplayControlOutput.Image,
        lookUpConfig: null,
        displayWithCompanyType: [CompanyType.ASC],
      }),
      excludedFromTemplate: true,
      initialUpload: true,
    };

    const fnskuField = {
      field: ItemField.fnsku,
      displayControl: new DisplayControl({
        displayWithCompanyType: [CompanyType.ASC],
      }),
      dataType: 'STRING',
      displayName: 'FNSKU',
      initialUpload: true,
      required: false,
    };

    const orderIdField = {
      field: DemandField.orderKey,
      displayControl: new DisplayControl({
        displayWithCompanyType: [CompanyType.ASC],
      }),
      displayName: 'Order ID',
      dataType: 'STRING',
      initialUpload: true,
    };

    const historyField = {
      field: ItemField.history,
      displayName: 'History',
      dataType: null,
      displayControl: new DisplayControl({
        output: DisplayControlOutput.Icon,
        lookUpConfig: null,
        displayWithCompanyType: [
          CompanyType.CSV,
          CompanyType.ASC,
          CompanyType.QBFS,
          CompanyType.QBO,
        ],
      }),
      initialUpload: true,
      required: true,
    };

    const totalSurchargeField = {
      field: ItemField.totalSurcharge,
      dataType: 'DECIMAL',
      displayName: 'Estimated aged inventory surcharge',
      displayControl: new DisplayControl({
        output: DisplayControlOutput.Currency,
        displayWithCompanyType: [CompanyType.ASC],
      }),
      initialUpload: true,
    };

    let fieldsAdd = [];

    switch (metadataType) {
      case 'shipmentDetail':
        fieldsAdd = [imageField];
        break;
      case 'supply':
      case 'demand':
        fieldsAdd = [imageField, orderIdField, fnskuField];
        break;
      case 'summary':
        fieldsAdd = [linkField, imageField];
        break;
      case 'item':
        fieldsAdd = [historyField, linkField, imageField, totalSurchargeField];
        break;
      case 'vendor':
        fieldsAdd = [vendorLinkField];
        break;
    }

    if (metadataType === 'vendor') {
      return fieldsAdd.concat(metadataToUpdate);
    }

    const itemNameFields = metadataToUpdate.splice(
      metadataToUpdate.findIndex((m) =>
        [ItemField.name, DemandField.itemName].includes(m.field)
      ),
      1
    );

    return itemNameFields.concat(fieldsAdd).concat(metadataToUpdate);
  }

  updateMetadataFields(fieldsToUpdate: any[], metaData: MetaData) {
    for (let field of fieldsToUpdate) {
      const index = metaData.fields.findIndex(
        (f: MetaDataField) => f.field === field.name
      );

      if (index === -1) {
        continue;
      }

      [
        'displayControl',
        'initialUpload',
        'required',
        'validators',
        'displayName',
        'restrictFilterAndSort',
        'excludedFromTemplate',
        'field',
      ].forEach((x) => {
        if (field[x]) {
          metaData.fields[index][x] = field[x];
        }
      });

      metaData.fields[index].includedInChooser = field.includedInChooser;
    }
  }

  getItemMetricDisplayName(metricType: string): string {
    const company = this.companyService.currentCompany();
    return company?.companyType === CompanyType.ASC
      ? `${metricType} Units Sold`
      : `${metricType} Units Shipped`;
  }
}
